import React from "react";
// import "./index.css";
import Logo from "../../../assets/icon/sialek.png";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CardList } from "react-bootstrap-icons";
import {
  Row,
  Col,
  Form,
  Image,
  Button,
  InputGroup,
  Container,
  Spinner,
} from "react-bootstrap";
import Navbar from "../navbar";
import "../../../styles/index.css";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";

const Index = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      token_activation: "",
    },
    validationSchema: Yup.object({
      token_activation: Yup.string().required("Harus diisi!"),
    }),
    onSubmit: (values) => {
      let params = {
        token: values.token_activation,
        type: "register",
      };
      setLoading(true);
      req
        .postWithNoHeaders({
          endpoint: "verification",
          data: params,
        })
        .then((res) => {
          if (res) {
            if (res.status == "success") {
              localStorage.removeItem("rg");
              sweetalert
                .success(`${`Email telah sukses di aktivasi.`}`)
                .then((res) => {
                  history.push("/login");
                });
            } else {
              sweetalert.error(`${res.message}`);
            }
          }
          setLoading(false);
        });
    },
  });

  // const getLocalStorage = localStorage.getItem("access_token") || null;

  React.useEffect(() => {}, []);
  return (
    <>
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  <Row style={{ marginTop: "100px" }}>
                    <Col xs="12" className="text-center p-3">
                      <Image src={Logo} alt="logo" width="120px"></Image>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col xs="12">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            id="token_activation"
                            className="border-right-unset"
                          >
                            <CardList />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="token_activation"
                          type="text"
                          placeholder="Token aktivasi"
                          value={formik.values.token_activation}
                          onChange={formik.handleChange}
                          className="border-left-unset"
                        />
                      </InputGroup>
                      {loading ? (
                        <Row className="text-center">
                          <Col>
                            <Spinner animation="grow" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          </Col>
                        </Row>
                      ) : (
                        <Button block="true" type="submit">
                          Verifikasi
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {formik.touched.token_activation &&
              formik.errors.token_activation ? (
                <Row>
                  <Col xs="12 pb-3">
                    <Button
                      disabled={true}
                      block="true"
                      className="btn btn-err"
                    >
                      Data yang anda masukkan belum benar.
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
