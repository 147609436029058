import React from "react";
import "./index.css";
// import { Container } from "@material-ui/core";
import { Row, Col, Form, Button, Container, Spinner } from "react-bootstrap";
import Navbar from "../../container/navbar";
import { Eye, Trash } from "react-bootstrap-icons";
import { useHistory, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FileUpload from "../../../assets/icon/file.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/index.css";
import Swal from "sweetalert2";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";
import { urlForImage } from "../../../helpers/env";
import axios from "axios";
import ModalImage from "../../modal/showimage";

const Index = () => {
  const [data, setData] = React.useState({});
  const [allData, setAllData] = React.useState([]);
  const [reRender, setReRender] = React.useState(false);

  const [idExportir, setidExportir] = React.useState();

  const [filePaths, setFilePaths] = React.useState([]);

  const [roleCheck, setRoleCheck] = React.useState({});
  const [parent, setParent] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [subdit, setSubdit] = React.useState([]);
  const [direktorat, setDirektorat] = React.useState({});
  const [urlImage, setUrlImage] = React.useState("");
  const [show, setShow] = React.useState(false);

  const location = useLocation();
  const history = useHistory();

  const tmpState = location.state;

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      namafile: "",
      keterangan: "",
      catatan: "",
    },
    validationSchema: Yup.object({
      // namafile: Yup.string().required("Harus diisi!"),
      // keterangan: Yup.string().required("Harus diisi!"),
      // catatan: Yup.string().required("Harus diisi!"),
    }),
    onSubmit: (values, actions) => {
      delete values.namafile;
      const resultData = {
        notes: values.catatan,
      };

      let tmpCheck = [];

      let toPush = roleCheck;

      Object.keys(toPush).map((key) => {
        if (toPush[key] === true) {
          tmpCheck.push(key);
        }
      });
      if (tmpCheck.length > 0 || allData.length > 0) {
        const fd = new FormData();
        fd.append("explanation", resultData.notes);
        fd.append("role_id", direktorat.id);
        fd.append("parent", parent);

        // fd.append("role_id", tmpCheck.join(","));

        allData.map((item, index) => {
          fd.append("file_names[" + index + "]", item.namafile);
          fd.append("document_desc[" + index + "]", item.keterangan);
          fd.append("file_paths[" + index + "]", filePaths[index]);
        });
        setLoading(true);
        req
          .post({
            endpoint: `applicant-submission/${tmpState.id_submission}/save`, //ENDPOINT BELUM DIGANTI DENGAN YANG SEBENARNYA
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                sweetalert
                  .success(`Disposisi telah dilanjutkan.`)
                  .then((res) => {
                    history.push(
                      `/subdit/permohonan/detail/${tmpState.id_submission}`
                    );
                  });
              } else if (res.status == "error") {
                sweetalert.error(`${res.message}`);
              }
              // else {
              //   sweetalert.error(`check error on network preview `);
              // }
              setLoading(false);
            }
          });
      } else {
        sweetalert.info("Dokumen harus diisi");
      }
    },
  });

  const onDrag = (e) => {
    if (e) {
      if (e[0].size < 1000000) {
        setData({ file: e });

        e.map((i) => {
          // return setImageName(i.name);
          formik.setFieldValue("namafile", i.name);
        });

        const fd = new FormData();
        fd.append("file", e[0]);
        fd.append("exporter_id", idExportir);
        req
          .post({
            endpoint: `applicant-submission/${tmpState.id_submission}/add-document`,
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                const path = res.data.path || null;
                const setpath = [...filePaths];
                setpath.push(path);
                setFilePaths(setpath);
              }
            }
          });
      } else {
        sweetalert.error(`Ukuran file maksimal 1mb`);
      }
    }
  };

  const handleAdd = () => {
    if (formik.values.namafile === "" || formik.values.keterangan === "") {
      return Swal.fire({
        text: "Jika ingin menambah dokumen, Gambar dan keterangan harus diisi",
        icon: "info",
        confirmButtonText: "OK",
        confirmButtonColor: "#63ACEF",
      });
    }
    formik.setFieldValue("namafile", "");
    formik.setFieldValue("keterangan", "");
    const fd = new FormData();
    fd.append("file", data);
    let tmp = allData;
    const result = {
      ...data,
      namafile: formik.values.namafile,
      keterangan: formik.values.keterangan,
    };

    tmp.push(result);

    setAllData(tmp);
  };
  const deleteNotes = (index) => {
    allData.splice(index, 1);
    setReRender(!reRender);
  };

  const getDocument = async (doc, id_proses) => {
    if (doc) {
      const tmpSplit = doc?.document.split(".");

      const tmpIndex = tmpSplit.length > 0 ? tmpSplit.length - 1 : null;
      const tmpTypeData = tmpSplit[tmpIndex].toLowerCase();

      if (
        tmpTypeData === "png" ||
        tmpTypeData === "jpg" ||
        tmpTypeData === "jpeg"
      ) {
        const urlImage = `${urlForImage}/submission-acceleration/documents/exporters/${idExportir}/${tmpState.id_submission}/processes/${id_proses}/documents/${doc.document}`;
        setUrlImage(urlImage);
        setShow(true);
      } else {
        window.open(
          `${urlForImage}/submission-acceleration/documents/exporters/${idExportir}/${tmpState.id_submission}/processes/${id_proses}/documents/${doc.document}`,
          "_blank"
        );
      }
    }
  };
  const closeModal = () => {
    setShow(false);
  };

  const createDisposisi = async () => {
    const parent = tmpState?.proses?.participants[0].id || null;
    await req
      .post({
        endpoint: `applicant-submission/${tmpState.id_submission}/create`,
        data: {
          parent,
        },
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setParent(res.parent);
            setDirektorat(res.direktorats[0]); //array
            setSubdit(res.subdits); //array
            setidExportir(res.data.exporter_id);
          }
        }
      });
  };

  React.useEffect(() => {
    // console.log(tmpState);
    createDisposisi();
  }, []);
  return (
    <>
      <ModalImage data={{ show, closeModal, urlImage }} />
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  <Row style={{ marginTop: "100px" }}>
                    <Col xs="12">
                      <p className="fsize12 font-weight-bold mt-3">
                        {" "}
                        {tmpState?.proses?.role?.name}
                      </p>

                      <p className="fsize12">{tmpState?.proses?.explanation}</p>
                      {tmpState?.proses?.documents.length > 0 &&
                        tmpState?.proses?.documents.map((doc, docIndex) => (
                          <p
                            key={docIndex}
                            className="txt-blue mb-2 fsize12"
                            style={{ textDecoration: "underline" }}
                            onClick={() =>
                              getDocument(doc, tmpState?.proses?.id)
                            }
                          >
                            {doc.document}
                            <Eye className="ml-2" color="#63acef" />
                          </p>
                        ))}

                      {/* <p
                        className="txt-blue mb-2 fsize12"
                        style={{ textDecoration: "underline" }}
                      >
                        Contoh File1.docx{" "}
                        <Eye className="ml-2" color="#63acef" />
                      </p> */}
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="12">
                      <Row>
                        <Col xs="12">
                          <Dropzone
                            onDrop={(acceptedFiles) => onDrag(acceptedFiles)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section
                                className="border-upload"
                                style={{ marginBottom: "1rem" }}
                              >
                                <div
                                  {...getRootProps()}
                                  className="border-upload-fill"
                                >
                                  <input {...getInputProps()} />
                                  <div className="border-fill">
                                    <img
                                      src={FileUpload}
                                      alt="file"
                                      className="pt-2 pb-2"
                                    ></img>
                                    <div className="border-image">Upload</div>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>

                          <Form.Group controlId="namafile">
                            <Form.Label>Nama File</Form.Label>
                            <Form.Control
                              className="forForm"
                              type="text"
                              name="namafile"
                              disabled
                              value={formik.values.namafile}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                          <Form.Group controlId="keterangan">
                            <Form.Label>Ket. Dokumen</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="keterangan"
                              value={formik.values.keterangan}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                          <button
                            type="button"
                            className="button-add fsize16 txt-blue"
                            onClick={(e) => handleAdd(e)}
                          >
                            Tambah
                          </button>

                          <Row
                            style={{ marginLeft: 0, marginRight: 0 }}
                            className={allData.length > 0 ? "notes" : null}
                          >
                            <Col xs="12">
                              {allData.map((item, index) => (
                                <Row
                                  key={index}
                                  style={{
                                    position: "relative",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Col xs="6">Nama File:</Col>
                                  <Col xs="6">
                                    {item.namafile.length > 10
                                      ? item.namafile.substring(0, 10) + "..."
                                      : item.namafile}
                                  </Col>
                                  <Col xs="6">Ket. Dokumen</Col>
                                  {/* <Col xs="6">{item.keterangan}</Col> */}
                                  <Col xs="6">
                                    {item.keterangan.length > 100
                                      ? item.keterangan.substring(0, 100) +
                                        "..."
                                      : item.keterangan}
                                  </Col>
                                  <Trash
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      bottom: "5px",
                                      color: "red",
                                    }}
                                    onClick={() => deleteNotes(index)}
                                  />
                                </Row>
                              ))}
                            </Col>
                          </Row>

                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Catatan</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="catatan"
                              onChange={formik.handleChange}
                              value={formik.values.catatan}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12 pb-3">
                  {loading ? (
                    <Row className="text-center">
                      <Col>
                        <Spinner animation="grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <Button block="true" type="submit">
                      Lanjutkan
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
