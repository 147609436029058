import React from "react";
import "./index.css";
// import { Container } from "@material-ui/core";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Image,
  Spinner,
} from "react-bootstrap";
import Navbar from "../navbar";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/index.css";
import backArrow from "../../../assets/icon/back.svg";
import { useHistory } from "react-router-dom";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";

const Index = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);

  const state = location.state;

  const initialValues = {
    ktp_photo: state?.ktp_photo?.path,
    npwp_photo: state?.npwp_photo?.path,
    siup_photo: state?.siup_photo?.path,
    tdp_photo: state?.tdp_photo?.path,
    nib_photo: state?.nib_photo?.path,
  };

  const validationSchema = Yup.object({
    ktp_photo: Yup.string().required("Harus diisi!"),
    npwp_photo: Yup.string().required("Harus diisi!"),
    siup_photo: Yup.string().required("Harus diisi!"),
    tdp_photo: Yup.string().required("Harus diisi!"),
    nib_photo: Yup.string().required("Harus diisi!"),
  });

  const formik = useFormik({
    validateOnChange: false,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      const fd = new FormData();

      if (values.ktp_photo != state.ktp_photo.path) {
        fd.append("ktp_photo", values.ktp_photo);
      }
      if (values.npwp_photo != state.npwp_photo.path) {
        fd.append("npwp_photo", values.npwp_photo);
      }
      if (values.siup_photo != state.siup_photo.path) {
        fd.append("siup_photo", values.siup_photo);
      }
      if (values.tdp_photo != state.tdp_photo.path) {
        fd.append("tdp_photo", values.tdp_photo);
      }
      if (values.nib_photo != state.nib_photo.path) {
        fd.append("nib_photo", values.nib_photo);
      }

      // fd.append("ktp_photo", values.ktp_photo);
      // fd.append("npwp_photo", values.npwp_photo);
      // fd.append("siup_photo", values.siup_photo);
      // fd.append("tdp_photo", values.tdp_photo);
      // fd.append("nib_photo", values.nib_photo);
      setLoading(true);
      req
        .post({
          endpoint: `profile/${state.id}/document`,
          data: fd,
        })
        .then((res) => {
          if (res) {
            if (res.status == "success") {
              sweetalert
                .success(`Perubahan Dokumen Administrasi berhasil disimpan`)
                .then((response) => {
                  history.push("/profile");
                });
            } else {
              // sweetalert.error(`${"internal server error"}`);
              const respon = res.message;
              const object1 = {};
              Object.keys(respon).map((key) => {
                object1[key] = respon[key][0];
              });
              formik.setErrors(object1);
            }
            setLoading(false);
          }
        });
    },
  });
  const handleKtp = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg"
      ) {
        if (e.size < 1000000) {
          formik.setFieldValue("ktp_photo", e);
        } else {
          sweetalert.error(`Ukuran file maksimal 1mb`);
        }
      } else {
        sweetalert.error(`Format file harus jpeg, jpg atau png`);
      }
    }
  };

  const handleNpwp = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg"
      ) {
        formik.setFieldValue("npwp_photo", e);
      } else {
        sweetalert.error(`Format file harus jpeg, jpg atau png`);
      }
    }
  };

  const handleSiup = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg"
      ) {
        formik.setFieldValue("siup_photo", e);
      } else {
        sweetalert.error(`Format file harus jpeg, jpg atau png`);
      }
    }
  };

  const handleTdp = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg"
      ) {
        formik.setFieldValue("tdp_photo", e);
      } else {
        sweetalert.error(`Format file harus jpeg, jpg atau png`);
      }
    }
  };

  const handleNib = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg"
      ) {
        formik.setFieldValue("nib_photo", e);
      } else {
        sweetalert.error(`Format file harus jpeg, jpg atau png`);
      }
    }
  };
  React.useEffect(() => {}, [location]);
  return (
    <>
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  <Row style={{ marginTop: "70px" }}>
                    <Col xs="12">
                      <Image
                        src={backArrow}
                        alt="logo"
                        className="mt-3"
                        onClick={() => history.push("profile")}
                      />
                      <h4 className="heading">Edit Dokumen</h4>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="12">
                      <Row>
                        <Col xs="12">
                          <div className="form-group">
                            <label htmlFor="ktp_photo">
                              Foto KTP pemilik perusahaan
                            </label>
                            <div className="custom custom-file">
                              <input
                                id="ktp_photo"
                                type="file"
                                className="custom-file-input"
                                onChange={(event) => handleKtp(event)}
                              />
                              <label
                                htmlFor="ktp_photo"
                                className={
                                  formik.errors.ktp_photo &&
                                  formik.touched.ktp_photo
                                    ? "custom-file-label form-err"
                                    : "custom-file-label"
                                }
                              >
                                {formik.values.ktp_photo == state.ktp_photo.path
                                  ? formik.values.ktp_photo
                                  : formik.values.ktp_photo.name}
                              </label>
                            </div>
                          </div>
                          {formik.errors.ktp_photo &&
                            formik.touched.ktp_photo && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.ktp_photo}
                              </p>
                            )}

                          {/* <div className="form-group">
                            <label htmlFor="npwp_photo">Foto NPWP</label>
                            <div className="custom custom-file">
                              <input
                                id="npwp_photo"
                                type="file"
                                disabled
                                className="custom-file-input"
                                onChange={(event) => handleNpwp(event)}
                              />
                              <label
                                htmlFor="npwp_photo"
                                className={
                                  formik.errors.npwp_photo &&
                                  formik.touched.npwp_photo
                                    ? "custom-file-label form-err"
                                    : "custom-file-label"
                                }
                              >
                                {formik.values.npwp_photo ==
                                state.npwp_photo.path
                                  ? formik.values.npwp_photo
                                  : formik.values.npwp_photo.name}
                              </label>
                            </div>
                          </div>
                          {formik.errors.npwp_photo &&
                            formik.touched.npwp_photo && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.npwp_photo}
                              </p>
                            )} */}

                          <div className="form-group">
                            <label htmlFor="siup_photo">SIUP</label>
                            <div className="custom custom-file">
                              <input
                                id="siup_photo"
                                type="file"
                                className="custom-file-input"
                                onChange={(event) => handleSiup(event)}
                              />
                              <label
                                htmlFor="siup_photo"
                                className={
                                  formik.errors.siup_photo &&
                                  formik.touched.siup_photo
                                    ? "custom-file-label form-err"
                                    : "custom-file-label"
                                }
                              >
                                {formik.values.siup_photo ==
                                state.siup_photo.path
                                  ? formik.values.siup_photo
                                  : formik.values.siup_photo.name}
                              </label>
                            </div>
                          </div>

                          {formik.errors.siup_photo &&
                            formik.touched.siup_photo && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.siup_photo}
                              </p>
                            )}

                          <div className="form-group">
                            <label htmlFor="tdp_photo">TDP</label>
                            <div className="custom custom-file">
                              <input
                                id="tdp_photo"
                                type="file"
                                className="custom-file-input"
                                onChange={(event) => handleTdp(event)}
                              />
                              <label
                                htmlFor="tdp_photo"
                                className={
                                  formik.errors.tdp_photo &&
                                  formik.touched.tdp_photo
                                    ? "custom-file-label form-err"
                                    : "custom-file-label"
                                }
                              >
                                {formik.values.tdp_photo == state.tdp_photo.path
                                  ? formik.values.tdp_photo
                                  : formik.values.tdp_photo.name}
                              </label>
                            </div>
                          </div>

                          {formik.errors.tdp_photo &&
                            formik.touched.tdp_photo && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.tdp_photo}
                              </p>
                            )}

                          <div className="form-group">
                            <label htmlFor="nib_photo">NIB</label>
                            <div className="custom custom-file">
                              <input
                                id="nib_photo"
                                type="file"
                                name="nib_photo"
                                className="custom-file-input"
                                onChange={(event) => handleNib(event)}
                              />
                              <label
                                htmlFor="custom-file"
                                className={
                                  formik.errors.nib_photo &&
                                  formik.touched.nib_photo
                                    ? "custom-file-label form-err"
                                    : "custom-file-label"
                                }
                              >
                                {formik.values.nib_photo == state.nib_photo.path
                                  ? formik.values.nib_photo
                                  : formik.values.nib_photo.name}
                              </label>
                            </div>
                          </div>

                          {formik.errors.nib_photo &&
                            formik.touched.nib_photo && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.nib_photo}
                              </p>
                            )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12 pb-3">
                  {loading ? (
                    <Row className="text-center">
                      <Col>
                        <Spinner animation="grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <Button block="true" type="submit">
                      Simpan
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
