import React, { useState } from "react";
import "./index.css";
import Logo from "../../../assets/icon/sialek.png";
// import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Eye, EyeSlash, CardList, Key } from "react-bootstrap-icons";
import {
  Row,
  Col,
  Form,
  Image,
  Button,
  InputGroup,
  Container,
  Modal,
  Spinner,
} from "react-bootstrap";
import Navbar from "../navbar";
import Api from "../../../libraries/api";
import AuthHelper from "../../../libraries/auth-helper";
import "../../../styles/index.css";
import Swal from "sweetalert2";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";

const Index = () => {
  const [showpw1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      no_npwp: "",
      password: "",
    },
    validationSchema: Yup.object({
      no_npwp: Yup.string().required("Harus diisi!"),
      password: Yup.string().required("Harus diisi!"),
    }),
    onSubmit: (values) => {
      let params = {
        username: values.no_npwp,
        password: values.password,
      };
      setLoading(true);
      req
        .postWithNoHeaders({
          endpoint: "login",
          data: params,
        })
        .then((res) => {
          if (res) {
            if (res.status == "success") {
              setLoading(false);

              const roleid = res.data.user.role_id;
              const group = res.data.user.group.group;
              const telegramId = res.data.user.telegramId;
              const email = res.data.user.email;

              if (roleid === 2) {
                AuthHelper.setLogin(res.data);
                AuthHelper.setProfile(res.data);
                localStorage.setItem("roleid", roleid);
                localStorage.setItem("group", group);

                localStorage.setItem("telegramId", telegramId);
                localStorage.setItem("email", email);

                localStorage.setItem("rolename", "eksportir");
                history.push("/profile");
              }
              if (roleid === 3 && group === "direktorat") {
                AuthHelper.setLogin(res.data);
                AuthHelper.setProfile(res.data);
                localStorage.setItem("roleid", roleid);
                localStorage.setItem("group", group);

                localStorage.setItem("telegramId", telegramId);
                localStorage.setItem("email", email);

                localStorage.setItem("rolename", "pphnak");

                history.push("/pphnak/permohonan");
              }
              if (group === "direktorat" && roleid !== 3) {
                AuthHelper.setLogin(res.data);
                AuthHelper.setProfile(res.data);
                localStorage.setItem("roleid", roleid);
                localStorage.setItem("group", group);

                localStorage.setItem("telegramId", telegramId);
                localStorage.setItem("email", email);

                localStorage.setItem("rolename", "direktorat");
                history.push("/direktorat/permohonan");
              }
              if (group === "subdit") {
                AuthHelper.setLogin(res.data);
                AuthHelper.setProfile(res.data);
                localStorage.setItem("roleid", roleid);
                localStorage.setItem("group", group);

                localStorage.setItem("telegramId", telegramId);
                localStorage.setItem("email", email);

                localStorage.setItem("rolename", "subdit");
                history.push("/subdit/permohonan");
              }
            } else {
              sweetalert.error(`${res.message}`);
              setLoading(false);
            }
          }
        });
    },
  });
  const showPassword1 = () => {
    setShow1(!showpw1);
  };

  const history = useHistory();

  React.useEffect(() => {
    if (
      AuthHelper.isLoggedIn() == true &&
      localStorage.getItem("roleid") == 2
    ) {
      history.push("/profile");
    } else if (
      AuthHelper.isLoggedIn() == true &&
      localStorage.getItem("roleid") == 3
    ) {
      history.push("/pphnak/permohonan");
    } else if (
      AuthHelper.isLoggedIn() == true &&
      localStorage.getItem("roleid") == 4
    ) {
      history.push("/direktorat/permohonan");
    } else if (
      AuthHelper.isLoggedIn() == true &&
      (localStorage.getItem("roleid") == 10 ||
        localStorage.getItem("roleid") == 13)
    ) {
      history.push("/subdit/permohonan");
    }
  }, []);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            Persiapkan dokumen berikut dalam bentuk digital untuk melengkapi
            persyaratan administrasi saat melakukan pendaftaran. Pastikan
            dokumen yang akan di upload sudah jelas agar mempermudah dalam
            melakukan verifikasi pendaftaran anda.
          </p>

          <ul>
            <li>KTP</li>
            <li>NPWP</li>
            <li>SIUP</li>
            <li>TDP</li>
            <li>NIB</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            block="true"
            type="submit"
            className="btn-bg-primary"
            onClick={() => history.push("/register")}
          >
            Lanjutkan
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  <Row style={{ marginTop: "100px" }}>
                    <Col xs="12" className="text-center p-3">
                      <Image src={Logo} alt="logo" width="120px"></Image>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col xs="12">
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            id="no_npwp"
                            className="border-right-unset"
                          >
                            <CardList />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="no_npwp"
                          type="text"
                          placeholder="No. NPWP / Username"
                          value={formik.values.no_npwp}
                          onChange={formik.handleChange}
                          className="border-left-unset"
                        />
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            id="password"
                            className="border-right-unset"
                          >
                            <Key />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          name="password"
                          type={showpw1 ? "text" : "password"}
                          placeholder="Password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          className="border-left-unset border-right-unset"
                        />
                        <InputGroup.Prepend>
                          <InputGroup.Text
                            id="password2"
                            className="border-left-unset"
                          >
                            {showpw1 ? (
                              <Eye onClick={showPassword1} />
                            ) : (
                              <EyeSlash onClick={showPassword1} />
                            )}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      </InputGroup>
                      <Button
                        variant="light"
                        block="true"
                        onClick={() =>
                          history.push("forgot-password", { show: "test" })
                        }
                      >
                        Lupa Password?
                      </Button>
                      {loading ? (
                        <Row className="text-center">
                          <Col>
                            <Spinner animation="grow" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          </Col>
                        </Row>
                      ) : (
                        <Button block="true" type="submit">
                          Masuk
                        </Button>
                      )}

                      <Button
                        block="true"
                        variant="outline-primary"
                        onClick={handleShow}
                      >
                        Daftar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {(formik.touched.no_npwp && formik.errors.no_npwp) ||
              (formik.touched.password && formik.errors.password) ? (
                <Row>
                  <Col xs="12 pb-3">
                    <Button
                      disabled={true}
                      block="true"
                      className="btn btn-err"
                    >
                      Data yang anda masukkan belum benar.
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
