import React from "react";
import {
  Modal,
  Button,
  InputGroup,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import req from "../../helpers/axios";

const Changepassword = (props) => {
  const history = useHistory();
  const [showpw0, setShow0] = React.useState(false);
  const [showpw1, setShow1] = React.useState(false);
  const [showpw2, setShow2] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      old_password: "",
      newpassword: "",
      repassword: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Harus diisi!"),
      newpassword: Yup.string().required("Password Harus diisi"),
      repassword: Yup.string()
        .oneOf([Yup.ref("newpassword"), null], "Password tidak sama")
        .required("Password harus diisi"),
    }),
    onSubmit: (values) => {
      const params = {
        current_password: values.old_password,
        new_password: values.newpassword,
      };
      setLoading(true);
      req
        .post({
          endpoint: `change_password`,
          data: params,
        })
        .then((res) => {
          if (res) {
            if (res.status === "error") {
              Swal.fire({
                text: `${res.message}`,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              });
            } else if (res.status === "success") {
              Swal.fire({
                text: `${res.message}`,
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              }).then((response) => {
                history.go(0);
              });
            }
            setLoading(false);
          }
        });
    },
  });
  const showPassword0 = () => {
    setShow0(!showpw0);
  };
  const showPassword1 = () => {
    setShow1(!showpw1);
  };
  const showPassword2 = () => {
    setShow2(!showpw2);
  };
  return (
    <div>
      <Modal
        show={props.data.show}
        onHide={props.data.closeModal}
        style={{ overflow: "auto" }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header className="justify-content-center" closeButton>
            <Modal.Title>Ganti Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="mb-3">
              <Form.Control
                name="old_password"
                type={showpw0 ? "text" : "password"}
                placeholder="Password lama"
                value={formik.values.old_password}
                onChange={formik.handleChange}
                className={
                  formik.errors.old_password && formik.touched.old_password
                    ? "form-err border-right-unset"
                    : "border-right-unset"
                }
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="old_password"
                  className={
                    formik.errors.old_password && formik.touched.old_password
                      ? "form-err icon-radius border-left-unset"
                      : "border-left-unset icon-radius"
                  }
                >
                  {showpw0 ? (
                    <Eye onClick={showPassword0} />
                  ) : (
                    <EyeSlash onClick={showPassword0} />
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>

            <InputGroup className="mb-3">
              <Form.Control
                name="newpassword"
                type={showpw1 ? "text" : "password"}
                placeholder="Password baru"
                value={formik.values.newpassword}
                onChange={formik.handleChange}
                className={
                  formik.errors.newpassword && formik.touched.newpassword
                    ? "form-err border-right-unset"
                    : "border-right-unset"
                }
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="newpassword"
                  className={
                    formik.errors.newpassword && formik.touched.newpassword
                      ? "form-err icon-radius border-left-unset"
                      : "border-left-unset icon-radius"
                  }
                >
                  {showpw1 ? (
                    <Eye onClick={showPassword1} />
                  ) : (
                    <EyeSlash onClick={showPassword1} />
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>

            <InputGroup className="mb-3">
              <Form.Control
                name="repassword"
                type={showpw2 ? "text" : "password"}
                placeholder="Ketik ulang password baru"
                value={formik.values.repassword}
                onChange={formik.handleChange}
                className={
                  formik.errors.newpassword && formik.touched.newpassword
                    ? "form-err border-right-unset"
                    : "border-right-unset"
                }
              />
              <InputGroup.Prepend>
                <InputGroup.Text
                  id="repassword"
                  className={
                    formik.errors.newpassword && formik.touched.newpassword
                      ? "form-err icon-radius border-left-unset"
                      : "border-left-unset icon-radius"
                  }
                >
                  {showpw2 ? (
                    <Eye onClick={showPassword2} />
                  ) : (
                    <EyeSlash onClick={showPassword2} />
                  )}
                </InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <Row className="text-center">
                <Col>
                  <Spinner animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : (
              <Button block="true" type="submit" className="btn-bg-primary">
                OK
              </Button>
            )}

            {(formik.errors.newpassword && formik.touched.newpassword) ||
            (formik.errors.repassword && formik.touched.repassword) ? (
              <Button disabled={true} block="true" className="btn btn-err">
                Password tidak sama
              </Button>
            ) : null}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Changepassword;
