import React from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import Dropzone from "react-dropzone";
import "./index.css";
import FileUpload from "../../../../assets/icon/file.svg";
import Header from "../header";
import Navbar from "../../navbar";
import { Trash } from "react-bootstrap-icons";
import Select from "react-select";

import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useHistory } from "react-router";
import req from "../../../../helpers/axios";
import sweetalert from "../../../../helpers/sweetalert";

const Index = (props) => {
  const history = useHistory();

  const id_exportir = localStorage.getItem("id_exportir");
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState({});
  const [country, setCountry] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [reRender, setReRender] = React.useState(false);
  const [komoditas, setKomoditas] = React.useState([]);

  const [filePaths, setFilePaths] = React.useState([]);

  const onDrag = (e) => {
    if (e) {
      if (e[0].size < 1000000) {
        setData({ file: e });

        e.map((i) => {
          // return setImageName(i.name);
          formik.setFieldValue("namafile", i.name);
        });

        const fd = new FormData();
        fd.append("file", e[0]);

        req
          .post({
            endpoint: `acceleration/${id_exportir}/addDocument`,
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                const path = res.data.path || null;
                const setpath = [...filePaths];
                setpath.push(path);
                setFilePaths(setpath);
              }
            }
          });
      } else {
        sweetalert.error(`Ukuran file maksimal 1mb`);
      }
    }
  };

  const handleAdd = () => {
    if (
      formik.values.namafile === "" ||
      formik.values.nomordokumen === "" ||
      formik.values.tanggal === "" ||
      formik.values.namadokumen === ""
    ) {
      return sweetalert.info(
        `Jika ingin menambah dokumen pendukung, Gambar, Nomor dokumen, Nama dokumen dan tanggal penerbitan harus diisi`
      );
    }
    formik.setFieldValue("namafile", "");
    formik.setFieldValue("nomordokumen", "");
    formik.setFieldValue("namadokumen", "");

    const fd = new FormData();
    fd.append("file", data);
    let tmp = allData;
    const result = {
      ...data,
      namafile: formik.values.namafile,
      nomordokumen: formik.values.nomordokumen,
      tanggal: formik.values.tanggal,
      namadokumen: formik.values.namadokumen,
    };

    tmp.push(result);

    setAllData(tmp);
  };

  const initialValues = {
    jeniskomoditas: "",
    nilai: "",
    volume: "",
    negaratujuan: "",
    suratpermohonan: "",
    namafile: "",
    nomordokumen: "",
    namadokumen: "",
    tanggal: moment().format("YYYY-MM-DD"),
    catatan: "",
  };

  const validationSchema = Yup.object({
    jeniskomoditas: Yup.string().required("Harus diisi!"),
    nilai: Yup.number()
      .min(1)
      .required("Harus diisi!")
      .typeError("Masukan nomor"),
    volume: Yup.number()
      .min(1)
      .required("Harus diisi!")
      .typeError("Masukan nomor"),
    negaratujuan: Yup.string().required("Harus diisi!"),
  });

  const formik = useFormik({
    validateOnChange: false,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      delete values.namafile;
      delete values.nomordokumen;
      delete values.tanggal;

      const resultData = {
        commodity_id: values.jeniskomoditas,
        volume_capacity: values.volume,
        cost_value: values.nilai,
        destination_country_id: values.negaratujuan,
        notes: values.catatan,
        document: values.suratpermohonan,
      };

      const fd = new FormData();
      fd.append("commodity_id", resultData.commodity_id);
      fd.append("volume_capacity", resultData.volume_capacity);
      fd.append("cost_value", resultData.cost_value);
      fd.append("destination_country_id", resultData.destination_country_id);
      fd.append("notes", resultData.notes);

      fd.append("document", resultData.document);
      allData.map((item, index) => {
        fd.append("file_names[" + index + "]", item.namafile);
        fd.append("document_numbers[" + index + "]", item.nomordokumen);
        fd.append("document_names[" + index + "]", item.namadokumen);
        fd.append("document_dates[" + index + "]", item.tanggal);
        fd.append("file_paths[" + index + "]", filePaths[index]);
      });
      setLoading(true);
      req
        .post({
          endpoint: `acceleration/${id_exportir}/add`,
          data: fd,
        })
        .then((res) => {
          if (res) {
            if (res.status === "success") {
              sweetalert
                .success(
                  "Rencana Pasar Ekspor anda akan ditinjau kembali oleh PPHNAK, Terimakasih."
                )
                .then((response) => {
                  history.push("/permohonan");
                });
            } else {
              sweetalert.error(`${res.message}`);
            }
            setLoading(false);
          }
        });
    },
  });
  const getCountry = async () => {
    await req
      .get({
        endpoint: `countries`,
        params: {
          perPage: 999,
        },
      })
      .then((res) => {
        if (res) {
          setCountry(res.data.data);
        }
      });
  };

  const handleSuratPermohonan = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (e.size < 1000000) {
        formik.setFieldValue("suratpermohonan", e);
      } else {
        sweetalert.error(`Ukuran file maksimal 1mb`);
      }
    }
  };

  const deleteNotes = (index) => {
    allData.splice(index, 1);
    setReRender(!reRender);
  };

  const getData = async () => {
    await req
      .get({
        endpoint: `export-plant/${id_exportir}`,
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            const tmpKomoditas = res.data.commodities;
            const dataOptions = tmpKomoditas.map((e) => ({
              value: e.id,
              label: `${e.hs_code} - ${e.description}`,
            }));
            setKomoditas(dataOptions);
          }
        }
      });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      marginTop: -10,
      marginBottom: "1rem",
    }),
  };

  React.useEffect(() => {
    getData();
    getCountry();
    // Pass in a callback function!
  }, [reRender]);

  return (
    <>
      <Container fluid className="grid-root">
        <Row>
          <Col xs="12">
            <Navbar />
            <Row style={{ marginTop: "100px" }} className=" mb-3">
              <Col xs="12">
                <Header page="permohonan" />
              </Col>
              <Col xs="12">
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Label>Jenis Komoditas</Form.Label>
                  <Select
                    styles={customStyles}
                    defaultValue={{ values: "", label: "Pilih Komoditas" }}
                    name="jeniskomoditas"
                    options={komoditas}
                    onChange={(option) =>
                      formik.setFieldValue("jeniskomoditas", option.value)
                    }
                  />
                  {formik.errors.jeniskomoditas &&
                    formik.touched.jeniskomoditas && (
                      <p style={{ color: "#EB5757" }}>
                        {formik.errors.jeniskomoditas}
                      </p>
                    )}
                  <Form.Group controlId="nilai">
                    <Form.Label>Nilai (US$)</Form.Label>
                    <Form.Control
                      className={`forForm ${
                        formik.errors.nilai &&
                        formik.touched.nilai &&
                        "form-err"
                      }`}
                      type="text"
                      name="nilai"
                      value={formik.values.nilai}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  {formik.errors.nilai && formik.touched.nilai && (
                    <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                      {formik.errors.nilai}
                    </p>
                  )}
                  <Form.Group controlId="volume">
                    <Form.Label>Volume (Kg)</Form.Label>
                    <Form.Control
                      className={`forForm ${
                        formik.errors.volume &&
                        formik.touched.volume &&
                        "form-err"
                      }`}
                      type="text"
                      name="volume"
                      value={formik.values.volume}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  {formik.errors.volume && formik.touched.volume && (
                    <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                      {formik.errors.volume}
                    </p>
                  )}
                  <Form.Group controlId="negaratujuan">
                    <Form.Label>Negara Tujuan</Form.Label>
                    <Form.Control
                      as="select"
                      className={`forForm ${
                        formik.errors.negaratujuan &&
                        formik.touched.negaratujuan &&
                        "form-err"
                      }`}
                      name="negaratujuan"
                      value={formik.values.negaratujuan}
                      onChange={formik.handleChange}
                    >
                      <option value="">Pilih Negara</option>
                      {country.length > 0
                        ? country.map((item, index) => (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </Form.Control>
                  </Form.Group>
                  {formik.errors.negaratujuan &&
                    formik.touched.negaratujuan && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.negaratujuan}
                      </p>
                    )}

                  <div className="form-group">
                    <Form.Label>
                      Surat Permohonan Percepatan Ekspor (optional)
                    </Form.Label>
                    <div className="custom custom-file">
                      <input
                        id="suratpermohonan"
                        type="file"
                        name="suratpermohonan"
                        className="custom-file-input "
                        onChange={(event) => handleSuratPermohonan(event)}
                      />
                      <label
                        htmlFor="suratpermohonan"
                        className={`custom-file-label ${
                          formik.errors.suratpermohonan &&
                          formik.touched.suratpermohonan &&
                          "form-err"
                        }`}
                      >
                        {formik.values.suratpermohonan
                          ? formik.values.suratpermohonan.name
                          : "Surat Permohonan"}
                      </label>
                    </div>
                  </div>
                  {formik.errors.suratpermohonan &&
                    formik.touched.suratpermohonan && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.suratpermohonan}
                      </p>
                    )}

                  <div className="d-flex mb-2">
                    <div className="pr-2">
                      <h6>Dokumen Pendukung (optional)</h6>
                    </div>
                    <hr className="my-auto flex-grow-1 linebold" />
                  </div>

                  <Dropzone onDrop={(acceptedFiles) => onDrag(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <section
                        className="border-upload"
                        style={{ marginBottom: "1rem" }}
                      >
                        <div {...getRootProps()} className="border-upload-fill">
                          <input {...getInputProps()} />
                          <div className="border-fill">
                            <img
                              src={FileUpload}
                              alt="file"
                              className="pt-2 pb-2"
                            ></img>
                            <div className="border-image">Upload</div>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Nama File</Form.Label>
                    <Form.Control
                      className="forForm"
                      // value={data.file.name ? data.file.name : ""}
                      // disabled
                      type="text"
                      disabled
                      name="namafile"
                      value={formik.values.namafile}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Nomor Dokumen</Form.Label>
                    <Form.Control
                      className="forForm"
                      type="text"
                      name="nomordokumen"
                      value={formik.values.nomordokumen}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Nama Dokumen</Form.Label>
                    <Form.Control
                      className="forForm"
                      type="text"
                      name="namadokumen"
                      value={formik.values.namadokumen}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Tanggal Penerbitan</Form.Label>
                    <Form.Control
                      className="forForm"
                      type="date"
                      name="tanggal"
                      value={formik.values.tanggal}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <button
                    type="button"
                    className="button-add fsize16 txt-blue"
                    onClick={(e) => handleAdd(e)}
                  >
                    Tambah
                  </button>
                  <Row
                    style={{ marginLeft: 0, marginRight: 0 }}
                    className={allData.length > 0 ? "notes" : null}
                  >
                    <Col xs="12">
                      {allData.map((item, index) => (
                        <Row
                          key={index}
                          style={{ position: "relative", marginBottom: "10px" }}
                        >
                          <Col xs="6">Nama File:</Col>
                          <Col xs="6">
                            {item.namafile.length > 10
                              ? item.namafile.substring(0, 10) + "..."
                              : item.namafile}
                          </Col>
                          <Col xs="6">Nomor Dokumen:</Col>
                          <Col xs="6">{item.nomordokumen}</Col>
                          <Col xs="6">Tanggal Penerbitan : </Col>
                          <Col xs="6">{item.tanggal}</Col>
                          <Trash
                            style={{
                              position: "absolute",
                              right: 0,
                              bottom: "5px",
                            }}
                            onClick={() => deleteNotes(index)}
                          />
                        </Row>
                      ))}
                    </Col>
                  </Row>

                  <Form.Group controlId="catatan">
                    <Form.Label>Catatan</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="catatan"
                      value={formik.values.catatan}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  {loading ? (
                    <Row className="text-center">
                      <Col>
                        <Spinner animation="grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-block btn-bg-primary text-white mb-3"
                    >
                      Submit
                    </button>
                  )}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
