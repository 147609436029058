import React from "react";
import Navbar from "../navbar";
import { Row, Col, Image, Button, Container, Spinner } from "react-bootstrap";
import Edit from "../../../assets/icon/edit.svg";
import "../../../styles/index.css";
import { useHistory } from "react-router-dom";
import "./index.css";
import { Pencil } from "react-bootstrap-icons";
import profileStatic from "../../../assets/icon/sialek.png";
import Dropzone from "react-dropzone";
import sw from "../../../helpers/sweetalert";
import ModalImage from "../../modal/showimage";
import req from "../../../helpers/axios";
import { urlForImage } from "../../../helpers/env";
import axios from "axios";
import sweetalert from "../../../helpers/sweetalert";

const Index = (props) => {
  const [data, setData] = React.useState({});
  const [urlImage, setUrlImage] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [reRender, setReRender] = React.useState(false);
  const [isLoadCatalog, setIsLoadCatalog] = React.useState(false);

  const [forProfile, setForProfile] = React.useState({});

  const history = useHistory();
  const onEditKatalog = async (e) => {
    setIsLoadCatalog(true);
    const ev = e[0];
    if (ev.type === "application/pdf") {
      if (ev.size < 1000000) {
        const fd = new FormData();
        fd.append("photo", e[0]);
        await req
          .post({
            endpoint: `profile/${data.id}/catalog`,
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                setReRender(!reRender);
                sw.success(`Katalog berhasil diperbarui`);
              } else {
                sw.error(`Katalog gagal diperbarui`);
              }
              res.status === "success"
                ? sw.success("Katalog berhasil diperbarui")
                : sw.error("Katalog gagal diperbarui");
            }
          });
      } else {
        sw.error(`Ukuran file maksimal 1mb`);
      }
    } else {
      sw.error("Format file harus pdf");
    }
    setIsLoadCatalog(false);
  };
  const onDrag = async (e) => {
    const ev = e[0];
    if (
      ev.type === "image/jpeg" ||
      ev.type === "image/png" ||
      ev.type === "image/jpg"
    ) {
      if (ev.size < 1000000) {
        const fd = new FormData();
        fd.append("photo", e[0]);

        await req
          .post({
            endpoint: `profile/${data.id}/photo`,
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                sw.success("Profile berhasil diperbarui").then(
                  (resp) => setReRender(!reRender)
                  // history.go(0)
                );
              } else {
                sw.error("Profile gagal diperbarui");
              }
            }
          });
      } else {
        sw.error(`Ukuran file maksimal 1mb`);
      }
    } else {
      sw.error("Format gambar harus jpg, jpeg atau png");
    }
  };
  const getProfile = async () => {
    await req
      .post({
        endpoint: "profile",
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            setData(res.data);
            setForProfile({
              profileUrl: res.data.profile_picture,
              idProfile: res.data.id,
            });

            localStorage.setItem("profileUrl", res.data.profile_picture);
            localStorage.setItem("idProfile", res.data.id);
          } else {
          }
        }
      });
  };

  const getCatalog = async () => {
    setIsLoadCatalog(true);
    if (data.advertisement !== null) {
      window.open(
        `${urlForImage}/company_advertisement/${data.id}/${data.advertisement}`,
        "_blank"
      );
    } else {
      sweetalert.info(`${`Catalog tidak ada`}`);
    }
    setIsLoadCatalog(false);
  };
  const showModal = (doc, title) => {
    if (doc) {
      const tmpSplit = doc.split(".");

      const tmpIndex = tmpSplit.length > 0 ? tmpSplit.length - 1 : null;
      const tmpTypeData = tmpSplit[tmpIndex].toLowerCase();
      let forUrl = "";

      if (title === "ktp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.ktp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.ktp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "npwp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.npwp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.npwp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "siup") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.siup_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.siup_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "tdp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.tdp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.tdp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "nib") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.nib_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.nib_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  const detailEkspor = () => {
    localStorage.setItem("id_exportir", data.id);
    history.push("/permohonan", data);
  };

  React.useEffect(() => {
    getProfile();
  }, [reRender]);
  return (
    <div>
      <ModalImage data={{ show, closeModal, urlImage }} />
      <Container fluid className="grid-root">
        <Navbar reRender={reRender} data={forProfile} />
        <Row className="mt-4">
          <Col
            xs="12"
            style={{ marginTop: "80px" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div className="parentdiv text-center mt-3">
              <div>
                <Dropzone onDrop={(acceptedFiles) => onDrag(acceptedFiles)}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        {...getRootProps()}
                        style={{
                          position: "absolute",
                        }}
                        className="editlogo d-flex justify-content-center"
                      >
                        <input {...getInputProps()} />

                        <div className="border-fill">
                          <Pencil
                            color="white"
                            size="12"
                            className={{ position: "absolute" }}
                          />
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              <div className="square">
                <Image
                  // company_profile/2/n44NRQEhqFyvmKrUlIt2cyIdzR0AZk73oATygpJy

                  // profile_picture

                  // `${urlForImage}/company_profile/${data.id}/${data.profile_picture}`

                  src={`${
                    data.profile_picture != null
                      ? `${urlForImage}/company_profile/${data.id}/${data.profile_picture}`
                      : `${profileStatic}`
                  }`}
                  alt="Profile Eksportir"
                  className="fitSquare"
                ></Image>
              </div>
            </div>
            <h6 className="text-color-black">{data?.company_name}</h6>
            <Button
              block="true"
              className="btn-bg-primary mb-3"
              onClick={detailEkspor}
            >
              Detail Ekspor
            </Button>
          </Col>
          <Col xs="12">
            <div className="d-flex mb-2">
              <div className="pr-2">
                <h6>Informasi Perusahaan</h6>
              </div>
              <hr className="my-auto flex-grow-1 linebold" />
            </div>
            <h6 className="">Alamat Perusahaan</h6>
            <h6 className="mb-3 text-dark">{`${data?.city?.name}, ${data?.address}`}</h6>
            <h6>Telp/Fax/Wa</h6>
            <h6 className="mb-3 text-dark">{data?.phone_number}</h6>
            <h6>Virtual Catalog *(format file harus .pdf)</h6>
            <Row
              className="d-flex flex-column align-items-center justify-content-center text-center no-gutters"
              style={{ height: "40px" }}
            >
              <Col xs="10">
                {isLoadCatalog ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    block="true"
                    type="button"
                    className="btn-bg-secondary text-color-secondary"
                    onClick={getCatalog}
                  >
                    Tampilkan Virtual Katalog
                  </Button>
                )}
              </Col>
              <Col xs="2">
                <Dropzone
                  onDrop={(acceptedFiles) => onEditKatalog(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Image src={Edit} alt="edit"></Image>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <div className="d-flex">
              <div className="pr-2">
                <h6>Dokumentasi Administrasi</h6>
              </div>
              <hr className="my-auto flex-grow-1 linebold" />
            </div>

            <h6>KTP Pimpinan Perusahaan</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.ktp_photo.path, "ktp")}
            >
              View File
            </h6>

            <h6>NPWP</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.npwp_photo.path, "npwp")}
            >
              View File
            </h6>

            <h6>SIUP</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.siup_photo.path, "siup")}
            >
              View File
            </h6>

            <h6>TDP</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.tdp_photo.path, "tdp")}
            >
              View File
            </h6>

            <h6>NIB</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.nib_photo.path, "nib")}
            >
              View File
            </h6>
            <Button
              block="true"
              className="mb-3 btn-bg-third text-color-black"
              onClick={() => history.push("edit-document", data)}
            >
              Edit Dokumen
            </Button>
          </Col>

          <Col xs="12">
            <div className="d-flex">
              <div className="pr-2">
                <h6>Dokumentasi Administrasi</h6>
              </div>
              <hr className="my-auto flex-grow-1 linebold" />
            </div>

            <h6>Nama Contact Person</h6>
            <h6 className="mb-3 text-dark">{data?.person_in_charge}</h6>

            <h6>No. Hp</h6>
            <h6 className="mb-3 text-dark">{data?.pic_phone_number}</h6>

            <h6>Email</h6>
            <h6 className="mb-3 text-dark">{data?.email}</h6>

            <h6>Jabatan</h6>
            <h6 className="mb-3 text-dark">{data?.position}</h6>

            <Button
              block="true"
              className="btn-bg-third text-color-black mb-3"
              onClick={() => history.push("edit-contact", data)}
            >
              Edit Informasi
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
