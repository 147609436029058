import React from "react";
import Navbar from "../../container/navbar";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import "../../../styles/index.css";
import "./index.css";
import CardItem from "../../../components/card-rencana";
import Header from "./header";
import req from "../../../helpers/axios";
const Index = () => {
  const id_exportir = localStorage.getItem("id_exportir");
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const getData = async () => {
    setIsLoading(true);
    await req
      .get({
        endpoint: `exporter/${id_exportir}/markerPlan`,
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            setData(res.data.export_plant);
          }
        }
      });
    setIsLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Container fluid className="grid-root">
        <Row style={{ height: "100vh" }}>
          <Col xs="12">
            <Navbar />

            <Row style={{ marginTop: "100px" }}>
              <Col
                xs="12"
                // className="d-flex text-center justify-content-around"
              >
                <Header page="rencana" />
              </Col>
            </Row>
            {isLoading ? (
              <Row>
                <Col className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : data.length < 1 ? (
              <Row>
                <Col xs="12" className="text-center">
                  <p>- Tidak ada data -</p>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col xs="12">
                    {data.map((item, index) => (
                      <CardItem data={item} key={index} />
                    ))}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
