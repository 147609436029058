import Storage from "./storage";
import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, store: Store, ...rest }) => {
  let profile = Storage.get("profile");

  return (
    <Route
      {...rest}
      render={(props) =>
        profile ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const RedirectRoute = ({ component: Component, store: Store, ...rest }) => {
  let profile = Storage.get("profile");

  return (
    <Route
      {...rest}
      render={(props) =>
        profile ? (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const EksportirRoute = ({ component: Component, store: Store, ...rest }) => {
  let roleid = Storage.get("roleid");
  let profile = Storage.get("profile");

  return (
    <Route
      {...rest}
      render={(props) =>
        roleid == 2 && profile ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const PphnakRoute = ({ component: Component, store: Store, ...rest }) => {
  let roleid = Storage.get("roleid");
  let profile = Storage.get("profile");
  const group = Storage.get("group");
  const rolename = Storage.get("rolename");
  return (
    <Route
      {...rest}
      render={(props) =>
        roleid === 3 &&
        (group === "direktorat") & (rolename === "pphnak") &&
        profile ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const DirektoratRoute = ({ component: Component, store: Store, ...rest }) => {
  let roleid = Storage.get("roleid");
  let profile = Storage.get("profile");
  const group = Storage.get("group");
  const rolename = Storage.get("rolename");
  return (
    <Route
      {...rest}
      render={(props) =>
        group === "direktorat" &&
        rolename === "direktorat" &&
        roleid !== 3 &&
        profile ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const SubditRoute = ({ component: Component, store: Store, ...rest }) => {
  let roleid = Storage.get("roleid");
  let profile = Storage.get("profile");
  const group = Storage.get("group");
  const rolename = Storage.get("rolename");

  return (
    <Route
      {...rest}
      render={(props) =>
        group === "subdit" && rolename === "subdit" && profile ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export {
  PrivateRoute,
  RedirectRoute,
  EksportirRoute,
  PphnakRoute,
  DirektoratRoute,
  SubditRoute,
};
