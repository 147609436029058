/* eslint-disable eqeqeq */
import React from "react";
import Navbar from "../../container/navbar";
import { Row, Col, Container, Badge, Spinner } from "react-bootstrap";
import "../../../styles/index.css";
import "./index.css";
import CardItem from "../../../components/pphnak/card-profile-eksportir-permohonan";
import Header from "./header";
import req from "../../../helpers/axios";

const Index = () => {
  const [filter, setFilter] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const id_exportir = localStorage.getItem("id_exportir");

  const filterByStatus = [
    "All",
    "Baru",
    "Di Proses",
    "Di Kembalikan",
    "Update",
    "Selesai",
  ];
  const getData = async () => {
    setIsLoading(true);
    await req
      .get({
        endpoint: `exporter/${id_exportir}/acceleration`,
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            const accelerationData = res.data.acceleration;
            if (filter == 0) {
              setData(accelerationData);
            } else if (filter == 1) {
              const datas = accelerationData.filter((item) => {
                return item.latest_status == 0;
              });
              setData(datas);
            } else if (filter == 2) {
              const datas = accelerationData.filter((item) => {
                return item.latest_status == 4;
              });
              setData(datas);
            } else if (filter == 3) {
              const datas = accelerationData.filter((item) => {
                return item.latest_status == 1;
              });
              setData(datas);
            } else if (filter == 4) {
              const datas = accelerationData.filter((item) => {
                return item.latest_status == 2;
              });
              setData(datas);
            } else if (filter == 5) {
              const datas = accelerationData.filter((item) => {
                return item.latest_status == 5;
              });
              setData(datas);
            }
          }
        }
      });
    setIsLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, [filter]);
  return (
    <>
      <Container fluid className="grid-root">
        <Row style={{ height: "100vh" }}>
          <Col xs="12">
            <Navbar />

            <Row style={{ marginTop: "100px" }}>
              <Col
                xs="12"
                // className="d-flex text-center justify-content-around"
              >
                <Header page="permohonan" />
              </Col>
            </Row>

            <Row className="mt-2 mb-2" noGutters>
              <Col xs="12" className="filter">
                <p>Filter by Status :</p>
              </Col>
              <Col xs="12" className="d-flex justify-content-around tab-badge">
                {filterByStatus.map((item, index) => (
                  <Badge
                    className={filter === index && "badge-grey"}
                    onClick={() => setFilter(index)}
                    key={index}
                  >
                    {item}
                  </Badge>
                ))}
              </Col>
            </Row>
            {isLoading ? (
              <Row>
                <Col className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : data.length < 1 ? (
              <Row>
                <Col xs="12" className="text-center">
                  <p>- Tidak ada data -</p>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col xs="12">
                    {data.map((item, index) => (
                      <CardItem data={item} key={index} />
                    ))}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
