import React from "react";
import Navbar from "../navbar";
import { Row, Col, Container, Badge, Spinner } from "react-bootstrap";
import "../../../styles/index.css";
import "./index.css";
import CardItem from "../../../components/card-permohonan";
import { Plus } from "react-bootstrap-icons";
import Header from "./header";
import { useHistory } from "react-router-dom";
import req from "../../../helpers/axios";

const Index = () => {
  const history = useHistory();
  const [filter, setFilter] = React.useState(0);
  const [data, setData] = React.useState();
  const [acceleration, setAcceleration] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const id_exportir = localStorage.getItem("id_exportir");

  const filterByStatus = [
    "All",
    "Baru",
    "Di Proses",
    "Di Kembalikan",
    "Update",
    "Selesai",
  ];
  const getData = async () => {
    setIsLoading(true);
    await req
      .get({
        endpoint: `acceleration/${id_exportir}`,
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            // setData(res.data);
            // setAcceleration(res.data.acceleration);
            if (filter == 0) {
              setAcceleration(res.data.acceleration);
            } else if (filter == 1) {
              const datas = res.data.acceleration.filter((item) => {
                return item.latest_status == 0;
              });
              setAcceleration(datas);
            } else if (filter == 2) {
              const datas = res.data.acceleration.filter((item) => {
                return item.latest_status == 4;
              });
              setAcceleration(datas);
            } else if (filter == 3) {
              const datas = res.data.acceleration.filter((item) => {
                return item.latest_status == 1;
              });
              setAcceleration(datas);
            } else if (filter == 4) {
              const datas = res.data.acceleration.filter((item) => {
                return item.latest_status == 2;
              });
              setAcceleration(datas);
            } else if (filter == 5) {
              const datas = res.data.acceleration.filter((item) => {
                return item.latest_status == 5;
              });
              setAcceleration(datas);
            }
          }
        }
      });
    setIsLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, [filter]);
  return (
    <>
      <Container fluid className="grid-root">
        <Row style={{ height: "100vh" }}>
          <Col xs="12">
            <Navbar />

            <Row style={{ marginTop: "100px" }}>
              <Col
                xs="12"
                // className="d-flex text-center justify-content-around"
              >
                <Header page="permohonan" />
              </Col>
            </Row>
            <Row className="mt-2 mb-2" noGutters>
              <Col xs="12" className="filter">
                <p>Filter by Status :</p>
              </Col>
              <Col xs="12" className="d-flex justify-content-around tab-badge">
                {filterByStatus.map((item, index) => (
                  <Badge
                    className={filter === index && "badge-grey"}
                    onClick={() => setFilter(index)}
                    key={index}
                  >
                    {item}
                  </Badge>
                ))}
              </Col>
            </Row>

            {isLoading ? (
              <Row>
                <Col className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : acceleration.length < 1 ? (
              <Row>
                <Col xs="12" className="text-center">
                  <p>- Tidak ada data -</p>
                </Col>
              </Row>
            ) : (
              <>
                <Row style={{ marginBottom: "50px" }}>
                  <Col xs="12">
                    {acceleration.map((item, index) => (
                      <CardItem data={item} key={index} />
                    ))}
                  </Col>
                </Row>
              </>
            )}

            <div className="row-button-add">
              <button
                className="btn-add btn-bg-primary fsize12"
                onClick={() => history.push("/permohonan/add", id_exportir)}
              >
                <Plus color="#ffffff" size="30" /> Tambah Baru
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
