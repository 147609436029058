import React from "react";
import { useHistory } from "react-router-dom";

const Index = () => {
  const history = useHistory();

  const backPage = () => {
    history.go(-1);
  };
  return (
    <div className={"text-center"}>
      <h1>404</h1>
      <h1>page not found</h1>
      <button className="btn btn-primary" onClick={backPage}>
        Kembali
      </button>
    </div>
  );
};

export default Index;
