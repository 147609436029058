import React from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Image,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { InfoCircle } from "react-bootstrap-icons";
import Navbar from "../navbar";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/index.css";
import backArrow from "../../../assets/icon/back.svg";
import { useHistory } from "react-router-dom";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";
import SelectR from "react-select";

const Index = () => {
  const [kab, setKab] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();
  const history = useHistory();
  const state = location.state;
  const initialValues = {
    company_name: state.company_name,
    address: state.address,
    city_id: state.city_id,
    phone_number: state.phone_number,

    name_contact_person: state.person_in_charge,
    no_hp: state.pic_phone_number,
    email: state.email,
    telegramId: state.telegramId || "",
    position: state.position,

    nik: state.nik,
    npwp: state.npwp,
    nib: state.nib,
  };

  const validationSchema = Yup.object({
    company_name: Yup.string().required("Harus diisi!"),
    address: Yup.string().required("Harus diisi!"),
    city_id: Yup.string().required("Harus diisi!"),
    phone_number: Yup.number()
      .min(9999999999, "Nomor harus diantara 10 - 16 digit")
      .max(9999999999999999, "Nomor harus diantara 10 - 16 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),

    name_contact_person: Yup.string().required("Harus diisi!"),
    no_hp: Yup.number()
      .min(9999999999, "Nomor harus diantara 10 - 16 digit")
      .max(9999999999999999, "Nomor harus diantara 10 - 16 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),
    email: Yup.string().email("Email tidak valid").required("Harus diisi!"),
    telegramId: Yup.string().required("Harus diisi!"),
    position: Yup.string().required("Harus diisi!"),

    nik: Yup.number()
      .max(9999999999999999, "Nomor harus diantara 0 - 16 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),

    npwp: Yup.number()
      .max(9999999999999999, "Nomor harus diantara 0 - 16 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),

    nib: Yup.number()
      .max(999999999999999999, "Nomor harus diantara 0 - 18 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const tmpData = {
        company_name: values.company_name,
        email: values.email,
        telegramId: values.telegramId,
        phone_number: values.no_hp,
        address: values.address,
        city_id: values.city_id,
        person_in_charge: values.name_contact_person,
        position: values.position,
        pic_phone_number: values.phone_number,
        nik: values.nik,
        nib: values.nib,
        npwp: values.npwp,
      };
      setLoading(true);
      req
        .post({
          endpoint: `profile/${state.id}/information`,
          data: tmpData,
        })
        .then((res) => {
          if (res) {
            if (res.status === "success") {
              sweetalert
                .success("Contact Person berhasil diubah")
                .then((response) => {
                  history.push("/profile");
                });
            } else {
              const respon = res.data.message;
              const object1 = {};
              Object.keys(respon).map((key) => {
                object1[key] = respon[key][0];
              });
              formik.setErrors(object1);
            }
            setLoading(false);
          }
        });
    },
  });
  const getCities = async () => {
    await req
      .get({
        endpoint: "cities",
        params: {
          perPage: 600,
        },
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            let tmpKab = [];
            res.data.data.map((item) => {
              tmpKab.push({
                label: item.name,
                value: item.id,
              });
            });

            setKab(tmpKab);
          }
        }
      });
  };

  const onIdTelegram = () => {
    window.location = `https://t.me/kementan_bot?start=${state.user_id}`;
  };
  React.useEffect(() => {
    getCities();
  }, []);
  return (
    <>
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  <Row style={{ marginTop: "70px" }}>
                    <Col xs="12">
                      <Image
                        src={backArrow}
                        alt="logo"
                        className="mt-3"
                        onClick={() =>
                          history.push("profile", { show: "test" })
                        }
                      />
                      <h4 className="heading">Edit Contact</h4>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="12">
                      <Row>
                        <Col xs="12">
                          <Form.Group controlId="company_name">
                            <Form.Label>Nama perusahan</Form.Label>
                            <Form.Control
                              name="company_name"
                              type="text"
                              placeholder="Nama perusahan"
                              value={formik.values.company_name}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.company_name &&
                                formik.touched.company_name &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.company_name &&
                            formik.touched.company_name && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.company_name}
                              </p>
                            )}

                          <Form.Label>Kab/Kota</Form.Label>
                          <SelectR
                            className="mb-3"
                            maxMenuHeight={200}
                            defaultValue={{
                              values: formik.values.city_id,
                              label: `${state?.city?.name}`,
                            }}
                            name="city_id"
                            options={kab}
                            onChange={(option) =>
                              formik.setFieldValue("city_id", option.value)
                            }
                          />
                          {formik.errors.city_id && formik.touched.city_id && (
                            <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                              {formik.errors.city_id}
                            </p>
                          )}
                          <Form.Group controlId="address">
                            <Form.Label>Alamat Perusahaan</Form.Label>
                            <Form.Control
                              name="address"
                              type="text"
                              placeholder="Alamat Perusahaan"
                              value={formik.values.address}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.address &&
                                formik.touched.address &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.address && formik.touched.address && (
                            <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                              {formik.errors.address}
                            </p>
                          )}

                          <Form.Group controlId="phone_number">
                            <Form.Label>Telp/Fax/Whatsapp</Form.Label>
                            <Form.Control
                              name="phone_number"
                              type="text"
                              placeholder="Telp/Fax/Whatsapp"
                              value={formik.values.phone_number}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.phone_number &&
                                formik.touched.phone_number &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.phone_number &&
                            formik.touched.phone_number && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.phone_number}
                              </p>
                            )}

                          <Form.Group controlId="name_contact_person">
                            <Form.Label>Nama Contact Person</Form.Label>
                            <Form.Control
                              name="name_contact_person"
                              type="text"
                              placeholder="Contact person"
                              value={formik.values.name_contact_person}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.name_contact_person &&
                                formik.touched.name_contact_person &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.name_contact_person &&
                            formik.touched.name_contact_person && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.name_contact_person}
                              </p>
                            )}

                          <Form.Group controlId="no_hp">
                            <Form.Label>No. Hp</Form.Label>
                            <Form.Control
                              name="no_hp"
                              type="text"
                              placeholder="No. Hp"
                              value={formik.values.no_hp}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.no_hp &&
                                formik.touched.no_hp &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.no_hp && formik.touched.no_hp && (
                            <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                              {formik.errors.no_hp}
                            </p>
                          )}

                          <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              name="email"
                              type="text"
                              placeholder="Email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.email &&
                                formik.touched.email &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.email && formik.touched.email && (
                            <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                              {formik.errors.email}
                            </p>
                          )}

                          <Form.Group controlId="telegramId">
                            <Form.Label>
                              Chat Id Telegram{" "}
                              <OverlayTrigger
                                show={true}
                                placement={"right"}
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Klik untuk register telegram
                                  </Tooltip>
                                }
                              >
                                <InfoCircle onClick={onIdTelegram} />
                              </OverlayTrigger>
                            </Form.Label>
                            <Form.Control
                              name="telegramId"
                              type="text"
                              disabled
                              placeholder="Chat Id Telegram"
                              value={formik.values.telegramId}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.telegramId &&
                                formik.touched.telegramId &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.telegramId &&
                            formik.touched.telegramId && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.telegramId}
                              </p>
                            )}

                          <Form.Group controlId="position">
                            <Form.Label>Jabatan</Form.Label>
                            <Form.Control
                              name="position"
                              type="text"
                              placeholder="Jabatan"
                              value={formik.values.position}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.position &&
                                formik.touched.position &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.position &&
                            formik.touched.position && (
                              <p
                                style={{ marginTop: "-10px", color: "#EB5757" }}
                              >
                                {formik.errors.position}
                              </p>
                            )}

                          <Form.Group controlId="nik">
                            <Form.Label>No. KTP Pemilik Perusahaan</Form.Label>
                            <Form.Control
                              name="nik"
                              type="text"
                              placeholder="No. KTP Pemilik Perusahaan"
                              value={formik.values.nik}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.nik &&
                                formik.touched.nik &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.nik && formik.touched.nik && (
                            <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                              {formik.errors.nik}
                            </p>
                          )}
                          <Form.Group controlId="npwp">
                            <Form.Label>No. NPWP</Form.Label>
                            <Form.Control
                              name="npwp"
                              type="text"
                              disabled
                              placeholder="No. NPWP"
                              value={formik.values.npwp}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.npwp &&
                                formik.touched.npwp &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.npwp && formik.touched.npwp && (
                            <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                              {formik.errors.npwp}
                            </p>
                          )}

                          <Form.Group controlId="nib">
                            <Form.Label>NIB</Form.Label>
                            <Form.Control
                              name="nib"
                              type="text"
                              placeholder="NIB"
                              value={formik.values.nib}
                              onChange={formik.handleChange}
                              className={
                                formik.errors.nib &&
                                formik.touched.nib &&
                                "form-err"
                              }
                            />
                          </Form.Group>
                          {formik.errors.nib && formik.touched.nib && (
                            <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                              {formik.errors.nib}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12 pb-3">
                  {loading ? (
                    <Row className="text-center">
                      <Col>
                        <Spinner animation="grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <Button block="true" type="submit">
                      Simpan
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
