import React from "react";
import { Container, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import Navbar from "../navbar";
import logoSuccess from "../../../assets/icon/success.svg";
import backArrow from "../../../assets/icon/back.svg";
import { useHistory, useParams } from "react-router-dom";
import "../../../styles/index.css";
import axios from "axios";
import { url } from "../../../helpers/env";
import Swal from "sweetalert2";
import req from "../../../helpers/axios";
const Index = () => {
  let { token } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [txtResponse, setTxtResponse] = React.useState("");
  const [catched, setCatched] = React.useState(false);
  const [respTrue, setRespTrue] = React.useState(false);
  const [respFalse, setRespFalse] = React.useState(false);

  // const

  const verified = async () => {
    // api.post("/verification", {
    //   token,
    //   type: "register",
    // });
    const params = {
      token,
      type: "register",
    };
    setLoading(true);
    await req
      .post({
        endpoint: `new_password`,
        data: params,
      })
      .then((res) => {
        setRespTrue(true);
        setLoading(false);
        if (res) {
          if (res.status === "error") {
            setTxtResponse(res.message);
            Swal.fire({
              text: `${res.message}`,
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#63ACEF",
            });
          } else {
            setTxtResponse(res.message);
            Swal.fire({
              text: `${res.message}`,
              icon: "success",
              confirmButtonText: "OK",
              confirmButtonColor: "#63ACEF",
            });
          }
        }
      });
  };

  React.useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      verified();
    }, 1500);
  }, []);
  const history = useHistory();
  return (
    <>
      <Container fluid="true" className="grid-root">
        <Navbar />
        <Row style={{ marginTop: "100px" }}>
          <Col xs="12">
            <Row style={{ height: "85vh" }}>
              <Col xs="12">
                <Image
                  src={backArrow}
                  alt="logo"
                  className="mt-3"
                  onClick={() => history.push("/login", { show: "test" })}
                />
              </Col>
              <Col
                xs="12"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                {loading ? (
                  <Row>
                    <Col xs="12">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </Col>
                  </Row>
                ) : catched ? (
                  <Row>
                    <Col xs="12">
                      <p>Mohon maaf ada kesalahan dalam sistem</p>
                    </Col>
                  </Row>
                ) : respTrue ? (
                  <Row>
                    <Col xs="12">
                      <Image src={logoSuccess} alt="" className="mb-2" />
                      <p>
                        Selamat! akun anda telah di verifikasi, tekan tombol
                        dibawah untuk melakukan login
                      </p>
                    </Col>
                  </Row>
                ) : respFalse ? (
                  <Row>
                    <Col xs="12">
                      <Image src={logoSuccess} alt="" className="mb-2" />
                      <p>{txtResponse}</p>
                    </Col>
                  </Row>
                ) : null}
              </Col>
              <Col xs="12" className="d-flex align-items-end">
                <Button
                  block="true"
                  className="btn-bg-primary"
                  onClick={() => history.push("/login", { show: "test" })}
                >
                  Masuk
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
