import React from "react";
import Navbar from "../../container/navbar";
import { Row, Col, Container } from "react-bootstrap";
import "../../../styles/index.css";
import "./index.css";
import { PencilFill } from "react-bootstrap-icons";
import ModalChangePassword from "../../modal/changepassword";
import ModalChangeNotif from "../../modal/changenotifemail";
import ModalChangeTelegram from "../../modal/changeidtelegram";

const Index = (props) => {
  const [data, setData] = React.useState({});
  const [urlImage, setUrlImage] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [reRender, setReRender] = React.useState(false);
  const [isLoadCatalog, setIsLoadCatalog] = React.useState(false);
  const [showNotif, setShowNotif] = React.useState(false);
  const [showTelegram, setShowTelegram] = React.useState(false);

  const closeModal = async () => {
    await setShow(false);
  };

  const handleModal = async () => {
    await setShow(true);
  };

  const closeModalNotif = async () => {
    await setShowNotif(false);
  };

  const handleModalNotif = async () => {
    await setShowNotif(true);
  };

  const closeModalTelegram = async () => {
    await setShowTelegram(false);
  };

  const handleModalTelegram = async () => {
    await setShowTelegram(true);
  };

  React.useEffect(() => {}, [reRender]);
  return (
    <div>
      {/* <ModalImage data={{ show, closeModal, urlImage }} /> */}
      <ModalChangePassword data={{ show, closeModal }} />
      <ModalChangeNotif data={{ showNotif, closeModalNotif }} />
      <ModalChangeTelegram data={{ showTelegram, closeModalTelegram }} />
      <Container fluid className="grid-root">
        <Navbar reRender={reRender} />
        <Row className="mt-4">
          <Col xs="12" style={{ marginTop: "70px" }}>
            <h6>
              Password <PencilFill color="blue" onClick={handleModal} />
            </h6>
            <h6 className="mb-3 text-dark">**************</h6>

            <h6>
              Email for Notification
              <PencilFill color="blue" onClick={handleModalNotif} />
            </h6>
            <h6 className="mb-3 text-dark">
              {localStorage.getItem("email") ?? "-"}
            </h6>

            <h6>
              ID Telegram
              <PencilFill color="blue" onClick={handleModalTelegram} />
            </h6>
            <h6 className="mb-3 text-dark">
              {localStorage.getItem("telegramId") ?? "-"}
            </h6>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
