import React from "react";
import Navbar from "../../container/navbar";
// import { Container } from "@material-ui/core";
import { Row, Col, Image, Button, Container, Spinner } from "react-bootstrap";
import Profile from "../../../assets/image/profile.svg";
import "../../../styles/index.css";
import { useHistory, useParams } from "react-router-dom";
import ModalImage from "../../modal/showimage";
import req from "../../../helpers/axios";
import { urlForImage } from "../../../helpers/env";
import profileStatic from "../../../assets/icon/sialek.png";
import axios from "axios";
import sweetalert from "../../../helpers/sweetalert";

const Index = (props) => {
  const [data, setData] = React.useState({});
  const [show, setShow] = React.useState(false);
  const [urlImage, setUrlImage] = React.useState("");
  const [isLoadCatalog, setIsLoadCatalog] = React.useState(false);

  let { id } = useParams();

  const history = useHistory();
  const getProfile = async () => {
    await req
      .get({
        endpoint: `exporter/${id}/show`,
        data: {
          id,
        },
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setData(res.data);
          }
        }
      });
  };

  const showModal = (doc, title) => {
    if (doc) {
      const tmpSplit = doc.split(".");

      const tmpIndex = tmpSplit.length > 0 ? tmpSplit.length - 1 : null;
      const tmpTypeData = tmpSplit[tmpIndex].toLowerCase();
      let forUrl = "";

      if (title === "ktp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.ktp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.ktp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "npwp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.npwp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.npwp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "siup") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.siup_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.siup_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "tdp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.tdp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.tdp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "nib") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.id}/${data.nib_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.id}/${data.nib_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }
    }
  };
  const closeModal = () => {
    setShow(false);
  };
  const detailEkspor = (id) => {
    localStorage.setItem("id_exportir", id);
    history.push("/profile-eksportir/permohonan");
  };

  const getCatalog = async () => {
    setIsLoadCatalog(true);
    if (data.advertisement !== null) {
      window.open(
        `${urlForImage}/company_advertisement/${data.id}/${data.advertisement}`,
        "_blank"
      );
    } else {
      sweetalert.info(`${`Catalog tidak ada`}`);
    }

    setIsLoadCatalog(false);
  };

  React.useEffect(() => {
    getProfile();
  }, []);
  return (
    <div>
      <ModalImage data={{ show, closeModal, urlImage }} />
      <Container fluid className="grid-root">
        <Navbar />
        <Row style={{ marginTop: "100px" }}>
          <Col
            xs="12"
            className="d-flex justify-content-center align-items-center"
          >
            <div
              style={{
                width: "80px",
                height: "80px",
                position: "relative",
                overflow: "hidden",
                borderRadius: "10px",
                alignItems: "center",
              }}
            >
              <Image
                style={{ position: "absolute", objectFit: "cover" }}
                src={`${
                  data.profile_picture != null
                    ? `${urlForImage}/company_profile/${data.id}/${data.profile_picture}`
                    : `${profileStatic}`
                }`}
                // src={`${urlForImage}/company_profile/${data.id}/${data.profile_picture}`}
                alt=""
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs="12"
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <h6 className="text-color-black">{data?.company_name}</h6>
            <Button
              block="true"
              className="btn-bg-primary mb-3"
              onClick={() => detailEkspor(data.id)}
            >
              Detail Ekspor
            </Button>
          </Col>
          <Col xs="12">
            <div className="d-flex mb-2">
              <div className="pr-2">
                <h6>Informasi Perusahaan</h6>
              </div>
              <hr className="my-auto flex-grow-1 linebold" />
            </div>
            <h6 className="">Alamat Perusahaan</h6>
            <h6 className="mb-3 text-dark">{`${data?.city?.name}, ${data?.address}`}</h6>
            <h6>Telp/Fax/Wa</h6>
            <h6 className="mb-3 text-dark">{data?.phone_number}</h6>
            <h6>Virtual Catalog *(format file harus .pdf)</h6>
            <Row
              className="d-flex flex-column align-items-center justify-content-center text-center no-gutters"
              style={{ height: "40px" }}
            >
              <Col xs="12">
                {isLoadCatalog ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <Button
                    block="true"
                    type="button"
                    className="btn-bg-secondary text-color-secondary"
                    onClick={getCatalog}
                  >
                    Tampilkan Virtual Katalog
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="12">
            <div className="d-flex">
              <div className="pr-2">
                <h6>Dokumentasi Administrasi</h6>
              </div>
              <hr className="my-auto flex-grow-1 linebold" />
            </div>

            <h6>KTP Pimpinan Perusahaan</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.ktp_photo.path, "ktp")}
            >
              View File
            </h6>

            <h6>NPWP</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.npwp_photo.path, "npwp")}
            >
              View File
            </h6>

            <h6>SIUP</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.siup_photo.path, "siup")}
            >
              View File
            </h6>

            <h6>TDP</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.tdp_photo.path, "tdp")}
            >
              View File
            </h6>

            <h6>NIB</h6>
            <h6
              className="text-with-border-bottom"
              onClick={() => showModal(data.nib_photo.path, "nib")}
            >
              View File
            </h6>
          </Col>

          <Col xs="12">
            <div className="d-flex">
              <div className="pr-2">
                <h6>Dokumentasi Administrasi</h6>
              </div>
              <hr className="my-auto flex-grow-1 linebold" />
            </div>

            <h6>Nama Contact Person</h6>
            <h6 className="mb-3 text-dark">{data?.person_in_charge}</h6>

            <h6>No. Hp</h6>
            <h6 className="mb-3 text-dark">{data?.pic_phone_number}</h6>

            <h6>Email</h6>
            <h6 className="mb-3 text-dark">{data?.email}</h6>

            <h6>Jabatan</h6>
            <h6 className="mb-3 text-dark">{data?.position}</h6>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Index;
