import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../../styles/index.css";
import "../index.css";
import { useHistory } from "react-router-dom";

const CardItem = (props) => {
  const history = useHistory();
  // const { status, id } = props.data;
  const data = props.data;

  const { latest_status } = data;
  const baru = (
    <button className={"button-proses bg-color-baru fsize10"}>Baru</button>
  );
  const menunggu = (
    <button className={"button-proses bg-color-menunggu fsize10"}>
      Menunggu
    </button>
  );
  const diproses = (
    <button className={"button-proses bg-color-diproses fsize10"}>
      Di Proses
    </button>
  );
  const dikembalikan = (
    <button className={"button-proses bg-color-dikembalikan fsize10"}>
      Di Kembalikan
    </button>
  );
  const update = (
    <button className={"button-proses bg-color-update fsize10"}>Update</button>
  );
  const selesai = (
    <button className={"button-proses bg-color-selesai fsize10"}>
      Selesai
    </button>
  );

  const forSwitch = () => {
    switch (latest_status) {
      case "0":
        return baru;
      case "1":
        return dikembalikan;
      case "2":
        return update;
      case "3":
        return menunggu;
      case "4":
        return diproses;
      case "5":
        return selesai;
      default:
        break;
    }
  };
  return (
    <>
      <Card className="shadow mb-3">
        <Card.Body className="card-box">
          <Row className="mb-2">
            <Col xs="12">
              <p className="fsize21 font-weight-bold">{data?.company_name}</p>
            </Col>
          </Row>

          <Row>
            <Col xs="12">
              <Row>
                <Col xs="6">
                  <div>
                    <p>Telp/Fax/Wa</p>
                    <p className="font-weight-bold">{data?.phone_number}</p>
                  </div>
                  <div>
                    <p>Alamat Perusahaan</p>
                    <p className="font-weight-bold">{data?.address}</p>
                  </div>
                </Col>

                <Col xs="6">
                  <div>
                    <p>Email</p>
                    <p className="font-weight-bold">{data?.email}</p>
                  </div>
                  <div>
                    <p>Contact person</p>
                    <p className="font-weight-bold">{data?.person_in_charge}</p>
                  </div>
                </Col>
              </Row>

              <div>
                <h6
                  className="font-weight-bold"
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    right: "15px",
                    color: "#63ACEF",
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `/profile-eksportir/detail/${data.id}`,
                    })
                  }
                >
                  Detail
                </h6>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CardItem;
