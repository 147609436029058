import React from "react";
import Navbar from "../navbar";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import "../../../styles/index.css";
import "./index.css";
import CardItem from "../../../components/card-akses";
import { Plus } from "react-bootstrap-icons";
import Header from "./header";
import { useHistory } from "react-router-dom";
import req from "../../../helpers/axios";

const Index = () => {
  const history = useHistory();
  const id_exportir = localStorage.getItem("id_exportir");
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);

  const getData = async () => {
    setIsLoading(true);
    await req
      .get({
        endpoint: `market-plant/${id_exportir}`,
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            setData(res.data);
          }
        }
      });
    setIsLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Container fluid className="grid-root">
        <Row style={{ height: "100vh" }}>
          <Col xs="12">
            <Navbar />

            <Row style={{ marginTop: "100px" }}>
              <Col
                xs="12"
                // className="d-flex text-center justify-content-around"
              >
                <Header page="akses" />
              </Col>
            </Row>
            {isLoading ? (
              <Row>
                <Col className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : data ? (
              data.market_plant.length < 1 ? (
                <Row>
                  <Col xs="12" className="text-center">
                    <p>- Tidak ada data -</p>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs="12">
                    {data.market_plant.map((item, index) => (
                      <CardItem data={item} key={index} />
                    ))}
                  </Col>
                </Row>
              )
            ) : null}
            <div className="row-button-add">
              <button
                className="btn-add btn-bg-primary fsize12"
                onClick={() => history.push("/akses-ekspor/add", id_exportir)}
              >
                <Plus color="#ffffff" size="30" /> Tambah Baru
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
