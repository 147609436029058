import React, { useState, useEffect } from "react";
import { loadModules } from "esri-loader";
import tmpJson from "../../../json/latlong.json";
import sweetalert from "../../../helpers/sweetalert";
const BermudaTriangle = (props) => {
  const { datas, reRender, setReRender } = props;

  // console.log(datas);
  const [graphic, setGraphic] = useState(null);
  Number.prototype.format = function (n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
  };

  useEffect(() => {
    if (reRender === true) {
      props.view.graphics.removeAll();

      loadModules([
        "esri/Graphic",
        "esri/geometry/Point",
        "esri/geometry/Multipoint",
        "esri/widgets/Popup",
        "esri/PopupTemplate",
      ])
        .then(([Graphic, Multipoint, PopupTemplate]) => {
          setReRender(false);
          const simpleMarkerSymbol = {
            type: "picture-marker",
            url: "/sialek.png",
            width: "46px",
            height: "46px",
          };

          const popupTemplate = {
            title: "{name}",
            content: "{data}",
          };
          setGraphic(null);

          datas.length > 0 &&
            datas.map((item) => {
              const point = {
                //Create a point
                type: "point",
                longitude: item?.datasets?.fill?.longitude ?? 0,
                latitude: item?.datasets?.fill?.latitude ?? 0,
              };
              let table = `<div class="row justify-content-around">
              <div class='col-6 '>Komoditas: </div>
              <div class='col-6 '>${item.data?.cmdDescE || "--"}</div>
            </div>
  
              `;

              table += `<div class="row justify-content-around">

              <div class='col-6 mb-2'>Partner :</div>
              <div class='col-6'>${item.data?.ptTitle || "--"}</div>
              
              <div class='col-6' mb-2>Nilai (USD) :</div>
              <div class='col-6'>${
                item.data?.TradeValue?.format(2, 3) || "0"
              }</div>

               <div class='col-6' mb-2>Berat (Kg) :</div>
              <div class='col-6'>${
                item.data?.NetWeight?.format(2, 3) || "0"
              }</div>

              <div class='col-6 mb-2'>Satuan :</div>
              <div class='col-6'>${item.data?.qtDesc || "--"}</div>

              <div class='col-6 mb-2'>Kuantity :</div>
              <div class='col-6'>${item.data?.TradeQuantity || "0"}</div>

             

              
            
            </div>`;
              const attributes = {
                name: `Negara Pemasok : ${item.datasets?.label || "--"}`,
                data: table,
              };
              const graphic = new Graphic({
                geometry: point,
                symbol: simpleMarkerSymbol,
                attributes: attributes,
                popupTemplate: popupTemplate,
              });
              setGraphic(graphic);
              props.view.graphics.add(graphic);
            });
        })
        .catch((err) => console.log(err));

      return function cleanup() {
        props.view.graphics.remove(graphic);
      };
    }
  }, [datas, reRender]);

  return null;
};

export default BermudaTriangle;
