import React, { useState } from "react";
import "./index.css";
import "../../../styles/index.css";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import Navbar from "../navbar";
import backArrow from "../../../assets/icon/back.svg";
import { useHistory } from "react-router-dom";
import { Eye, EyeSlash } from "react-bootstrap-icons";
// import logic from "./_logic";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";
import SelectR from "react-select";

const Index = (props) => {
  // const { test } = logic(props);
  const [kab, setKab] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadSubmit, setLoadSubmit] = useState(false);
  const [showpw1, setShow1] = useState(false);
  const [timeline, setTimeline] = useState(1);
  const history = useHistory();
  const validateTimeline1 = Yup.object({
    company_name: Yup.string().required("Harus diisi!"),
    email: Yup.string().email("Email tidak valid").required("Harus diisi!"),
    password: Yup.string().required("Harus diisi!"),
    address: Yup.string().required("Harus diisi!"),
    phone_number: Yup.number()
      .min(9999999999, "Nomor harus diantara 10 - 16 digit")
      .max(9999999999999999, "Nomor harus diantara 10 - 16 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),

    person_in_charge: Yup.string().required("Harus diisi!"),
    pic_phone_number: Yup.number()
      .min(9999999999, "Nomor harus diantara 10 - 16 digit")
      .max(9999999999999999, "Nomor harus diantara 10 - 16 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),
    city_id: Yup.string().required("Harus diisi!"),
    position: Yup.string().required("Harus diisi!"),
  });

  const validateTimeline2 = Yup.object({
    ktp_photo: Yup.string().required("Harus diisi!"),
    // nik: Yup.string().required("Harus diisi!"),
    nik: Yup.number()
      .max(9999999999999999, "Nomor harus diantara 0 - 16 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),
    npwp_photo: Yup.string().required("Harus diisi!"),
    npwp: Yup.number()
      .max(9999999999999999, "Nomor harus diantara 0 - 16 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),
    siup_photo: Yup.string().required("Harus diisi!"),
    tdp_photo: Yup.string().required("Harus diisi!"),
    nib_photo: Yup.string().required("Harus diisi!"),
    nib: Yup.number()
      .max(999999999999999999, "Nomor harus diantara 0 - 18 digit")
      .required("Harus diisi")
      .typeError("Masukan nomor"),
  });
  const showPassword1 = () => {
    setShow1(!showpw1);
  };
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      company_name: "",
      email: "",
      password: "",

      address: "",

      phone_number: "",
      person_in_charge: "",
      pic_phone_number: "",
      city_id: "",
      position: "",

      ktp_photo: "",
      nik: "",
      npwp_photo: "",
      npwp: "",
      siup_photo: "",
      tdp_photo: "",
      nib_photo: "",
      nib: "",
    },
    validationSchema: timeline === 1 ? validateTimeline1 : validateTimeline2,
    onSubmit: (values, actions) => {
      if (timeline === 2) {
        setLoadSubmit(true);
        const fd = new FormData();
        fd.append("company_name", values.company_name);
        fd.append("email", values.email);
        fd.append("password", values.password);
        fd.append("address", values.address);
        fd.append("person_in_charge", values.person_in_charge);
        fd.append("phone_number", values.phone_number);
        fd.append("pic_phone_number", values.pic_phone_number);
        fd.append("city_id", values.city_id);
        fd.append("position", values.position);

        fd.append("ktp_photo", values.ktp_photo);
        fd.append("nik", values.nik);
        fd.append("npwp_photo", values.npwp_photo);
        fd.append("npwp", values.npwp);
        fd.append("siup_photo", values.siup_photo);
        fd.append("tdp_photo", values.tdp_photo);
        fd.append("nib_photo", values.nib_photo);
        fd.append("nib", values.nib);

        //this line for send request post

        req
          .post({
            endpoint: "register",
            data: fd,
          })
          .then((res) => {
            setLoadSubmit(false);
            if (res) {
              if (res.status == "success") {
                sweetalert
                  .success(
                    `Akun anda berhasil didaftarkan. lakukan verifikasi akun melalui link yang kami kirim ke email anda`
                  )
                  .then((response) => {
                    localStorage.setItem("rg", 1);
                    history.push("/konfirmasi");
                  });
              } else {
                const respon = res?.message;
                const object1 = {};
                Object.keys(respon).map((key) => {
                  object1[key] = respon[key][0];
                });
                formik.setErrors(object1);
              }
            }
          });
      }
      if (timeline === 1) {
        const fd = new FormData();
        fd.append("company_name", values.company_name);
        fd.append("email", values.email);
        fd.append("password", values.password);
        fd.append("address", values.address);
        fd.append("person_in_charge", values.person_in_charge);
        fd.append("phone_number", values.phone_number);
        fd.append("pic_phone_number", values.pic_phone_number);
        fd.append("city_id", values.city_id);
        fd.append("position", values.position);

        //this line for send request post

        req
          .post({
            endpoint: "register1",
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status == "success") {
                setLoading(true);
                setTimeout(() => {
                  actions.setTouched({});
                  actions.setSubmitting(false);
                  setLoading(false);
                  setTimeline(2);
                }, 1000);
              } else {
                const respon = res.message;
                const object1 = {};
                Object.keys(respon).map((key) => {
                  object1[key] = respon[key][0];
                });
                formik.setErrors(object1);
              }
            }
          });
      }
    },
  });

  const getCities = async () => {
    await req
      .get({
        endpoint: "cities",
        params: {
          perPage: 99999,
        },
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            let tmpKab = [];
            res.data.data.map((item) => {
              tmpKab.push({
                label: item.name,
                value: item.id,
              });
            });

            setKab(tmpKab);
          } else {
            sweetalert.error(`${res?.message}`);
          }
        }
      });
  };
  React.useEffect(() => {
    getCities();
  }, []);
  const handleKtp = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg" ||
        e.type === "application/pdf"
      ) {
        if (e.size < 1000000) {
          formik.setFieldValue("ktp_photo", e);
        } else {
          sweetalert.error(`Ukuran file maksimal 1mb`);
        }
      } else {
        sweetalert.info(`${`Format gambar harus jpg, jpeg, png atau pdf`}`);
      }
    }
  };

  const handleNpwp = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg" ||
        e.type === "application/pdf"
      ) {
        if (e.size < 1000000) {
          formik.setFieldValue("npwp_photo", e);
        } else {
          sweetalert.error(`Ukuran file maksimal 1mb`);
        }
      } else {
        sweetalert.info(`${`Format gambar harus jpg, jpeg, png atau pdf`}`);
      }
    }
  };

  const handleSiup = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg" ||
        e.type === "application/pdf"
      ) {
        if (e.size < 1000000) {
          formik.setFieldValue("siup_photo", e);
        } else {
          sweetalert.error(`Ukuran file maksimal 1mb`);
        }
      } else {
        sweetalert.info(`${`Format gambar harus jpg, jpeg, png atau pdf`}`);
      }
    }
  };

  const handleTdp = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg" ||
        e.type === "application/pdf"
      ) {
        if (e.size < 1000000) {
          formik.setFieldValue("tdp_photo", e);
        } else {
          sweetalert.error(`Ukuran file maksimal 1mb`);
        }
      } else {
        sweetalert.info(`${`Format gambar harus jpg, jpeg, png atau pdf`}`);
      }
    }
  };

  const handleNib = (event) => {
    const e = event.currentTarget.files[0];
    if (e) {
      if (
        e.type === "image/jpeg" ||
        e.type === "image/png" ||
        e.type === "image/jpg" ||
        e.type === "application/pdf"
      ) {
        if (e.size < 1000000) {
          formik.setFieldValue("nib_photo", e);
        } else {
          sweetalert.error(`Ukuran file maksimal 1mb`);
        }
      } else {
        sweetalert.info(`${`Format gambar harus jpg, jpeg, png atau pdf`}`);
      }
    }
  };

  return (
    <>
      <Container fluid="true" className="grid-root" style={{ height: "100%" }}>
        <Navbar />
        {loading ? (
          <Row style={{ marginTop: "100px" }}>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Spinner animation="grow" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        ) : (
          <Row style={{ marginTop: "70px" }}>
            <Col xs="12">
              <Form onSubmit={formik.handleSubmit}>
                <Row className={timeline === 1 ? "d-block" : "d-none"}>
                  <Col xs="12">
                    <Image
                      src={backArrow}
                      alt="logo"
                      className="mt-3"
                      onClick={() => history.push("/login", { show: "test" })}
                    />
                    <h4 className="heading">Daftar</h4>
                    <p className="mt-4">Profil Pelaku Usaha Ekspor</p>
                    <Form.Group controlId="company_name">
                      <Form.Control
                        name="company_name"
                        type="text"
                        placeholder="Nama perusahan"
                        value={formik.values.company_name}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.company_name &&
                          formik.touched.company_name &&
                          "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.company_name &&
                      formik.touched.company_name && (
                        <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                          {formik.errors.company_name}
                        </p>
                      )}
                    <Form.Group controlId="email">
                      <Form.Control
                        name="email"
                        type="text"
                        placeholder="Email perusahan"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.email &&
                          formik.touched.email &&
                          "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.email && formik.touched.email && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.email}
                      </p>
                    )}
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="password"
                        type={showpw1 ? "text" : "password"}
                        placeholder="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.password && formik.touched.password
                            ? "form-err border-right-unset"
                            : "border-right-unset"
                        }
                      />
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className={
                            formik.errors.password && formik.touched.password
                              ? "form-err icon-radius border-left-unset"
                              : "border-left-unset icon-radius"
                          }
                          id="password"
                        >
                          {showpw1 ? (
                            <Eye onClick={showPassword1} />
                          ) : (
                            <EyeSlash onClick={showPassword1} />
                          )}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                    {formik.errors.password && formik.touched.password && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.password}
                      </p>
                    )}

                    <Form.Group controlId="address">
                      <Form.Control
                        name="address"
                        type="text"
                        placeholder="Alamat Perusahaan"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.address &&
                          formik.touched.address &&
                          "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.address && formik.touched.address && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.address}
                      </p>
                    )}

                    <SelectR
                      className="mb-3"
                      maxMenuHeight={200}
                      defaultValue={{ values: "", label: "Pilih Kab/Kota" }}
                      name="city_id"
                      options={kab}
                      onChange={(option) =>
                        formik.setFieldValue("city_id", option.value)
                      }
                    />
                    {formik.errors.city_id && formik.touched.city_id && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.city_id}
                      </p>
                    )}

                    <Form.Group controlId="phone_number">
                      <Form.Control
                        name="phone_number"
                        type="text"
                        placeholder="Telp/Fax/Whatsapp"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.phone_number &&
                          formik.touched.phone_number &&
                          "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.phone_number &&
                      formik.touched.phone_number && (
                        <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                          {formik.errors.phone_number}
                        </p>
                      )}

                    <Form.Group controlId="person_in_charge">
                      <Form.Control
                        name="person_in_charge"
                        type="text"
                        placeholder="Nama contact person"
                        value={formik.values.person_in_charge}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.person_in_charge &&
                          formik.touched.person_in_charge &&
                          "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.person_in_charge &&
                      formik.touched.person_in_charge && (
                        <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                          {formik.errors.person_in_charge}
                        </p>
                      )}

                    <Form.Group controlId="pic_phone_number">
                      <Form.Control
                        name="pic_phone_number"
                        type="text"
                        placeholder="No. Hp/Whatsapp"
                        value={formik.values.pic_phone_number}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.pic_phone_number &&
                          formik.touched.pic_phone_number &&
                          "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.pic_phone_number &&
                      formik.touched.pic_phone_number && (
                        <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                          {formik.errors.pic_phone_number}
                        </p>
                      )}

                    <Form.Group controlId="position">
                      <Form.Control
                        name="position"
                        type="text"
                        placeholder="Jabatan"
                        value={formik.values.position}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.position &&
                          formik.touched.position &&
                          "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.position && formik.touched.position && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.position}
                      </p>
                    )}
                    {/* <Button block="true" type="submit" onClick={onSetTimeline}> */}
                    <Button
                      block="true"
                      type="submit"
                      className="btn-bg-primary"
                    >
                      Selanjutnya
                    </Button>
                    <Row>
                      <Col
                        xs="12"
                        className="d-flex justify-content-center mt-3 mb-3"
                      >
                        <div
                          className={
                            timeline === 1
                              ? "ball mr-1 bg-primary"
                              : "ball mr-1 bg-secondary"
                          }
                        >
                          {timeline === 1 ? 1 : null}
                        </div>

                        <div
                          className={
                            timeline === 2
                              ? "ball mr-1 bg-primary"
                              : "ball mr-1 bg-secondary"
                          }
                        >
                          {timeline === 2 ? 2 : null}
                        </div>
                      </Col>
                    </Row>
                    {(formik.touched.company_name &&
                      formik.errors.company_name) ||
                    (formik.touched.email && formik.errors.email) ||
                    (formik.touched.password && formik.errors.password) ||
                    (formik.touched.address && formik.errors.address) ||
                    (formik.touched.phone_number &&
                      formik.errors.phone_number) ||
                    (formik.touched.person_in_charge &&
                      formik.errors.person_in_charge) ||
                    (formik.touched.pic_phone_number &&
                      formik.errors.pic_phone_number) ||
                    (formik.touched.city_id && formik.errors.city_id) ||
                    (formik.touched.position && formik.errors.position) ? (
                      <Row>
                        <Col xs="12 pb-3">
                          <Button
                            disabled={true}
                            block="true"
                            className="btn btn-err"
                          >
                            Lengkapi data yang kurang
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
                <Row className={timeline === 2 ? "d-block" : "d-none"}>
                  <Col xs="12">
                    <Image
                      src={backArrow}
                      alt="logo"
                      className="mt-3"
                      onClick={() => setTimeline(1)}
                    />
                    <h4 className="heading">Daftar</h4>
                    <p className="mt-4">Profil Pelaku Usaha Ekspor</p>
                    <div className="form-group">
                      <div className="custom custom-file">
                        <input
                          id="ktp_photo"
                          type="file"
                          className="custom-file-input"
                          onChange={(event) => handleKtp(event)}
                        />
                        <label
                          htmlFor="ktp_photo"
                          className={
                            formik.errors.ktp_photo && formik.touched.ktp_photo
                              ? "custom-file-label form-err"
                              : "custom-file-label"
                          }
                        >
                          {formik.values.ktp_photo
                            ? formik.values.ktp_photo.name
                            : "Foto KTP pemilik perusahaan"}
                        </label>
                      </div>
                    </div>
                    {formik.errors.ktp_photo && formik.touched.ktp_photo && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.ktp_photo}
                      </p>
                    )}

                    <Form.Group controlId="nik">
                      <Form.Control
                        name="nik"
                        type="text"
                        placeholder="No. KTP Pemilik Perusahaan"
                        value={formik.values.nik}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.nik && formik.touched.nik && "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.nik && formik.touched.nik && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.nik}
                      </p>
                    )}

                    <div className="form-group">
                      <div className="custom custom-file">
                        <input
                          id="npwp_photo"
                          type="file"
                          className="custom-file-input"
                          onChange={(event) => handleNpwp(event)}
                        />
                        <label
                          htmlFor="npwp_photo"
                          className={
                            formik.errors.npwp_photo &&
                            formik.touched.npwp_photo
                              ? "custom-file-label form-err"
                              : "custom-file-label"
                          }
                        >
                          {formik.values.npwp_photo
                            ? formik.values.npwp_photo.name
                            : "Foto NPWP"}
                        </label>
                      </div>
                    </div>
                    {formik.errors.npwp_photo && formik.touched.npwp_photo && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.npwp_photo}
                      </p>
                    )}

                    <Form.Group controlId="npwp">
                      <Form.Control
                        name="npwp"
                        type="text"
                        placeholder="No. NPWP"
                        value={formik.values.npwp}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.npwp &&
                          formik.touched.npwp &&
                          "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.npwp && formik.touched.npwp && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.npwp}
                      </p>
                    )}

                    <div className="form-group">
                      <div className="custom custom-file">
                        <input
                          id="siup_photo"
                          type="file"
                          className="custom-file-input"
                          onChange={(event) => handleSiup(event)}
                        />
                        <label
                          htmlFor="siup_photo"
                          className={
                            formik.errors.siup_photo &&
                            formik.touched.siup_photo
                              ? "custom-file-label form-err"
                              : "custom-file-label"
                          }
                        >
                          {formik.values.siup_photo
                            ? formik.values.siup_photo.name
                            : "SIUP"}
                        </label>
                      </div>
                    </div>
                    {formik.errors.siup_photo && formik.touched.siup_photo && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.siup_photo}
                      </p>
                    )}

                    <div className="form-group">
                      <div className="custom custom-file">
                        <input
                          id="tdp_photo"
                          type="file"
                          className="custom-file-input"
                          onChange={(event) => handleTdp(event)}
                        />
                        <label
                          htmlFor="tdp_photo"
                          className={
                            formik.errors.tdp_photo && formik.touched.tdp_photo
                              ? "custom-file-label form-err"
                              : "custom-file-label"
                          }
                        >
                          {formik.values.tdp_photo
                            ? formik.values.tdp_photo.name
                            : "TDP"}
                        </label>
                      </div>
                    </div>
                    {formik.errors.tdp_photo && formik.touched.tdp_photo && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.tdp_photo}
                      </p>
                    )}

                    <div className="form-group">
                      <div className="custom custom-file">
                        <input
                          id="nib_photo"
                          type="file"
                          name="nib_photo"
                          className="custom-file-input"
                          onChange={(event) => handleNib(event)}
                        />
                        <label
                          htmlFor="custom-file"
                          className={
                            formik.errors.nib_photo && formik.touched.nib_photo
                              ? "custom-file-label form-err"
                              : "custom-file-label"
                          }
                        >
                          {formik.values.nib_photo
                            ? formik.values.nib_photo.name
                            : "NIB"}
                        </label>
                      </div>
                    </div>
                    {formik.errors.nib_photo && formik.touched.nib_photo && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.nib_photo}
                      </p>
                    )}

                    <Form.Group controlId="nib">
                      <Form.Control
                        name="nib"
                        type="text"
                        placeholder="NIB"
                        value={formik.values.nib}
                        onChange={formik.handleChange}
                        className={
                          formik.errors.nib && formik.touched.nib && "form-err"
                        }
                      />
                    </Form.Group>
                    {formik.errors.nib && formik.touched.nib && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.nib}
                      </p>
                    )}

                    <Button
                      block="true"
                      type="submit"
                      className="btn-bg-primary"
                    >
                      {loadSubmit ? (
                        <Spinner animation="grow" width={"auto"} />
                      ) : (
                        "Daftar"
                      )}
                    </Button>
                    <Row>
                      <Col
                        xs="12"
                        className="d-flex justify-content-center mt-3 mb-3"
                      >
                        <div
                          className={
                            timeline === 1
                              ? "ball mr-1 bg-primary"
                              : "ball mr-1 bg-secondary"
                          }
                        >
                          {timeline === 1 ? 1 : null}
                        </div>

                        <div
                          className={
                            timeline === 2
                              ? "ball mr-1 bg-primary"
                              : "ball mr-1 bg-secondary"
                          }
                        >
                          {timeline === 2 ? 2 : null}
                        </div>
                      </Col>
                    </Row>
                    {(formik.touched.ktp_photo && formik.errors.ktp_photo) ||
                    (formik.touched.nik && formik.errors.nik) ||
                    (formik.touched.npwp_photo && formik.errors.npwp_photo) ||
                    (formik.touched.npwp && formik.errors.npwp) ||
                    (formik.touched.siup_photo && formik.errors.siup_photo) ||
                    (formik.touched.tdp_photo && formik.errors.tdp_photo) ||
                    (formik.touched.nib && formik.errors.nib) ||
                    (formik.touched.nib_photo && formik.errors.nib_photo) ? (
                      <Row>
                        <Col xs="12 pb-3">
                          <Button
                            disabled={true}
                            block="true"
                            className="btn btn-err"
                          >
                            Lengkapi data yang kurang
                          </Button>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Index;
