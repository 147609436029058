import React from "react";
import Navbar from "../../container/navbar";
import { Row, Col, Container, Badge, Spinner } from "react-bootstrap";
import "../../../styles/index.css";
// import "./index.css";
import CardItem from "../../../components/pphnak/card-profile-eksportir";
import { useHistory } from "react-router-dom";
import { FormControl, InputGroup, Button } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";

const Index = () => {
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState(0);
  const [reRender, setReRender] = React.useState(false);

  const [searchCompany, setSearchCompany] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const filterByStatus = [
    "All",
    "Baru",
    "Di Proses",
    "Di Kembalikan",
    "Update",
    "Selesai",
    "Menunggu",
  ];
  const searchSubmission = () => {
    setReRender(!reRender);
  };

  const getData = async () => {
    setLoading(true);
    await req
      .get({
        endpoint: `exporter`,
        params: {
          qword: searchCompany,
          perPage: 9999999,
        },
      })
      .then((res) => {
        if (res) {
          res.status === "success"
            ? setData(res.data.data)
            : sweetalert.error(`${res.message}`);
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    getData();
  }, [filter, reRender]);

  return (
    <>
      <Container fluid className="grid-root">
        <Row style={{ height: "100vh" }}>
          <Col xs="12">
            <Navbar />

            <Row style={{ marginTop: "100px" }}>
              <Col
                xs="12"
                // className="d-flex text-center justify-content-around"
              >
                {/* <input type="text"></input> */}
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Cari nama perusahaan"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onChange={(e) => setSearchCompany(e.target.value)}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={searchSubmission}
                    >
                      <Search />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>
            {loading ? (
              <Row>
                <Col className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : Object.keys(data).length < 1 ? (
              <Row>
                <Col xs="12" className="text-center">
                  <p>- Tidak ada data -</p>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs="12">
                  {data
                    ? Object.keys(data).map((keys) => (
                        <CardItem data={data[keys]} key={keys} />
                      ))
                    : null}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
