import React, { useState } from "react";
import "./index.css";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Container,
  Spinner,
} from "react-bootstrap";
import Navbar from "../navbar";
import { Eye, EyeSlash, CardList, Key, KeyFill } from "react-bootstrap-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/index.css";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { url } from "../../../helpers/env";
import req from "../../../helpers/axios";

const Index = () => {
  const history = useHistory();

  const [showpw1, setShow1] = useState(false);
  const [showpw2, setShow2] = useState(false);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      token: "",
      no_npwp: "",
      newpassword: "",
      repassword: "",
    },
    validationSchema: Yup.object({
      token: Yup.string().required("Harus diisi!"),
      no_npwp: Yup.string().required("Harus diisi!"),
      newpassword: Yup.string().required("Password Harus diisi"),
      repassword: Yup.string()
        .oneOf([Yup.ref("newpassword"), null], "Password tidak sama")
        .required("Password harus diisi"),
    }),
    onSubmit: (values) => {
      const params = {
        npwp: values.no_npwp,
        token: values.token,
        password: values.newpassword,
        confirmed: "true",
        type: "password",
      };

      setLoading(true);
      req
        .post({
          endpoint: "new_password",
          data: params,
        })
        .then((res) => {
          setLoading(false);
          if (res) {
            if (res.status === "error") {
              Swal.fire({
                text: `${res.message}`,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              });
            } else if (res.status === "success") {
              localStorage.removeItem("np");
              Swal.fire({
                text: `${res.message}`,
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              }).then((response) => {
                history.push("/login");
              });
            }
          }
        });
    },
  });

  const showPassword1 = () => {
    setShow1(!showpw1);
  };
  const showPassword2 = () => {
    setShow2(!showpw2);
  };

  React.useEffect(() => {}, []);
  return (
    <>
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  <Row style={{ marginTop: "70px" }}>
                    <Col xs="12">
                      <h4 className="heading">Buat password baru</h4>
                      <p className="mt-4">
                        Password baru anda harus berbeda dengan password
                        sebelumnya.
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="12">
                      <Row>
                        <Col xs="12">
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="token">
                                <KeyFill />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              name="token"
                              type="text"
                              placeholder="Kode dari email"
                              value={formik.values.token}
                              onChange={formik.handleChange}
                            />
                          </InputGroup>

                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="no_npwp">
                                <CardList />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              name="no_npwp"
                              type="text"
                              placeholder="No. NPWP / Username"
                              value={formik.values.no_npwp}
                              onChange={formik.handleChange}
                            />
                          </InputGroup>

                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="newpassword">
                                <Key />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              name="newpassword"
                              type={showpw1 ? "text" : "password"}
                              placeholder="Password Baru"
                              value={formik.values.newpassword}
                              onChange={formik.handleChange}
                            />
                            <InputGroup.Prepend>
                              <InputGroup.Text id="newpassword">
                                {showpw1 ? (
                                  <Eye onClick={showPassword1} />
                                ) : (
                                  <EyeSlash onClick={showPassword1} />
                                )}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                          </InputGroup>

                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="repassword">
                                <Key />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              name="repassword"
                              type={showpw2 ? "text" : "password"}
                              placeholder="Ketik ulang password baru"
                              value={formik.values.repassword}
                              onChange={formik.handleChange}
                            />
                            <InputGroup.Prepend>
                              <InputGroup.Text id="repassword">
                                {showpw2 ? (
                                  <Eye onClick={showPassword2} />
                                ) : (
                                  <EyeSlash onClick={showPassword2} />
                                )}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12 pb-3">
                  <Button block="true" type="submit">
                    {loading ? (
                      <Spinner animation="grow" width={"auto"} />
                    ) : (
                      "Reset password"
                    )}
                  </Button>

                  {(formik.errors.newpassword && formik.touched.newpassword) ||
                  (formik.errors.repassword && formik.touched.repassword) ? (
                    <Button
                      disabled={true}
                      block="true"
                      className="btn btn-err"
                    >
                      Password tidak sama
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
