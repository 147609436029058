import React from "react";
import {
  Modal,
  Button,
  InputGroup,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import req from "../../helpers/axios";

const Changepassword = (props) => {
  const history = useHistory();
  const [showpw0, setShow0] = React.useState(false);
  const [showpw1, setShow1] = React.useState(false);
  const [showpw2, setShow2] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      email: localStorage.getItem("email"),
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Harus diisi!"),
    }),
    onSubmit: (values) => {
      const params = {
        email: values.email,
      };
      setLoading(true);
      req
        .post({
          // endpoint belum ada
          endpoint: `change_email`,
          data: params,
        })
        .then((res) => {
          if (res) {
            if (res.status === "error") {
              Swal.fire({
                text: `${res.message.email[0]}`,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              });
            } else if (res.status === "success") {
              localStorage.setItem("email", params.email);
              Swal.fire({
                text: `${res.message}`,
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              }).then((response) => {
                history.go(0);
              });
            }
            setLoading(false);
          }
        });
    },
  });

  return (
    <div>
      <Modal
        show={props.data.showNotif}
        onHide={props.data.closeModalNotif}
        style={{ overflow: "auto" }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header className="justify-content-center" closeButton>
            <Modal.Title>Ganti Email Notifikasi</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="email">
              <Form.Control
                name="email"
                type="text"
                placeholder="Email Notification"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={
                  formik.errors.email && formik.touched.email && "form-err"
                }
              />
            </Form.Group>
            {formik.errors.email && formik.touched.email && (
              <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                {formik.errors.email}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <Row className="text-center">
                <Col>
                  <Spinner animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : (
              <Button block="true" type="submit" className="btn-bg-primary">
                Submit
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Changepassword;
