import React, { useState, useEffect } from "react";
import { loadModules } from "esri-loader";
import tmpJson from "../../../json/latlong.json";
const BermudaTriangle = (props) => {
  const { datas, reRender, setReRender } = props;

  const [graphic, setGraphic] = useState(null);
  Number.prototype.format = function (n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
  };
  useEffect(() => {
    if (reRender === true) {
      props.view.graphics.removeAll();
      loadModules([
        "esri/Graphic",
        "esri/geometry/Point",
        "esri/geometry/Multipoint",
        "esri/widgets/Popup",
        "esri/PopupTemplate",
      ])
        .then(([Graphic, Multipoint, PopupTemplate]) => {
          setReRender(false);

          datas.data_potensi.length > 0 &&
            datas.data_potensi.map((item) => {
              let table = `
              <div id='a'><div>
              
              <div class="row justify-content-around">
                              <div class='col-6 text-center'>Komoditas</div>
                              <div class='col-3 text-center'>Volume (Kg)</div>
                              <div class='col-3 text-center'>Nilai (USD)</div>
                            </div>`;

              let get_unique = [
                ...new Set(item.data.map((uniqData) => uniqData.hs9code)),
              ];

              let x = {};

              get_unique.map((unique) => {
                item.data.map((aData) => {
                  if (aData.hs9code == unique) {
                    aData.vol = aData.vol ? parseFloat(aData.vol) : 0;
                    aData.nilai = aData.nilai ? parseFloat(aData.nilai) : 0;

                    if (x[aData.hs9code]) {
                      x[aData.hs9code].vol =
                        parseFloat(x[aData.hs9code].vol) +
                        parseFloat(aData.vol);
                      x[aData.hs9code].nilai =
                        parseFloat(x[aData.hs9code].nilai) +
                        parseFloat(aData.nilai);
                    } else {
                      x[aData.hs9code] = aData;
                    }
                  }
                });
                let volume = x[unique].vol;
                let nilai = x[unique].nilai;

                table += `<div class="row justify-content-around mb-3">
                    <div class='col-6'>${
                      `${x[unique].hsdes}` || "--"
                      // `${x[unique].hs9code}  -  ${x[unique].hsdes}` || "--"
                    }</div>
                    <div class='col-3 text-center'>${`${volume.format(
                      2,
                      3
                    )}`}</div>
                    <div class='col-3 text-center'>${`${nilai.format(
                      2,
                      3
                    )}`}</div>
                  </div>`;
              });
              const popupTemplate = {
                title: "{name}",
                content: "{data}",
              };
              setGraphic(null);

              const polygon = {
                type: "polygon",
                rings: item.province.coordinate,
              };

              const simpleFillSymbol = {
                type: "simple-fill",
                color: [227, 139, 79, 0.9],
                outline: {
                  color: [255, 255, 255],
                  width: 1,
                },
              };

              const attributes = {
                name: item.province.name,
                data: table,
              };
              const graphic = new Graphic({
                geometry: polygon,
                symbol: simpleFillSymbol,
                attributes: attributes,
                popupTemplate: popupTemplate,
              });
              setGraphic(graphic);
              props.view.graphics.add(graphic);
            });
        })
        .catch((err) => `Error ${err}`);

      return function cleanup() {
        props.view.graphics.remove(graphic);
      };
    }
  }, [datas, reRender]);

  return null;
};

export default BermudaTriangle;
