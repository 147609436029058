import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../styles/index.css";
import "./index.css";
import { useHistory } from "react-router-dom";
import moment from "moment";

const CardItem = (props) => {
  const history = useHistory();
  const data = props.data;

  const { latest_status } = data;

  const baru = (
    <button className={"button-proses bg-color-baru fsize10"}>Baru</button>
  );
  const menunggu = (
    <button className={"button-proses bg-color-menunggu fsize10"}>
      Menunggu
    </button>
  );
  const diproses = (
    <button className={"button-proses bg-color-diproses fsize10"}>
      Di Proses
    </button>
  );
  const dikembalikan = (
    <button className={"button-proses bg-color-dikembalikan fsize10"}>
      Di Kembalikan
    </button>
  );
  const update = (
    <button className={"button-proses bg-color-update fsize10"}>Update</button>
  );
  const selesai = (
    <button className={"button-proses bg-color-selesai fsize10"}>
      Selesai
    </button>
  );

  const forSwitch = () => {
    switch (latest_status) {
      case "0":
        return baru;
      case "1":
        return dikembalikan;
      case "2":
        return update;
      case "3":
        return menunggu;
      case "4":
        return diproses;
      case "5":
        return selesai;
      default:
        break;
    }
  };

  return (
    <>
      <Card className="shadow mb-3">
        <Card.Body className="card-box">
          <Row className="mb-2">
            <Col xs="12" className="d-flex justify-content-between">
              <div>
                <p>No. Pengajuan</p>
                <p className="fsize16 font-weight-bold mb-2">
                  {data.submission_number}
                </p>
                <p className="fsize21 font-weight-bold">
                  {data.commodity.description}
                </p>
              </div>
              <div style={{ position: "absolute", right: `15px` }}>
                {forSwitch()}
                <p className="fsize8">Update Terakhir</p>
                <p className="fsize8 font-weight-bold">
                  {moment(data.updated_at)
                    .locale("id")
                    .format("dddd, MMMM YYYY")}
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="d-flex">
              <div className="mr-4">
                <p>Tanggal Pengajuan</p>
                <p className="font-weight-bold">
                  {moment(data.created_at).format("dddd")}
                </p>
                <p className="font-weight-bold">
                  {moment(data.created_at).format("DD MMMM YYYY")}
                </p>
              </div>
              <div>
                <p>Negara Tujuan</p>
                <p className="font-weight-bold">{data.country.name}</p>
              </div>
              <div>
                <h6
                  className="font-weight-bold"
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    right: "15px",
                    color: "#63ACEF",
                  }}
                  onClick={() => history.push(`/permohonan/detail/${data.id}`)}
                >
                  Detail
                </h6>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CardItem;
