import React from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import "./index.css";
import Header from "../header";
import Navbar from "../../navbar";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useHistory } from "react-router-dom";
import req from "../../../../helpers/axios";
import sweetalert from "../../../../helpers/sweetalert";
import Select from "react-select";

const Index = (props) => {
  const history = useHistory();
  const [country, setCountry] = React.useState([]);
  const [komoditas, setKomoditas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const id_exportir = localStorage.getItem("id_exportir");
  const initialValues = {
    tahun: moment().format("YYYY"),
    jeniskomoditas: "",
    nilai: "",
    volume: "",
    negaratujuan: "",
  };

  const validationSchema = Yup.object({
    tahun: Yup.number()
      .min(2000, "Tahun tidak valid")
      .max(9999, "Tahun tidak valid")
      .required("Harus diisi")
      .typeError("Masukan nomor"),
    jeniskomoditas: Yup.string().required("Harus diisi!"),
    nilai: Yup.number().required("Harus diisi!").typeError("Masukan nomor"),
    volume: Yup.number().required("Harus diisi!").typeError("Masukan nomor"),
    negaratujuan: Yup.string().required("Harus diisi!"),
  });

  const formik = useFormik({
    validateOnChange: false,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      const tmpData = {
        year: values.tahun,
        commodity_id: values.jeniskomoditas,
        value: values.nilai,
        volume: values.volume,
        destination_country_id: values.negaratujuan,
      };
      setLoading(true);
      req
        .post({
          endpoint: `export-plant/${id_exportir}/add`,
          data: tmpData,
        })
        .then((res) => {
          if (res) {
            if (res.status === "success") {
              sweetalert
                .success(
                  `Rencana Pasar Ekspor anda akan ditinjau kembali oleh PPHNAK, Terimakasih.`
                )
                .then((response) => {
                  history.push("/rencana-ekspor");
                });
            } else {
              sweetalert.error(`${res.message}`);
            }
            setLoading(false);
          }
        });
    },
  });

  const getCountry = async () => {
    await req
      .get({
        endpoint: "countries",
        params: {
          perPage: 999,
        },
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            setCountry(res.data.data);
          }
        }
      });
  };

  const getData = async () => {
    await req
      .get({
        endpoint: `export-plant/${id_exportir}`,
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            const tmpKomoditas = res.data.commodities;
            const dataOptions = tmpKomoditas.map((e) => ({
              value: e.id,
              label: `${e.hs_code} - ${e.description}`,
            }));
            setKomoditas(dataOptions);
          }
        }
      });
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      marginTop: -10,
      marginBottom: "1rem",
    }),
  };
  React.useEffect(() => {
    getData();
    getCountry();
  }, []);
  return (
    <>
      <Container fluid className="grid-root">
        <Row>
          <Col xs="12">
            <Navbar />
            <Row style={{ marginTop: "100px" }} className=" mb-3">
              <Col xs="12">
                <Header page="rencana" />
              </Col>
            </Row>
            <Form onSubmit={formik.handleSubmit}>
              <Row
                className="d-flex justify-content-around"
                style={{ height: "90vh" }}
              >
                <Col xs="12">
                  <Form.Group controlId="tahun">
                    <Form.Label>Tahun</Form.Label>
                    <Form.Control
                      className={`forForm ${
                        formik.errors.tahun &&
                        formik.touched.tahun &&
                        "form-err"
                      }`}
                      type="text"
                      name="tahun"
                      value={formik.values.tahun}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  {formik.errors.tahun && formik.touched.tahun && (
                    <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                      {formik.errors.tahun}
                    </p>
                  )}
                  <Form.Label>Jenis Komoditas</Form.Label>
                  <Select
                    styles={customStyles}
                    maxMenuHeight={200}
                    // value={formik.values.jeniskomoditas}
                    defaultValue={{ values: "", label: "Pilih Komoditas" }}
                    name="jeniskomoditas"
                    options={komoditas}
                    // onChange={formik.handleChange}
                    onChange={(option) =>
                      formik.setFieldValue("jeniskomoditas", option.value)
                    }
                  />
                  {formik.errors.jeniskomoditas &&
                    formik.touched.jeniskomoditas && (
                      <p style={{ color: "#EB5757" }}>
                        {formik.errors.jeniskomoditas}
                      </p>
                    )}
                  <Form.Group controlId="nilai">
                    <Form.Label>Nilai (US$)</Form.Label>
                    <Form.Control
                      className={`forForm ${
                        formik.errors.nilai &&
                        formik.touched.nilai &&
                        "form-err"
                      }`}
                      type="text"
                      name="nilai"
                      value={formik.values.nilai}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  {formik.errors.nilai && formik.touched.nilai && (
                    <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                      {formik.errors.nilai}
                    </p>
                  )}
                  <Form.Group controlId="volume">
                    <Form.Label>Volume</Form.Label>
                    <Form.Control
                      className={`forForm ${
                        formik.errors.volume &&
                        formik.touched.volume &&
                        "form-err"
                      }`}
                      type="text"
                      name="volume"
                      value={formik.values.volume}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  {formik.errors.volume && formik.touched.volume && (
                    <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                      {formik.errors.volume}
                    </p>
                  )}
                  <Form.Group controlId="negaratujuan">
                    <Form.Label>Negara Tujuan</Form.Label>
                    <Form.Control
                      as="select"
                      className={`forForm ${
                        formik.errors.negaratujuan &&
                        formik.touched.negaratujuan &&
                        "form-err"
                      }`}
                      name="negaratujuan"
                      value={formik.values.negaratujuan}
                      onChange={formik.handleChange}
                    >
                      <option value="">Pilih Negara</option>
                      {country.length > 0
                        ? country.map((item, index) => (
                            <option value={item.id} key={index}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </Form.Control>
                  </Form.Group>
                  {formik.errors.negaratujuan &&
                    formik.touched.negaratujuan && (
                      <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                        {formik.errors.negaratujuan}
                      </p>
                    )}
                </Col>
                <Col xs="12">
                  {loading ? (
                    <Row className="text-center">
                      <Col>
                        <Spinner animation="grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-block btn-bg-primary text-white"
                    >
                      Submit
                    </button>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
