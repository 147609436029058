import React from "react";
import "./index.css";
// import { Container } from "@material-ui/core";
import { Row, Col, Form, Button, Container, Spinner } from "react-bootstrap";
import Navbar from "../../container/navbar";
import { Eye, Trash } from "react-bootstrap-icons";
import { useHistory, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FileUpload from "../../../assets/icon/file.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/index.css";
import api from "../../../libraries/api";
import Swal from "sweetalert2";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";

const Index = () => {
  const [data, setData] = React.useState({});
  const [allData, setAllData] = React.useState([]);
  const [reRender, setReRender] = React.useState(false);

  const [idExportir, setidExportir] = React.useState();

  const [filePaths, setFilePaths] = React.useState([]);

  const [roleCheck, setRoleCheck] = React.useState({});
  const [parent, setParent] = React.useState();

  const [subdit, setSubdit] = React.useState([]);
  const [direktorat, setDirektorat] = React.useState({});
  const [documentEdit, setDocumentEdit] = React.useState({});
  const [participantsCheck, setParticipantsCheck] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const location = useLocation();
  const history = useHistory();

  const tmpState = location.state;

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      namafile: "",
      keterangan: "",
      catatan: tmpState.proses.explanation,
    },
    validationSchema: Yup.object({
      // namafile: Yup.string().required("Harus diisi!"),
      // keterangan: Yup.string().required("Harus diisi!"),
      // catatan: Yup.string().required("Harus diisi!"),
    }),
    onSubmit: (values, actions) => {
      delete values.namafile;
      const resultData = {
        notes: values.catatan,
      };

      let tmpCheck = [];

      let toPush = roleCheck;

      Object.keys(toPush).map((key) => {
        if (toPush[key] === true) {
          tmpCheck.push(key);
        }
      });
      if (tmpCheck.length > 0 || documentEdit.documents.length > 0) {
        const fd = new FormData();
        fd.append("explanation", resultData.notes);
        fd.append("role_id", tmpCheck.join(","));
        fd.append("parent", parent);
        allData.map((item, index) => {
          fd.append("file_names[" + index + "]", item.namafile);
          fd.append("document_desc[" + index + "]", item.keterangan);
          fd.append("file_paths[" + index + "]", filePaths[index]);
        });

        documentEdit.documents.map((item, index) => {
          fd.append("update_id[" + index + "]", item.id);
          fd.append("document_desc_update[" + index + "]", item.description);
        });
        setLoading(true);
        req
          .post({
            endpoint: `applicant-submission/${tmpState.id_submission}/update/${tmpState?.proses.id}`,

            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                sweetalert.success(`Edit disposisi berhasil.`).then((res) => {
                  history.push(
                    `/subdit/permohonan/detail/${tmpState.id_submission}`
                  );
                });
              } else {
                sweetalert.error(`${res.message} `);
              }
              setLoading(false);
            }
          });
      } else {
        sweetalert.info("Dokumen harus diisi");
      }
    },
  });

  const onDrag = (e) => {
    if (e) {
      if (e[0].size < 1000000) {
        setData({ file: e });

        e.map((i) => {
          // return setImageName(i.name);
          formik.setFieldValue("namafile", i.name);
        });

        const fd = new FormData();
        fd.append("file", e[0]);
        fd.append("exporter_id", idExportir);
        req
          .post({
            endpoint: `applicant-submission/${tmpState.id_submission}/add-document`,
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                const path = res.data.path || null;
                const setpath = [...filePaths];
                setpath.push(path);
                setFilePaths(setpath);
              }
            }
          });
      } else {
        sweetalert.error(`Ukuran file maksimal 1mb`);
      }
    }
  };

  const handleAdd = () => {
    if (formik.values.namafile === "" || formik.values.keterangan === "") {
      return Swal.fire({
        text: "Jika ingin menambah dokumen, Gambar dan keterangan harus diisi",
        icon: "info",
        confirmButtonText: "OK",
        confirmButtonColor: "#63ACEF",
      });
    }
    formik.setFieldValue("namafile", "");
    formik.setFieldValue("keterangan", "");
    const fd = new FormData();
    fd.append("file", data);
    let tmp = allData;
    const result = {
      ...data,
      namafile: formik.values.namafile,
      keterangan: formik.values.keterangan,
    };

    tmp.push(result);

    setAllData(tmp);
  };
  const deleteNotes = (docID) => {
    req
      .post({
        endpoint: `applicant-submission/${docID}/delete-document`,
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setReRender(!reRender);
          } else {
            sweetalert.error("internal server error");
          }
        }
      });
  };

  const deleteNotesTmp = (index) => {
    allData.splice(index, 1);
    setReRender(!reRender);
  };

  const createEdit = async () => {
    await req
      .get({
        endpoint: `applicant-submission/${tmpState.id_submission}/edit/${tmpState?.proses.id}`,
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setDocumentEdit(res.process);
            setParent(res.parent);
            setDirektorat(res.direktorats); //array
            setSubdit(res.subdits); //array
            setParticipantsCheck(res.process.all_participants);
            setidExportir(res.data.exporter_id);
          }
        }
      });
  };

  React.useEffect(() => {
    createEdit();
  }, [reRender]);
  return (
    <>
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  <Row style={{ marginTop: "100px" }}>
                    <Col xs="12">
                      <Row>
                        <Col xs="12">
                          <Dropzone
                            onDrop={(acceptedFiles) => onDrag(acceptedFiles)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section
                                className="border-upload"
                                style={{ marginBottom: "1rem" }}
                              >
                                <div
                                  {...getRootProps()}
                                  className="border-upload-fill"
                                >
                                  <input {...getInputProps()} />
                                  <div className="border-fill">
                                    <img
                                      src={FileUpload}
                                      alt="file"
                                      className="pt-2 pb-2"
                                    ></img>
                                    <div className="border-image">Upload</div>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>

                          <Form.Group controlId="namafile">
                            <Form.Label>Nama File</Form.Label>
                            <Form.Control
                              className="forForm"
                              type="text"
                              name="namafile"
                              disabled
                              value={formik.values.namafile}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                          <Form.Group controlId="keterangan">
                            <Form.Label>Ket. Dokumen</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="keterangan"
                              value={formik.values.keterangan}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                          <button
                            type="button"
                            className="button-add fsize16 txt-blue"
                            onClick={(e) => handleAdd(e)}
                          >
                            Tambah
                          </button>

                          <Row
                            style={{ marginLeft: 0, marginRight: 0 }}
                            className={
                              documentEdit?.documents?.length > 0 ||
                              allData.length > 0
                                ? "notes"
                                : null
                            }
                          >
                            <Col xs="12">
                              {documentEdit?.documents?.length > 0 &&
                                documentEdit?.documents.map((doc, docIndex) => (
                                  <Row
                                    key={docIndex}
                                    style={{
                                      position: "relative",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Col xs="6">Nama File:</Col>
                                    <Col xs="6">
                                      {doc.document.length > 10
                                        ? doc.document.substring(0, 10) + "..."
                                        : doc.document}
                                    </Col>
                                    <Col xs="6">Ket. Dokumen</Col>
                                    <Col xs="6">
                                      {doc.description.length > 100
                                        ? doc.description.substring(0, 100) +
                                          "..."
                                        : doc.description}
                                    </Col>
                                    <Trash
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        bottom: "5px",
                                        color: "red",
                                      }}
                                      onClick={() => deleteNotes(doc.id)}
                                    />
                                  </Row>
                                ))}
                            </Col>
                            <Col xs="12">
                              {allData.map((item, index) => (
                                <Row
                                  key={index}
                                  style={{
                                    position: "relative",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Col xs="6">Nama File:</Col>
                                  <Col xs="6">
                                    {item.namafile.length > 10
                                      ? item.namafile.substring(0, 10) + "..."
                                      : item.namafile}
                                  </Col>
                                  <Col xs="6">Ket. Dokumen</Col>
                                  {/* <Col xs="6">{item.keterangan}</Col> */}
                                  <Col xs="6">
                                    {item.keterangan.length > 100
                                      ? item.keterangan.substring(0, 100) +
                                        "..."
                                      : item.keterangan}
                                  </Col>
                                  <Trash
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      bottom: "5px",
                                      color: "red",
                                    }}
                                    onClick={() => deleteNotesTmp(index)}
                                  />
                                </Row>
                              ))}
                            </Col>
                          </Row>

                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Catatan</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="catatan"
                              onChange={formik.handleChange}
                              value={formik.values.catatan}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12 pb-3">
                  {loading ? (
                    <Row className="text-center">
                      <Col>
                        <Spinner animation="grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <Button block="true" type="submit">
                      Lanjutkan
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
