import React, { useState } from "react";
import icon from "../../../assets/icon/hamb.svg";
import "./index.css";
import Sidebar from "../sidebar/index";
import { useHistory, useLocation } from "react-router-dom";
import imageProfile from "../../../assets/icon/deptan.svg";
import { Avatar } from "@material-ui/core";
import { KeyFill, BoxArrowLeft, PersonFill } from "react-bootstrap-icons";
import ModalChangePassword from "../../modal/changepassword";
import Swal from "sweetalert2";
import { urlForImage } from "../../../helpers/env";
import { Dropdown } from "react-bootstrap";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";
import profileStatic from "../../../assets/icon/sialek.png";

const firstMenu = [
  {
    id: 1,
    url: "/permohonan",
    title: "Layanan Ekspor",
    active: false,
  },
  {
    id: 2,
    url: "/peta-potensi",
    title: "Peta Potensi Ekspor",
    active: false,
  },
  {
    id: 3,
    url: "/peta-peluang",
    title: "Peta Peluang Ekspor",
    active: false,
  },
  {
    id: 4,
    url: "/login",
    title: "Login",
    active: false,
  },
];

const menus = [
  {
    id: 1,
    url: "/permohonan",
    title: "Layanan Ekspor",
    active: false,
  },
  {
    id: 2,
    url: "/peta-potensi",
    title: "Peta Potensi Ekspor",
    active: false,
  },
  {
    id: 3,
    url: "/peta-peluang",
    title: "Peta Peluang Ekspor",
    active: false,
  },
];

const menuspphnak = [
  {
    id: 1,
    url: "/peta-potensi",
    title: "Peta Potensi Ekspor",
    active: false,
  },
  {
    id: 2,
    url: "/peta-peluang",
    title: "Peta Peluang Ekspor",
    active: false,
  },
  {
    id: 3,
    url: "/pphnak/permohonan",
    title: "Data Pemohon",
    active: false,
  },
  {
    id: 4,
    url: "/profile-eksportir",
    title: "Profile Eksportir",
    active: false,
  },
];

const menusDirektorat = [
  {
    id: 1,
    url: "/peta-potensi",
    title: "Peta Potensi Ekspor",
    active: false,
  },
  {
    id: 2,
    url: "/peta-peluang",
    title: "Peta Peluang Ekspor",
    active: false,
  },
  {
    id: 3,
    url: "/direktorat/permohonan",
    title: "Data Pemohon",
    active: false,
  },
  {
    id: 4,
    url: "/profile-eksportir",
    title: "Profile Eksportir",
    active: false,
  },
];

const menusSubdit = [
  {
    id: 1,
    url: "/peta-potensi",
    title: "Peta Potensi Ekspor",
    active: false,
  },
  {
    id: 2,
    url: "/peta-peluang",
    title: "Peta Peluang Ekspor",
    active: false,
  },
  {
    id: 3,
    url: "/subdit/permohonan",
    title: "Data Pemohon",
    active: false,
  },
  {
    id: 4,
    url: "/profile-eksportir",
    title: "Profile Eksportir",
    active: false,
  },
];

const forMenus = () => {
  const roleid = localStorage.getItem("roleid");
  const group = localStorage.getItem("group");
  if (roleid == null || undefined) {
    return firstMenu;
  } else if (roleid == 2) {
    return menus;
  } else if (roleid == 3) {
    return menuspphnak;
  } else if (group === "direktorat") {
    return menusDirektorat;
  } else if (group === "subdit") {
    return menusSubdit;
  }
};

const Navbar = (props) => {
  const [toggle, setToggle] = useState(false);
  const [listMenu, setListMenu] = useState([]);
  const [activeMenu, setActiveMenu] = useState("Layanan Ekspor");
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState({});

  const accessToken =
    localStorage.getItem("access_token") === null
      ? ""
      : localStorage.getItem("access_token");
  const roleid = localStorage.getItem("roleid");
  const history = useHistory();
  const location = useLocation();
  const closeModal = async () => {
    await setShow(false);
  };

  const handleModal = async () => {
    await setShow(true);
  };

  const handleLogout = async () => {
    await Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin akan keluar ?",
      showCancelButton: true,
      confirmButtonText: `Keluar`,
      confirmButtonColor: "#F43D3D",
    }).then((result) => {
      if (result.isConfirmed) {
        req
          .get({
            endpoint: `logout`,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                localStorage.clear();
                history.push("/login");
              }
            }
          });
      }
    });
  };

  const handleToggle = async (nSelect = null) => {
    if (nSelect) {
      let nM = listMenu ?? [];
      // eslint-disable-next-line
      await nM.map((v) => {
        if (v.id === nSelect) {
          v.active = true;
          setActiveMenu(v.title);
          history.push(v.url);
          localStorage.setItem("title", v.title);
        } else {
          v.active = false;
        }
      });

      setListMenu(nM);
    }
    setToggle(!toggle);
  };
  const linkProfile = async () => {
    await req
      .post({
        endpoint: "profile",
      })
      .then((res) => {
        if (res) {
          if ((res.status = "success")) {
            setData(res.data);
          } else {
            sweetalert.error(`internal server error`);
          }
        }
      });
  };
  let tmpLocal = localStorage;
  let tmpGroup = tmpLocal.getItem("group");
  let tmpRoleName = tmpLocal.getItem("rolename");
  let handleSettings = () => {
    if (tmpRoleName === "pphnak" && tmpGroup === "direktorat") {
      history.push("/pphnak/settings");
    }
    if (tmpRoleName === "direktorat" && tmpGroup === "direktorat") {
      history.push("/direktorat/settings");
    }
    if (tmpRoleName === "subdit" && tmpGroup === "subdit") {
      history.push("/subdit/settings");
    }
  };

  let profileUrl = localStorage.getItem("profileUrl");
  let idProfile = localStorage.getItem("idProfile");

  const reloadPage = () => {};

  React.useEffect(() => {
    if (listMenu.length === 0) {
      setListMenu(forMenus);
    }
    if (roleid == 2) {
      linkProfile();
    }
    // eslint-disable-next-line
  }, [props]);
  return (
    <>
      <ModalChangePassword data={{ show, closeModal }} />
      <div className="row align-items-center navbar-mobile ">
        <div className="col-2" onClick={() => handleToggle(null)}>
          <img src={icon} alt="icon" />
        </div>
        <div className={accessToken === "" ? "col-10" : "col-7 "}>
          <p style={{ marginBottom: 0, fontSize: "16px" }}>
            {localStorage.getItem("title") == undefined ||
            localStorage.getItem("title") == "Login"
              ? "Layanan Ekspor"
              : localStorage.getItem("title")}
          </p>
        </div>
        {accessToken !== "" && history.location.pathname !== "/login" && (
          <div className="col-3 text-right" style={{ display: "ruby" }}>
            <Dropdown>
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <Avatar
                  alt="Si Alex"
                  src={
                    roleid == 2
                      ? data.profile_picture != null
                        ? `${urlForImage}/company_profile/${data.id}/${data.profile_picture}`
                        : `${profileStatic}`
                      : imageProfile
                  }
                  style={{ borderRadius: "0px" }}
                  sizes="large"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {localStorage.getItem("roleid") == 2 && (
                  <Dropdown.Item onClick={() => history.push("/profile")}>
                    <PersonFill className="mr-2" />
                    Profile
                  </Dropdown.Item>
                )}
                {tmpGroup === "exporter" && tmpRoleName === "eksportir" && (
                  <Dropdown.Item onClick={handleModal}>
                    <KeyFill className="mr-2" />
                    Ganti Password
                  </Dropdown.Item>
                )}
                {tmpGroup !== "exporter" && tmpRoleName !== "eksportir" && (
                  <Dropdown.Item onClick={handleSettings}>
                    <KeyFill className="mr-2" />
                    Settings
                  </Dropdown.Item>
                )}

                <Dropdown.Item onClick={handleLogout}>
                  <BoxArrowLeft className="mr-2" />
                  Keluar
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        <Sidebar key={"sidebar_1"} data={{ handleToggle, toggle, listMenu }} />
      </div>
    </>
  );
};

export default Navbar;
