import React from "react";
import { Row, Col } from "react-bootstrap";
import "../../../styles/index.css";
import "./index.css";
import { useHistory, useLocation } from "react-router-dom";

const Index = (props) => {
  const history = useHistory();
  const id_exportir = localStorage.getItem("id_exportir");
  return (
    <>
      <Row
        noGutters
        className="d-flex text-center justify-content-around tab-title mb-3"
      >
        {/* <Col xs="4" className="bottom-underline-blue"> */}
        <Col
          xs="4"
          className={
            props.page === "permohonan"
              ? "bottom-underline-blue"
              : "bottom-underline-grey"
          }
          onClick={() => history.push("/permohonan", id_exportir)}
        >
          <p
            className={
              props.page === "permohonan" ? "text-color-secondary" : null
            }
          >
            Permohonan
          </p>
          <p
            className={
              props.page === "permohonan" ? "text-color-secondary" : null
            }
          >
            Percepatan Ekspor
          </p>
        </Col>

        <Col
          xs="4"
          className={
            props.page === "rencana"
              ? "bottom-underline-blue"
              : "bottom-underline-grey"
          }
          onClick={() => history.push("/rencana-ekspor", id_exportir)}
        >
          <p
            className={props.page === "rencana" ? "text-color-secondary" : null}
          >
            Rencana
          </p>
          <p
            className={props.page === "rencana" ? "text-color-secondary" : null}
          >
            Pasar Ekspor
          </p>
        </Col>
        <Col
          xs="4"
          className={
            props.page === "akses"
              ? "bottom-underline-blue"
              : "bottom-underline-grey"
          }
          onClick={() => history.push("/akses-ekspor", id_exportir)}
        >
          <p className={props.page === "akses" ? "text-color-secondary" : null}>
            Akses
          </p>
          <p className={props.page === "akses" ? "text-color-secondary" : null}>
            Pasar Ekspor
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Index;
