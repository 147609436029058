import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../styles/index.css";
import "./index.css";

const CardItem = (props) => {
  const data = props.data;

  return (
    <>
      <Card className="shadow mb-3">
        <Card.Body className="card-box">
          <Row className="mb-2">
            <Col xs="12" className="d-flex justify-content-between">
              <div>
                {/* <p>No. Pengajuan</p>
                <p className="fsize16 font-weight-bold mb-2">000001</p> */}
                <p className="fsize21 font-weight-bold">
                  {data.commodity.description}
                </p>
              </div>
              <div>
                <p>Tahun</p>
                <p className="fsize16 font-weight-bold">{data.year}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="d-flex">
              <div style={{ marginRight: "65px" }}>
                <p>HS Code</p>
                <p className="font-weight-bold">{data.commodity.hs_code}</p>

                <p>Nilai (US$)</p>
                <p className="font-weight-bold">{data.value}</p>
              </div>
              <div>
                <p>Volume</p>
                <p className="font-weight-bold">{data.volume} kg</p>
                <p>Negara Tujuan</p>
                <p className="font-weight-bold">{data.country.name}</p>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CardItem;
