import React from "react";
import Navbar from "../../container/navbar";
import { Row, Col, Container, Badge, Spinner } from "react-bootstrap";
import "../../../styles/index.css";
import "./index.css";
import CardItem from "../../../components/subdit/card-permohonan";
import { useHistory } from "react-router-dom";
import { FormControl, InputGroup, Button } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import req from "../../../helpers/axios";
const Index = () => {
  const history = useHistory();
  const [data, setData] = React.useState([]);
  const [filter, setFilter] = React.useState(0);
  const [pengajuan, setPengajuan] = React.useState("");
  const [reRender, setReRender] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const filterByStatus = [
    "All",
    "Baru",
    "Di Proses",
    "Di Kembalikan",
    "Update",
    "Selesai",
    "Menunggu",
  ];
  const getData = async () => {
    setIsLoading(true);
    await req
      .get({
        endpoint: "applicant-submission",
        params: {
          pages: 1,
          perPage: 999999,
          qword: pengajuan,
          // latest_status:
          //   filter == 0
          //     ? null
          //     : filter == 1
          //     ? 0
          //     : filter == 2
          //     ? 4
          //     : filter == 3
          //     ? 1
          //     : filter == 4
          //     ? 2
          //     : filter == 5
          //     ? 5
          //     : filter == 6 && 3,
        },
      })
      .then((res) => {
        if (res) {
          if (res.status == "success") {
            const datas = res.data.data;
            const tmpDatas = Object.keys(datas).map((keys) => {
              return datas[keys];
            });
            const result = tmpDatas.filter((tmpData) => {
              if (filter === 0) {
                return tmpDatas;
              }
              if (filter === 1) {
                return tmpData.set_status === "0";
              }
              if (filter === 2) {
                return tmpData.set_status === "4";
              }
              if (filter === 3) {
                return tmpData.set_status === "1";
              }
              if (filter === 4) {
                return tmpData.set_status === "2";
              }
              if (filter === 5) {
                return tmpData.set_status === "5";
              }
              if (filter === 6) {
                return tmpData.set_status === "3";
              }
            });

            setData(result);
          }
        }
      });
    setIsLoading(false);
  };
  const searchSubmission = () => {
    setReRender(!reRender);
  };

  React.useEffect(() => {
    getData();
  }, [filter, reRender]);

  return (
    <>
      <Container fluid className="grid-root">
        <Row style={{ height: "100vh" }}>
          <Col xs="12">
            <Navbar />

            <Row style={{ marginTop: "100px" }}>
              <Col
                xs="12"
                // className="d-flex text-center justify-content-around"
              >
                {/* <input type="text"></input> */}
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Cari No. Pengajuan"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onChange={(e) => setPengajuan(e.target.value)}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={searchSubmission}
                    >
                      <Search />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>

            <Row className="mt-2 mb-2" noGutters>
              <Col xs="12" className="filter">
                <p>Filter by Status :</p>
              </Col>
              <Col xs="12" className="d-flex justify-content-around tab-badge">
                {filterByStatus.map((item, index) => (
                  <Badge
                    className={filter === index && "badge-grey"}
                    onClick={() => setFilter(index)}
                    key={index}
                  >
                    {item}
                  </Badge>
                ))}
              </Col>
            </Row>
            {isLoading ? (
              <Row>
                <Col className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : data.length < 1 ? (
              <Row>
                <Col xs="12" className="text-center">
                  <p>- Tidak ada data -</p>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col xs="12">
                    {data.map((item, index) => (
                      <CardItem data={item} key={index} />
                    ))}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
