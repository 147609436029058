import React from "react";
import {
  Container,
  Row,
  Col,
  Collapse,
  Button,
  Form,
  Badge,
  Modal,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Navbar from "../../../container/navbar";
import FileUpload from "../../../../assets/icon/file.svg";
import {
  CaretDownFill,
  CaretUpFill,
  ClockHistory,
  Table,
} from "react-bootstrap-icons";
import Edit from "../../../../assets/icon/edit-circle.svg";
// import FileUpload from "../../../../assets/icon/file.svg";
import CardPermohonan from "../../../../components/pphnak/card-pphnak-permohonan";
import { Plus, Eye } from "react-bootstrap-icons";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import ModalImage from "../../../modal/showimage";
import Dropzone from "react-dropzone";

import { useFormik } from "formik";
import * as Yup from "yup";
import sweetalert from "../../../../helpers/sweetalert";
import req from "../../../../helpers/axios";
import { urlForImage } from "../../../../helpers/env";
import { ArrowRight } from "@material-ui/icons";
import axios from "axios";

import siAlek from "../../../../assets/icon/sialek.png";
import pertanianImg from "../../../../assets/icon/deptan.svg";

const Detail = (props) => {
  const [data, setData] = React.useState({});
  const [commodity, setCommodity] = React.useState({});
  const [eksporter, setExporter] = React.useState({});
  const [proses, setProses] = React.useState([]);
  const [document, setDocument] = React.useState([]);
  const [role, setRole] = React.useState([]);

  const [urlImage, setUrlImage] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [collapse1, setcollapse1] = React.useState(false);
  const [collapse2, setcollapse2] = React.useState(false);
  const [collapse3, setcollapse3] = React.useState(false);
  const [collapse4, setcollapse4] = React.useState(false);
  const [collapse5, setcollapse5] = React.useState(false);
  const [tab, setTab] = React.useState(1);
  const [filter, setFilter] = React.useState("all");

  const [reRender, setReRender] = React.useState(false);

  const [allData, setAllData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  let { id } = useParams();
  const history = useHistory();

  const onDrag = (e) => {
    if (e) {
      for (let index = 0; index <= e.length - 1; index++) {
        // let tmpArr = [];
        if (e[index].size < 1000000) {
          setAllData(e);
        } else {
          sweetalert.error(`Ukuran perfile maksimal 1mb`);
        }
      }
    }

    // setAllData(e);
  };
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      namafile: "",
      catatan: "",
    },
    validationSchema: Yup.object({
      // namafile: Yup.string().required("Harus diisi!"),
      // catatan: Yup.string().required("Harus diisi!"),
    }),
    onSubmit: (values, actions) => {
      if (formik.values.catatan !== "" || allData.length > 0) {
        const tmpData = {
          file: allData,
          catatan: values.catatan,
        };

        const fd = new FormData();
        fd.append("explanation", tmpData.catatan);

        allData.map((item, index) => {
          fd.append(`documents[${index}]`, item);
        });
        setLoading(true);
        req
          .post({
            endpoint: `applicant-submission/${id}/revision`,
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                sweetalert
                  .success(
                    `Permohonan Percepatan Ekspor telah dikembalikan ke-Pelaku Ekspor`
                  )
                  .then((res) => {
                    formik.setFieldValue("catatan", "");
                    setAllData([]);
                    setcollapse5(!collapse5);
                    setReRender(!reRender);
                  });
              } else {
                sweetalert.error(`${res.message}`);
              }
              setLoading(false);
            }
          });

        // applicant-submission/9/revision
      } else {
        sweetalert.info(
          "Jika akan Update Informasi setidaknya ada pesan atau file"
        );
      }
    },
  });
  // const filterByStatus = [
  //   "All",
  //   "Baru",
  //   "Di Proses",
  //   "Di Kembalikan",
  //   "Update",
  //   "Selesai",
  // ];

  const filterByStatus = {
    All: "all",
    Baru: 0,
    "Di Proses": 4,
    "Di Kembalikan": 1,
    Update: 2,
    Selesai: 5,
    Menunggu: 3,
  };

  const getDetail = async () => {
    setIsLoading(true);
    await req
      .get({
        endpoint: `applicant-submission/${id}/view`,
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setCommodity(res.data.submission.commodity);
            setData(res.data.submission);
            setExporter(res.data.submission.exporter);
            setDocument(res.data.submission.documents);
            setRole(res.data.user_roles);

            const tmpProcess = res.data.processes;

            let tmpProcess2 = [];
            tmpProcess.map((item) => {
              if (item.all_participants.length) {
                let a = [];
                item.all_participants.map((participant) => {
                  if (participant.status == filter || filter == "all") {
                    a.push(participant);
                  }
                });
                item.statusEdit = filter;
                if (a.length) {
                  item.all_participants = a;
                  tmpProcess2.push(item);
                }
              }
            });
            setProses(tmpProcess2);
            // console.log(tmpProcess2);
          }
        }
      });
    setIsLoading(false);
  };

  const baru = (
    <button className={"button-proses bg-color-baru fsize10"}>Baru</button>
  );
  const menunggu = (
    <button className={"button-proses bg-color-menunggu fsize10"}>
      Menunggu
    </button>
  );
  const diproses = (
    <button className={"button-proses bg-color-diproses fsize10"}>
      Di Proses
    </button>
  );
  const dikembalikan = (
    <button className={"button-proses bg-color-dikembalikan fsize10"}>
      Di Kembalikan
    </button>
  );
  const update = (
    <button className={"button-proses bg-color-update fsize10"}>Update</button>
  );
  const selesai = (
    <button className={"button-proses bg-color-selesai fsize10"}>
      Selesai
    </button>
  );
  const participantsButton = (param) => {
    switch (param) {
      case "0":
        return baru;
      case "1":
        return dikembalikan;
      case "2":
        return update;
      case "3":
        return menunggu;
      case "4":
        return diproses;
      case "5":
        return selesai;
      default:
        break;
    }
  };

  const forSwitch = (data) => {
    switch (data) {
      case "0":
        return baru;
      case "1":
        return dikembalikan;
      case "2":
        return update;
      case "3":
        return menunggu;
      case "4":
        return diproses;
      case "5":
        return selesai;
      default:
        break;
    }
  };

  const detailDocument = (url1, url2) => {
    setUrlImage(
      `${urlForImage}/submission-acceleration/documents/exporters/${data.exporter.id}/${url1}/attachments/supporting-documents/${url2}`
    );
    setShow(true);
  };

  const showModal = (doc, title) => {
    if (doc) {
      const tmpSplit = doc.split(".");

      const tmpIndex = tmpSplit.length > 0 ? tmpSplit.length - 1 : null;
      const tmpTypeData = tmpSplit[tmpIndex].toLowerCase();
      let forUrl = "";

      if (title === "ktp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.ktp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.ktp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "npwp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.npwp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.npwp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "siup") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.siup_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.siup_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "tdp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.tdp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.tdp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "nib") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.nib_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${data.exporter.id}/${data.exporter.nib_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }
    }
  };

  const getDocument = async (doc, id_proses) => {
    if (doc) {
      const tmpSplit = doc?.document.split(".");

      const tmpIndex = tmpSplit.length > 0 ? tmpSplit.length - 1 : null;
      const tmpTypeData = tmpSplit[tmpIndex].toLowerCase();

      if (
        tmpTypeData === "png" ||
        tmpTypeData === "jpg" ||
        tmpTypeData === "jpeg"
      ) {
        const urlImage = `${urlForImage}/submission-acceleration/documents/exporters/${data.exporter.id}/${data.id}/processes/${id_proses}/documents/${doc.document}`;
        setUrlImage(urlImage);
        setShow(true);
      } else {
        window.open(
          `${urlForImage}/submission-acceleration/documents/exporters/${data.exporter.id}/${data.id}/processes/${id_proses}/documents/${doc.document}`,
          "_blank"
        );
      }
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  const [modalProses, setModalProses] = React.useState(false);
  const [explanationModal, setExplanationModal] = React.useState("");
  const [documentModal, setDocumentModal] = React.useState([]);
  const [idProcess, setIdProcess] = React.useState();

  const handleClose = () => setModalProses(false);

  const reqProses = async () => {
    setModalProses(false);
    await req
      .get({
        // endpoint: `applicant-submission/${idProcess}/detail`,
        endpoint: `applicant-submission/${idProcess}/detail?process=ok`,
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            sweetalert
              .success("Berhasil Di proses")
              .then((res) => setReRender(!reRender));
          } else {
            sweetalert.error(`${res.message}`);
          }
        }
      });
  };

  const handleShow = async (explanation, document, id) => {
    await setExplanationModal(explanation);
    await setDocumentModal(document);
    await setIdProcess(id);
    setModalProses(true);
  };

  const confirmation = async () => {
    req
      .post({
        endpoint: `applicant-submission/${id}/finish`,
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            sweetalert
              .success(`Pengajuan percepatan berhasil diterima`)
              .then((res) => {
                setReRender(!reRender);
              });
          }
        }
      });
  };

  React.useEffect(() => {
    getDetail();
  }, [filter, reRender]);
  return (
    <>
      <ModalImage data={{ show, closeModal, urlImage }} />
      <Modal show={modalProses} onHide={handleClose} centered>
        <Modal.Body>
          <Row>
            <Col xs="12" className="mb-2 font-weight-bold">
              Ket. Dokumen
            </Col>

            <Col xs="12" className="mb-2">
              {explanationModal}
            </Col>
            {documentModal.length > 0 &&
              documentModal.map((item, index) => (
                <Col xs="12" key={`doc${index}`}>
                  {item?.document}
                </Col>
              ))}
            {/* <Col xs="12">{documentModal}</Col> */}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-block btn-bg-primary" onClick={reqProses}>
            Konfirmasi
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row style={{ height: "100vh" }}>
          <Col xs="12">
            <Navbar />
            <button
              style={{ marginTop: "100px" }}
              className="button-collapse"
              onClick={() => setcollapse1(!collapse1)}
            >
              <span className="title-collapse">
                Nomor Pengajuan - {data.submission_number}
              </span>
              <span>{collapse1 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>
            <Collapse in={collapse1}>
              <div id="collapse1" className="paragraph">
                <p>HS Code</p>
                <p className="font-weight-bold mb-2">{commodity.hs_code}</p>

                <p>Volume (kg)</p>
                <p className="font-weight-bold mb-2">{data.volume_capacity}</p>

                <p>Nilai (US$)</p>
                <p className="font-weight-bold mb-2">{data.cost_value}</p>

                <p>Tanggal Pengajuan</p>
                <p className="font-weight-bold mb-2">
                  {moment(data.created_at).format("dddd, DD MMMM YYYY")}
                </p>

                <p>Update Terakhir</p>
                <p className="font-weight-bold mb-2">
                  {moment(data.updated_at).format("dddd, DD MMMM YYYY")}
                </p>

                <p>Status</p>
                {forSwitch(data?.latest_status)}
              </div>
            </Collapse>
            {/* profil pelaku usaha ekspor */}
            <button
              className="button-collapse"
              onClick={() => setcollapse2(!collapse2)}
            >
              <span className="title-collapse">Profil Pelaku Usaha Ekspor</span>
              <span>{collapse2 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>
            <Collapse in={collapse2}>
              <div id="collapse2" className="paragraph">
                <p>Nama Perusahaan</p>
                <p className="font-weight-bold mb-2">
                  {eksporter.company_name}
                </p>

                <p>Telp/Fax/Wa</p>
                <p className="font-weight-bold mb-2">
                  {eksporter.phone_number}
                </p>

                <p>Nama Contact Person</p>
                <p className="font-weight-bold mb-2">
                  {eksporter.person_in_charge}
                </p>

                <p>Jabatan</p>
                <p className="font-weight-bold mb-2">{eksporter.position}</p>

                <p>Alamat Perusahaan</p>
                <p className="font-weight-bold mb-2">{`${eksporter?.city?.name}, ${eksporter.address}`}</p>

                <p>Email Perusahaan</p>
                <p className="font-weight-bold mb-2"> {eksporter.email}</p>

                <p>No. HP/WA</p>
                <p className="font-weight-bold mb-2">
                  {eksporter.pic_phone_number}
                </p>
              </div>
            </Collapse>
            {/* dokumen administrasi */}
            <button
              className="button-collapse"
              onClick={() => setcollapse3(!collapse3)}
            >
              <span className="title-collapse"> Dokumen Administrasi </span>
              <span>{collapse3 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>
            {/* /storage/submission-acceleration/documents/exporters/8/34/processes/89/documents/1603942886845.jpeg */}
            <Collapse in={collapse3}>
              <div id="collapse3" className="paragraph">
                <p>Foto KTP Pimpinan Perusahaan</p>
                <p
                  className="color-underline mb-2"
                  onClick={() => showModal(data.exporter.ktp_photo.path, "ktp")}
                >
                  view file
                </p>

                <p>Foto NPWP</p>
                <p
                  className="mb-2 color-underline"
                  onClick={() =>
                    showModal(data.exporter.npwp_photo.path, "npwp")
                  }
                >
                  view file
                </p>

                <p>SIUP</p>
                <p
                  className="mb-2 color-underline"
                  onClick={() =>
                    showModal(data.exporter.siup_photo.path, "siup")
                  }
                >
                  view file
                </p>

                <p>TDP</p>
                <p
                  className="mb-2 color-underline"
                  onClick={() => showModal(data.exporter.tdp_photo.path, "tdp")}
                >
                  view file
                </p>

                <p>NIB</p>
                <p
                  className="mb-2 color-underline"
                  onClick={() => showModal(data.exporter.nib_photo.path, "nib")}
                >
                  view file
                </p>
              </div>
            </Collapse>
            {/* dokumen pendukung */}
            <button
              className="button-collapse"
              onClick={() => setcollapse4(!collapse4)}
            >
              <span className="title-collapse"> Dokumen Pendukung </span>
              <span>{collapse4 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>
            <Collapse in={collapse4}>
              <div id="collapse4">
                {document.map((item, index) => (
                  <CardPermohonan
                    data={item}
                    detailDocument={detailDocument}
                    key={`document${index}`}
                  />
                ))}
              </div>
            </Collapse>
            {/* history disposisi  */}
            {/* selain dikembalikan button ditampilkan */}

            {data.latest_status !== "5" ? (
              <>
                <button
                  className="button-collapse"
                  onClick={() => setcollapse5(!collapse5)}
                >
                  <span className="title-collapse">
                    Update Informasi Status ke Eksportir
                  </span>
                  <span>{collapse5 ? <CaretUpFill /> : <CaretDownFill />}</span>
                </button>

                <Collapse in={collapse5}>
                  <div id="collapse5">
                    {data?.latest_status !== "1" ? (
                      <Form onSubmit={formik.handleSubmit}>
                        <Form.Group controlId="pesan">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Tulis pesan"
                            name="catatan"
                            value={formik.values.catatan}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>

                        <Dropzone
                          multiple={true}
                          onDrop={(acceptedFiles) => onDrag(acceptedFiles)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section
                              className="border-upload"
                              style={{ marginBottom: "1rem" }}
                            >
                              <div
                                {...getRootProps()}
                                className="border-upload-fill"
                              >
                                <input {...getInputProps()} />
                                <div className="border-fill">
                                  <img
                                    src={FileUpload}
                                    alt="file"
                                    className="pt-2 pb-2"
                                  ></img>
                                  <div className="border-image">Upload</div>
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>

                        {loading ? (
                          <Row className="text-center">
                            <Col>
                              <Spinner animation="grow" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </Col>
                          </Row>
                        ) : (
                          <button className="btn-bg-primary mb-5 border-0 w-100">
                            Kirim
                          </button>
                        )}
                      </Form>
                    ) : (
                      proses.length > 0 && (
                        <Row>
                          <Col>
                            <p className="fsize12">{proses[0].explanation}</p>
                            {proses[0].documents.length > 0 &&
                              proses[0].documents.map((m, inx) => (
                                <p
                                  className="txt-blue mb-2 fsize12"
                                  key={`vv${inx}`}
                                  onClick={() => getDocument(m, proses[0].id)}
                                >
                                  {m.document}
                                  <Eye className="ml-2" color="#63acef" />
                                </p>
                              ))}
                          </Col>
                        </Row>
                      )
                    )}
                  </div>
                </Collapse>
              </>
            ) : (
              ""
            )}

            <Row
              className="d-flex text-center align-items-center"
              style={{ borderBottom: "1px solid #C4C4C4" }}
            >
              <Col
                xs="6"
                className={
                  tab === 0
                    ? "tab-left"
                    : "h43 d-flex align-items-center justify-content-center"
                }
                onClick={() => setTab(0)}
              >
                <span
                  className={
                    tab === 0 ? "fsize14 text-white" : "fsize14 txt-blue"
                  }
                >
                  <ClockHistory fill={tab === 0 ? "#ffffff" : "#63ACEF"} /> Cek
                  Histori Disposisi
                </span>
              </Col>
              <Col
                xs="6"
                className={
                  tab === 1
                    ? "tab-right"
                    : "h43 d-flex align-items-center justify-content-center"
                }
                onClick={() => setTab(1)}
              >
                <span
                  className={
                    tab === 1 ? "fsize14 text-white" : "fsize14 txt-blue"
                  }
                >
                  <Table fill={tab === 1 ? "#ffffff" : "#63ACEF"} /> List
                  Disposisi
                </span>
              </Col>
            </Row>
            {tab === 0 ? (
              <Timeline align="left" className="root-title">
                {proses.length > 0
                  ? proses.map((item, index) => (
                      <div key={`process${index}`}>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Row noGutters className="title-timeline">
                              <Col xs="12">
                                <p>{moment(item.updated_at).format("dddd")},</p>
                                <p>
                                  {moment(item.updated_at).format(
                                    "DD MMMM YYYY"
                                  )}
                                </p>
                              </Col>
                              <Col
                                xs="12"
                                className="d-flex align-items-center"
                              >
                                <div className="circle mr-2">
                                  <img
                                    src={
                                      item.from_role_id === 2
                                        ? eksporter?.profile_picture !== null
                                          ? `${urlForImage}/company_profile/${eksporter.id}/${eksporter.profile_picture}`
                                          : siAlek
                                        : pertanianImg
                                    }
                                    alt=""
                                    className="circle-fit"
                                  ></img>
                                </div>
                                <span>
                                  {role.map((v) => {
                                    if (v.id === item.from_role_id) {
                                      return v.name;
                                    }
                                  })}
                                </span>
                              </Col>
                            </Row>
                          </TimelineContent>
                        </TimelineItem>
                        {item.all_participants.map((itm, idx) => (
                          <div key={`k${idx}`}>
                            <TimelineItem>
                              <TimelineSeparator className="child-title">
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent className="child-size">
                                <Row className="d-flex justify-content-around align-items-center">
                                  <Col xs="8">
                                    <p className="font-weight-bold">
                                      {" "}
                                      {role.map((v) => {
                                        if (v.id === itm.role_id) {
                                          return v.name;
                                        }
                                      })}
                                    </p>
                                  </Col>
                                  <Col xs="4" className="text-right">
                                    {participantsButton(itm.status)}
                                  </Col>
                                </Row>
                              </TimelineContent>
                            </TimelineItem>
                          </div>
                        ))}
                        <Row className="fsize12">
                          <Col xs="10">
                            <p>
                              Di proses oleh:
                              {role.map((v) => {
                                if (v.id === item.from_role_id) {
                                  return v.name;
                                }
                              })}
                            </p>
                            <p>{item.explanation}</p>
                          </Col>
                        </Row>
                        {item.documents.map((val, i) => (
                          <Row>
                            <Col xs="12">
                              <p
                                className="txt-blue txt-underline-blue fsize12"
                                key={`documnt${i}`}
                                onClick={() => getDocument(val, item.id)}
                              >
                                {val.document}
                              </p>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    ))
                  : null}
              </Timeline>
            ) : null}
            {tab === 1 ? (
              <Row className="mt-2 mb-2 list-disposisi" noGutters>
                <Col xs="12">
                  <Row>
                    <Col xs="12" className="filter mt-2 mb-2">
                      <p>Filter by Status :</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs="12"
                      className="d-flex justify-content-around tab-badge mb-1"
                      style={{ borderBottom: "1px solid #C4C4C4" }}
                    >
                      {Object.keys(filterByStatus).map((item) => (
                        <Badge
                          className={
                            filter === filterByStatus[item] && "badge-grey mb-2"
                          }
                          onClick={() => setFilter(filterByStatus[item])}
                          key={item}
                        >
                          {item}
                        </Badge>
                      ))}
                    </Col>
                  </Row>
                  {isLoading ? (
                    <Row>
                      <Col className="text-center">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : proses.length > 0 ? (
                    proses.map((item, index) => (
                      <div key={index}>
                        <Col xs="12" className="mb-3 mt-1 pl-0">
                          <p>
                            {moment(item.updated_at).format(
                              "dddd, DD MMMM YYYY"
                            )}
                          </p>
                        </Col>

                        <Row style={{ marginLeft: "auto" }}>
                          <Col xs="5">
                            <Row className="justify-content-center">
                              <Col xs="8" className="pl-0">
                                <p className="font-weight-bold txt-dark">
                                  {role.map((v) => {
                                    if (v.id === item.from_role_id) {
                                      return v.name;
                                    }
                                  })}
                                </p>
                              </Col>
                              <Col xs="2">
                                {/* <img src={fromto} alt="to" /> */}
                                <ArrowRight />
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="7">
                            {/* kanan */}
                            {item.all_participants.map((value, index2) => (
                              <Row key={index2}>
                                <>
                                  <Col
                                    xs="6"
                                    className="d-flex justify-content-between mb-2"
                                  >
                                    <p className="font-weight-bold txt-dark">
                                      {role.map((v) => {
                                        if (v.id === value.role_id) {
                                          return v.name;
                                        }
                                      })}
                                    </p>
                                  </Col>
                                  <Col xs="6" className="text-right">
                                    {participantsButton(value.status)}
                                  </Col>
                                </>
                              </Row>
                            ))}
                          </Col>
                        </Row>

                        <Col xs="12" className="pl-0">
                          <p className="text-dark font-weight-bold">
                            {role.map((v) => {
                              if (v.id == item.from_role_id) {
                                return v.name;
                              }
                            })}
                          </p>
                        </Col>
                        <Col xs="12" className="mb-3 pl-0">
                          <p className="text-dark">{item.explanation}</p>
                        </Col>
                        <Col
                          xs="12"
                          style={{
                            borderBottom: "3px solid #C4C4C4",
                            position: "relative",
                          }}
                          className="file pl-0"
                        >
                          {item.documents.map((m, inx) => (
                            <p
                              className="txt-blue mb-2 "
                              key={`vv${inx}`}
                              onClick={() => getDocument(m, item.id)}
                            >
                              {m.document}
                              <Eye className="ml-2" color="#63acef" />
                            </p>
                          ))}

                          {(item.all_participants[0]?.status == 3 ||
                            item.all_participants[0]?.status == 2) &&
                          item.all_participants[0]?.role_id == 3 ? (
                            <span
                              className="proses-disposisi"
                              onClick={() =>
                                handleShow(
                                  item.explanation,
                                  item.documents,
                                  // item.id
                                  item.all_participants[0]?.id
                                )
                              }
                            >
                              Proses
                            </span>
                          ) : null}

                          {(item.statusEdit == "all" || item.statusEdit == 3) &&
                          item.status == 3 &&
                          item.from_role_id ==
                            localStorage.getItem("roleid") ? (
                            <img
                              src={Edit}
                              alt=""
                              className="edit-listdisposisi"
                              onClick={() =>
                                history.push({
                                  pathname: "/pphnak/permohonan/disposisi/edit",
                                  state: {
                                    proses: item,
                                    id_submission: item.submission_id,
                                  },
                                })
                              }
                            ></img>
                          ) : null}
                        </Col>
                      </div>
                    ))
                  ) : (
                    <Row>
                      <Col className={`text-center`}>
                        <p>- Tidak ada data -</p>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col xs="12" className="button-bottom">
            {proses.length > 0 &&
            proses[proses.length - 1].all_participants[0].status === "5" &&
            data.latest_status !== "5" ? (
              <button
                className="btn-bg-primary mb-3 border-0"
                onClick={() =>
                  history.push({
                    pathname: "/pphnak/permohonan/disposisi",
                    state: id,
                  })
                }
              >
                <Plus className="mr-2" color="white" size="30" />
                Disposisi Baru
              </button>
            ) : data.latest_status === "5" ? (
              ""
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Untuk membuat disposisi baru, harap memproses terlebih
                    dahulu permohonan dari eksportir.
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <Button
                    disabled
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#b7b7b7",
                      color: "#828282",
                    }}
                    className="mb-2 border-0 w-100"
                  >
                    <Plus className="mr-2" color="#828282" size="30" />
                    Disposisi Baru
                  </Button>
                </span>
              </OverlayTrigger>
            )}

            {proses.length > 0 &&
            proses[proses.length - 1].all_participants[0].status === "5" &&
            data.latest_status !== "5" ? (
              <button
                className="btn-bg-green mb-2 border-0"
                onClick={confirmation}
              >
                Konfirmasi Permohonan Percepatan Ekspor
              </button>
            ) : data.latest_status === "5" ? (
              ""
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    Harap memproses terlebih dahulu permohonan dari eksportir.
                  </Tooltip>
                }
              >
                <span className="d-inline-block">
                  <button
                    disabled
                    style={{ pointerEvents: "none" }}
                    className="btn-bg-green mb-2 border-0 w-100"
                    onClick={confirmation}
                  >
                    Konfirmasi Permohonan Percepatan Ekspor
                  </button>
                </span>
              </OverlayTrigger>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Detail;
