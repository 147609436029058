import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./shared/configure-store";
// import Dashboard from "./pages/container/routes";
import Login from "./pages/container/login/index";
import KonfirmasiRegister from "./pages/container/konfirmasi/index";

import NotFound from "./pages/container/not-found";
import {
  PrivateRoute,
  RedirectRoute,
  EksportirRoute,
  PphnakRoute,
  DirektoratRoute,
  SubditRoute,
} from "./libraries/route-handling"; // this line before changes

// import { RedirectRoute } from "./libraries/route-handling";
import ForgotPassword from "./pages/container/forgot-password/index";
import NewPassword from "./pages/container/new-password";
import Register from "./pages/container/register";
import RegisterSuccess from "./pages/container/register/success";
import Profile from "./pages/container/profile";
import EditDokumen from "./pages/container/editdokumen";
import EditContact from "./pages/container/edit-contact";

import Permohonan from "./pages/container/permohonan/permohonan";
import PermohonanDetail from "./pages/container/permohonan/detail/detailpermohonan";
import PermohonanAdd from "./pages/container/permohonan/add/permohonan";

import RencanaEkspor from "./pages/container/permohonan/rencana";
import RencanaEksporAdd from "./pages/container/permohonan/add/rencana";

import AksesEkspor from "./pages/container/permohonan/akses";
import AksesEksporAdd from "./pages/container/permohonan/add/akses";

// import Navbar from "./pages/container/navbar";
// import PetaPeluang from "./pages/container/global-side/map";
import PetaPotensi from "./pages/peta/petapotensi";
import PetaPeluang from "./pages/peta/petapeluang";

// <-- PPHNAK

import PphnakSettings from "./pages/pphnak/settings";
import PermohonanDisposisiPphnakEdit from "./pages/pphnak/disposisi/editdisposisi";

import ProfileEkportirPermohonanDetail from "./pages/eksportir-profiling/detail-ekportir/detail/detailpermohonan";
import ProfileEkportirPermohonan from "./pages/eksportir-profiling/detail-ekportir/permohonan";
import ProfileEkportirDetailRencana from "./pages/eksportir-profiling/detail-ekportir/rencana";
import ProfileEkportirDetailAkses from "./pages/eksportir-profiling/detail-ekportir/akses";

import ProfileEkportirDetail from "./pages/eksportir-profiling/profile-ekportir/detail-profile";
import ProfileEkportir from "./pages/eksportir-profiling/profile-ekportir";

import PermohonanPphnak from "./pages/pphnak/permohonan";
import PermohonanDisposisi from "./pages/pphnak/disposisi";
import PermohonanDetailPphnak from "./pages/pphnak/permohonan/detail/detailpermohonan";
// PPHNAK -->

// <-- DIREKTORAT
import DirektoratSettings from "./pages/direktorat/settings";
import PermohonanDisposisiDirektoratEdit from "./pages/direktorat/disposisi/editdisposisi";
import PermohonanDisposisiDirektoratProses from "./pages/direktorat/disposisi/prosesdisposisi";
import PermohonanDirektorat from "./pages/direktorat/permohonan";
import PermohonanDisposisiDirektorat from "./pages/direktorat/disposisi";
import PermohonanDetailDirektorat from "./pages/direktorat/permohonan/detail/detailpermohonan";
// DIREKTORAT -->

// <-- SUBDIT
import SubditSettings from "./pages/subdit/settings";
import PermohonanDisposisiSubditEdit from "./pages/subdit/disposisi/editdisposisi";
import PermohonanDisposisiSubditProses from "./pages/subdit/disposisi/prosesdisposisi";
import PermohonanSubdit from "./pages/subdit/permohonan";
import PermohonanDetailSubdit from "./pages/subdit/permohonan/detail/detailpermohonan";

// SUBDIT -->

const Routes = ({ store }) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/konfirmasi" component={KonfirmasiRegister} />
          {/*<PrivateRoute path="/" component={Dashboard}/>*/}
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/new-password" component={NewPassword} />
          <Route exact path="/register" component={Register} />
          <Route
            exact
            path="/verification/:token"
            component={RegisterSuccess}
          />

          <Route exact path="/peta-potensi" component={PetaPotensi} />
          <Route exact path="/peta-peluang" component={PetaPeluang} />

          <Route
            path="/profile-eksportir/permohonan/detail/:id"
            component={ProfileEkportirPermohonanDetail}
            exact
          />
          <Route
            path="/profile-eksportir/permohonan"
            component={ProfileEkportirPermohonan}
            exact
          />
          <Route
            path="/profile-eksportir/rencana-ekspor"
            component={ProfileEkportirDetailRencana}
            exact
          />
          <Route
            path="/profile-eksportir/akses-ekspor"
            component={ProfileEkportirDetailAkses}
            exact
          />
          <Route
            path="/profile-eksportir/detail/:id"
            component={ProfileEkportirDetail}
            exact
          />
          <Route path="/profile-eksportir" component={ProfileEkportir} exact />

          <EksportirRoute exact path="/profile" component={Profile} />

          <EksportirRoute exact path="/edit-document" component={EditDokumen} />
          <EksportirRoute exact path="/edit-contact" component={EditContact} />
          <EksportirRoute exact path="/permohonan" component={Permohonan} />
          <EksportirRoute
            exact
            path="/permohonan/add"
            component={PermohonanAdd}
          />
          <EksportirRoute
            exact
            path="/rencana-ekspor"
            component={RencanaEkspor}
          />
          <EksportirRoute
            exact
            path="/rencana-ekspor/add"
            component={RencanaEksporAdd}
          />

          <EksportirRoute exact path="/akses-ekspor" component={AksesEkspor} />
          <EksportirRoute
            exact
            path="/akses-ekspor/add"
            component={AksesEksporAdd}
          />

          <EksportirRoute
            exact
            path="/permohonan/detail/:id"
            component={PermohonanDetail}
          />

          <PphnakRoute
            path="/pphnak/settings"
            component={PphnakSettings}
            exact
          />
          <PphnakRoute
            path="/pphnak/permohonan/disposisi/edit"
            component={PermohonanDisposisiPphnakEdit}
            exact
          />
          <PphnakRoute
            path="/pphnak/permohonan/disposisi"
            component={PermohonanDisposisi}
            exact
          />
          <PphnakRoute
            path="/pphnak/permohonan/detail/:id"
            component={PermohonanDetailPphnak}
            exact
          />
          <PphnakRoute path="/pphnak/permohonan" component={PermohonanPphnak} />

          <DirektoratRoute
            path="/direktorat/settings"
            component={DirektoratSettings}
            exact
          />
          <DirektoratRoute
            path="/direktorat/permohonan/disposisi/edit"
            component={PermohonanDisposisiDirektoratEdit}
            exact
          />
          <DirektoratRoute
            path="/direktorat/permohonan/disposisi/proses"
            component={PermohonanDisposisiDirektoratProses}
            exact
          />
          <DirektoratRoute
            path="/direktorat/permohonan/disposisi"
            component={PermohonanDisposisiDirektorat}
            exact
          />
          <DirektoratRoute
            path="/direktorat/permohonan/detail/:id"
            component={PermohonanDetailDirektorat}
            exact
          />
          <DirektoratRoute
            path="/direktorat/permohonan"
            component={PermohonanDirektorat}
          />

          <SubditRoute
            path="/subdit/settings"
            component={SubditSettings}
            exact
          />

          <SubditRoute
            path="/subdit/permohonan/disposisi/edit"
            component={PermohonanDisposisiSubditEdit}
            exact
          />

          <SubditRoute
            path="/subdit/permohonan/disposisi/proses"
            component={PermohonanDisposisiSubditProses}
            exact
          />
          <SubditRoute
            path="/subdit/permohonan/detail/:id"
            component={PermohonanDetailSubdit}
            exact
          />
          <SubditRoute path="/subdit/permohonan" component={PermohonanSubdit} />
          <Route component={NotFound} />
        </Switch>
      }
    </ConnectedRouter>
  </Provider>
);

export default Routes;
