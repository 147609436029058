import React from "react";
import { Map } from "@esri/react-arcgis";
import {
  Col,
  Container,
  Row,
  Form,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Navbar from "../../container/navbar";
import req from "../../../helpers/axios";

import s from "./index.module.css";
import "./styleZoomButton.css";
import { CloudArrowDown, Search } from "react-bootstrap-icons";
import sweetalert from "../../../helpers/sweetalert";
import moment from "moment";
import BermudaTriangle from "./BermudaTriangle"; // The Graphic component we just made
import SelectR from "react-select";
import { Select } from "react-select-virtualized";
import { url, urlForImage } from "../../../helpers/env";
import axios from "axios";

const Index = () => {
  const [provinsi, setProvinsi] = React.useState("");
  const [tahun, setTahun] = React.useState(
    parseInt(moment().format("YYYY")) - 1
  );
  const [reRender, setReRender] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [hsCode, setHscode] = React.useState("");

  const [province, setProvince] = React.useState([]);
  const [year, setYear] = React.useState([]);

  const [forKomoditas, setForKomoditas] = React.useState([]);

  const [datas, setDatas] = React.useState([]);
  const [count, setCount] = React.useState(1);
  const [urlDownload, setUrlDownload] = React.useState("");

  let tmpTahun = 0;
  let tmpProvinsi = 0;
  let tmpData = [];
  const forSetRender = (param) => {
    setReRender(param);
  };

  const onDownload = async () => {
    if (urlDownload !== "") {
      window.open(`${urlForImage}/${urlDownload}`, "_blank");
    } else {
      sweetalert.info("Data tidak ada");
    }
  };

  const getKomoditas = async () => {
    if (provinsi !== "" && tahun !== "" && hsCode !== "") {
      tmpTahun = tahun;
      tmpProvinsi = provinsi;

      setLoading(true);
      await req
        .postWithNoHeaders({
          endpoint: `coordinate-all-potensi?tahun=${tahun}&provinsi=${provinsi}&kodeHS=${hsCode}`,
        })
        .then((res) => {
          if (res) {
            if (res.status === "success") {
              setDatas(res.data);
              setReRender(true);
              setLoading(false);
            } else {
              sweetalert.error(`${res.message}`);
            }
          }
        });

      const params = {
        tahun: tahun,
        provinsi: provinsi,
        kodeHS: hsCode,
      };
      await axios({
        url: `${url}/export-potensi`,
        method: "POST",
        data: params,
      })
        .then((res) => {
          if (res) {
            setUrlDownload(res?.data?.nama_file);
          }
        })
        .catch((err) => {
          sweetalert.error(`internal server error`);
        });
      setLoading(false);
    } else if (provinsi !== "" && tahun !== "") {
      tmpTahun = tahun;
      tmpProvinsi = provinsi;
      setLoading(true);
      await req
        .postWithNoHeaders({
          endpoint: `coordinate-all-potensi?tahun=${tahun}&provinsi=${provinsi}&kodeHS=all`,
        })
        .then((res) => {
          if (res) {
            if (res.status === "success") {
              setDatas(res.data);
              setReRender(true);
              setLoading(false);
            } else {
              sweetalert.error(`${res.message}`);
            }
          }
        });

      const params = {
        tahun: tahun,
        provinsi: provinsi,
        kodeHS: "all",
      };
      await axios({
        url: `${url}/export-potensi`,
        method: "POST",
        data: params,
      })
        .then((res) => {
          if (res) {
            setUrlDownload(res?.data?.nama_file);
          }
        })
        .catch((err) => {
          sweetalert.error(`internal server error`);
        });
      setLoading(false);
    } else if (hsCode !== "" && tahun !== "") {
      tmpTahun = tahun;
      tmpProvinsi = provinsi;
      setLoading(true);
      await req
        .postWithNoHeaders({
          endpoint: `coordinate-all-potensi?tahun=${tahun}&provinsi=all&kodeHS=${hsCode}`,
        })
        .then((res) => {
          if (res) {
            if (res.status === "success") {
              setDatas(res.data);
              setReRender(true);
              setLoading(false);
            } else {
              sweetalert.error(`${res.message}`);
            }
          }
        });

      const params = {
        tahun: tahun,
        provinsi: "all",
        kodeHS: hsCode,
      };
      await axios({
        url: `${url}/export-potensi`,
        method: "POST",
        data: params,
      })
        .then((res) => {
          if (res) {
            setUrlDownload(res?.data?.nama_file);
          }
        })
        .catch((err) => {
          sweetalert.error(`internal server error`);
        });
      setLoading(false);
    } else if (tahun !== "") {
      tmpTahun = tahun;
      tmpProvinsi = provinsi;
      setLoading(true);
      setTimeout(() => {
        setLoading(true);
        req
          .postWithNoHeaders({
            endpoint: `coordinate-all-potensi?tahun=${tahun}&provinsi=all&kodeHS=all`,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                setDatas(res.data);
                setReRender(true);
                setLoading(false);
              } else {
                sweetalert.error(`${res.message}`);
                setLoading(false);
              }
            }
          });
        const params = {
          tahun: tahun,
          provinsi: "all",
          kodeHS: "all",
        };
        axios({
          url: `${url}/export-potensi`,
          method: "POST",
          data: params,
        })
          .then((res) => {
            if (res) {
              setUrlDownload(res?.data?.nama_file);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            sweetalert.error(`internal server error`);
          });
      }, 3000);
    }
  };

  const onSumber = () => {
    window.open(`https://www.bps.go.id/`, "_blank");
  };

  const makeYear = () => {
    const year = moment().format("YYYY");
    const toIntYear = parseInt(year);
    const lastYear = toIntYear - 30;

    let tmpArrYear = [];
    for (let i = toIntYear; i >= lastYear; i--) {
      tmpArrYear.push(i);
    }
    const tmpYear = tmpArrYear.map((item) => ({ label: item, value: item }));
    setYear(tmpYear);
  };
  const getProvince = async () => {
    setLoading(true);
    await req
      .getWithNoHeaders({
        endpoint: "export-potential-map",
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setCount(count + 1);
            let tmpProvince = [{ label: "INDONESIA", value: "" }];
            const respon = res.data.provinces;
            Object.keys(respon).map((k) => {
              tmpProvince.push({
                value: k,
                label: respon[k],
              });
            });
            setProvince(tmpProvince);

            const respon2 = res.data.commodity2;
            let nData = [
              {
                label: "Semua Komoditas",
                options: [{ label: "-Tampilkan Semua", value: "all" }],
              },
            ];
            // let nData = [];
            Object.keys(respon2).map((k) => {
              nData.push({
                label:
                  k === "hewanHidup"
                    ? "Hewan Hidup"
                    : k === "panganSegarOlahan"
                    ? "Pangan Segar & Olahan"
                    : k === "benihTernak"
                    ? "Benih Ternak"
                    : k === "obatHewan"
                    ? "Obat Hewan"
                    : k === "nonPanagan"
                    ? "Non Pangan"
                    : "Lainnya",
                options: Object.keys(respon2[k]).map((item) => ({
                  label: `${respon2[k][item]}`,
                  value: item,
                })),
              });
            });
            setForKomoditas(nData);
            setLoading(false);
          } else {
            sweetalert.error(`${`Internal Server Error`}`);
            setLoading(false);
          }
        }
      });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      marginTop: 10,
      overflow: "auto",
    }),
  };

  React.useEffect(() => {
    getKomoditas();
    if (count === 1) {
      getProvince();
      makeYear();
    }
  }, [provinsi, tahun, hsCode]);

  return (
    <>
      <Container fluid className="grid-root">
        <Row>
          <Col xs="12" style={{ height: "100vh" }}>
            <Row>
              <Col xs="12">
                <Navbar />
              </Col>
            </Row>

            <Row style={{ marginTop: "80px" }}>
              <Col xs="12" className={`${s.sizeMap}`}>
                <Map
                  class="full-screen-map"
                  viewProperties={{
                    center: [106.83156413815725, -6.166243263125052],
                    effectiveMinZoom: 7,
                    zoom: 6,
                  }}
                >
                  <BermudaTriangle
                    datas={datas}
                    reRender={reRender}
                    setReRender={forSetRender}
                  />
                </Map>
              </Col>
            </Row>

            <Row className={`${s.inputForm}`}>
              <Col xs="12">
                <Row>
                  <Col xs={5}>
                    <SelectR
                      maxMenuHeight={200}
                      defaultValue={{ values: "", label: "INDONESIA" }}
                      options={province}
                      onChange={(option) => setProvinsi(option.value)}
                    />
                  </Col>
                  <Col xs={5}>
                    <SelectR
                      maxMenuHeight={200}
                      defaultValue={{
                        values: parseInt(moment().format("YYYY")) - 1,
                        label: parseInt(moment().format("YYYY")) - 1,
                      }}
                      options={year}
                      onChange={(option) => setTahun(option.value)}
                    />
                  </Col>

                  {((provinsi !== "" && tahun !== "" && hsCode !== "") ||
                    (tahun !== "" && provinsi !== "") ||
                    (tahun !== "" && hsCode !== "") ||
                    tahun !== "") &&
                    loading === false && (
                      <Col xs={2}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Download data
                            </Tooltip>
                          }
                        >
                          <CloudArrowDown size={30} onClick={onDownload} />
                        </OverlayTrigger>
                      </Col>
                    )}
                </Row>
                <Row>
                  <Col>
                    <Select
                      styles={customStyles}
                      options={forKomoditas}
                      // defaultValue={{ label: "Pilih Komoditas", value: "" }}
                      grouped
                      minimumInputSearch={0}
                      onChange={(option) =>
                        option !== null ? setHscode(option.value) : ""
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {loading && (
              <Row className={`${s.forLoading}`}>
                <Col className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            )}

            <Row className={`${s.onSumber}`}>
              <Col>
                <p style={{ fontSize: "13px", color: "black" }}>
                  Sumber Data :{" "}
                  <span onClick={onSumber} style={{ color: "black" }}>
                    Badan Pusat Statistik
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
