import React from "react";
import {
  Modal,
  Button,
  InputGroup,
  Form,
  Row,
  Col,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Eye, EyeSlash, InfoCircle } from "react-bootstrap-icons";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import req from "../../helpers/axios";

const Changepassword = (props) => {
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      telegramId: localStorage.getItem("telegramId"),
    },
    validationSchema: Yup.object({
      telegramId: Yup.string().required("Harus diisi!"),
    }),
    onSubmit: (values) => {
      const params = {
        telegramId: values.telegramId,
      };
      setLoading(true);
      req
        .post({
          // endpoint belum ada
          endpoint: `change_telegram`,
          data: params,
        })
        .then((res) => {
          if (res) {
            if (res.status === "error") {
              Swal.fire({
                text: `${res.message}`,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              });
            } else if (res.status === "success") {
              localStorage.setItem("telegramId", params.telegramId);
              Swal.fire({
                text: `${res.message}`,
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              }).then((response) => {
                history.go(0);
              });
            }
            setLoading(false);
          }
        });
    },
  });

  const dataProfile = JSON.parse(localStorage.getItem("profile"));
  // console.log(dataProfile);
  const onIdTelegram = () => {
    if (dataProfile?.user?.id !== null || dataProfile?.user?.id != undefined) {
      window.open(
        `https://t.me/kementan_bot?start=${dataProfile?.user?.id}`,
        "_blank"
      );
    }
  };
  return (
    <div>
      <Modal
        show={props.data.showTelegram}
        onHide={props.data.closeModalTelegram}
        style={{ overflow: "auto" }}
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header className="justify-content-center" closeButton>
            <Modal.Title>Ganti ID telegram </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>
              <InfoCircle onClick={onIdTelegram} /> Klik untuk register atau
              ubah ID telegram
            </Form.Label>

            <Form.Group controlId="telegramId">
              <Form.Control
                name="telegramId"
                type="text"
                disabled
                placeholder="ID telegram"
                value={formik.values.telegramId}
                onChange={formik.handleChange}
                className={
                  formik.errors.telegramId &&
                  formik.touched.telegramId &&
                  "form-err"
                }
              />
            </Form.Group>
            {formik.errors.telegramId && formik.touched.telegramId && (
              <p style={{ marginTop: "-10px", color: "#EB5757" }}>
                {formik.errors.telegramId}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            {loading ? (
              <Row className="text-center">
                <Col>
                  <Spinner animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : (
              <Button block="true" type="submit" className="btn-bg-primary">
                Submit
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Changepassword;
