import React from "react";
import { Container, Row, Col, Collapse, Form, Spinner } from "react-bootstrap";
import Navbar from "../../navbar";
import "./index.css";
import "../../../../styles/index.css";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import FileUpload from "../../../../assets/icon/file.svg";
import siAlek from "../../../../assets/icon/sialek.png";
import pertanianImg from "../../../../assets/icon/deptan.svg";

// import Dropzonez from "react-dropzone-uploader";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import Dropzone from "react-dropzone";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import ModalImage from "../../../modal/showimage";
import req from "../../../../helpers/axios";
import sweetalert from "../../../../helpers/sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Trash } from "react-bootstrap-icons";
import { urlForImage } from "../../../../helpers/env";
import axios from "axios";

const Detail = (props) => {
  let { id } = useParams();
  const history = useHistory();
  const [dataAcc, setDataAcc] = React.useState({});
  const [data, setData] = React.useState({});
  const [commodity, setCommodity] = React.useState({});
  const [eksporter, setEksporter] = React.useState({});
  const [proses, setProses] = React.useState([]);
  const [role, setRole] = React.useState([]);
  const [urlImage, setUrlImage] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [reRender, setReRender] = React.useState(false);
  const [filePaths, setFilePaths] = React.useState([]);
  const [document, setDocument] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [collapse1, setcollapse1] = React.useState(false);
  const [collapse2, setcollapse2] = React.useState(false);
  const [collapse3, setcollapse3] = React.useState(false);
  const [collapse4, setcollapse4] = React.useState(false);
  const [collapse5, setcollapse5] = React.useState(false);
  const [allData, setAllData] = React.useState([]);

  const [lastProcess, setLastProcess] = React.useState({});

  const onDrag = (e) => {
    if (e) {
      if (e[0].size < 1000000) {
        setData({ file: e });

        e.map((i) => {
          // return setImageName(i.name);
          formik.setFieldValue("namafile", i.name);
        });

        const fd = new FormData();
        fd.append("file", e[0]);
        req
          .post({
            endpoint: `acceleration/${id}/addDocument`,
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status == "success") {
                const path = res.data.path || null;
                const setpath = [...filePaths];
                setpath.push(path);
                setFilePaths(setpath);
              }
            }
          });
      } else {
        sweetalert.error(`Ukuran file maksimal 1mb`);
      }
    }
  };

  const handleAdd = () => {
    if (formik.values.namafile === "" || formik.values.keterangan === "") {
      return sweetalert.info(
        `Jika ingin menambah dokumen, Gambar dan keterangan harus diisi`
      );
    }
    formik.setFieldValue("namafile", "");
    formik.setFieldValue("keterangan", "");
    const fd = new FormData();
    fd.append("file", data);
    let tmp = allData;
    const result = {
      ...data,
      namafile: formik.values.namafile,
      keterangan: formik.values.keterangan,
    };

    tmp.push(result);

    setAllData(tmp);
  };
  const deleteNotes = (index) => {
    allData.splice(index, 1);
    setReRender(!reRender);
  };

  const detailDocument = (url1, url2) => {
    const tmpSplit = url2.split(".");

    const tmpIndex = tmpSplit.length > 0 ? tmpSplit.length - 1 : null;
    const tmpTypeData = tmpSplit[tmpIndex].toLowerCase();

    if (
      tmpTypeData === "jpg" ||
      tmpTypeData === "jpeg" ||
      tmpTypeData === "png"
    ) {
      setUrlImage(
        `${urlForImage}/submission-acceleration/documents/exporters/${eksporter.id}/${url1}/attachments/supporting-documents/${url2}`
      );
      setShow(true);
    } else {
      window.open(
        `${urlForImage}/submission-acceleration/documents/exporters/${eksporter.id}/${url1}/attachments/supporting-documents/${url2}`,
        "_blank"
      );
    }
  };

  const showModal = (doc, title) => {
    if (doc) {
      const tmpSplit = doc.split(".");

      const tmpIndex = tmpSplit.length > 0 ? tmpSplit.length - 1 : null;
      const tmpTypeData = tmpSplit[tmpIndex].toLowerCase();
      let forUrl = "";

      if (title === "ktp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.ktp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.ktp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "npwp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.npwp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.npwp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "siup") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.siup_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.siup_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "tdp") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.tdp_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.tdp_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }

      if (title === "nib") {
        if (tmpTypeData === "pdf") {
          window.open(
            `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.nib_photo.path}`,
            "_blank"
          );
        } else {
          forUrl = `${urlForImage}/company_administration_data/${eksporter.id}/${eksporter.nib_photo.path}`;
          setUrlImage(forUrl);
          setShow(true);
        }
      }
    }
  };

  const getDocument = async (doc, id_proses) => {
    if (doc) {
      const tmpSplit = doc?.document.split(".");

      const tmpIndex = tmpSplit.length > 0 ? tmpSplit.length - 1 : null;
      const tmpTypeData = tmpSplit[tmpIndex].toLowerCase();

      if (
        tmpTypeData === "png" ||
        tmpTypeData === "jpg" ||
        tmpTypeData === "jpeg"
      ) {
        const urlImage = `${urlForImage}/submission-acceleration/documents/exporters/${eksporter.id}/${id}/processes/${id_proses}/documents/${doc.document}`;
        setUrlImage(urlImage);
        setShow(true);
      } else {
        window.open(
          `${urlForImage}/submission-acceleration/documents/exporters/${eksporter.id}/${id}/processes/${id_proses}/documents/${doc.document}`,
          "_blank"
        );
      }
    }
  };

  const getDetail = async () => {
    await req
      .get({
        endpoint: `acceleration/${id}/view`,
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setCommodity(res.data.acceleration.commodity);
            setDataAcc(res.data.acceleration);
            setProses(res.data.process);
            setRole(res.data.user_roles);
            setEksporter(res.data.exporter);
            setLastProcess(res.data.latest_process);
            setDocument(res.data.acceleration.documents);
          } else {
            sweetalert.error(`${res.message}`);
          }
        }
      });
  };

  const baru = (
    <button className={"button-proses bg-color-baru fsize10"}>Baru</button>
  );
  const menunggu = (
    <button className={"button-proses bg-color-menunggu fsize10"}>
      Menunggu
    </button>
  );
  const diproses = (
    <button className={"button-proses bg-color-diproses fsize10"}>
      Di Proses
    </button>
  );
  const dikembalikan = (
    <button className={"button-proses bg-color-dikembalikan fsize10"}>
      Di Kembalikan
    </button>
  );
  const update = (
    <button className={"button-proses bg-color-update fsize10"}>Update</button>
  );
  const selesai = (
    <button className={"button-proses bg-color-selesai fsize10"}>
      Selesai
    </button>
  );

  const participantsButton = (param) => {
    switch (param) {
      case "0":
        return baru;
      case "1":
        return dikembalikan;
      case "2":
        return update;
      case "3":
        return menunggu;
      case "4":
        return diproses;
      case "5":
        return selesai;
      default:
        break;
    }
  };

  const forSwitch = (param) => {
    switch (param) {
      case "0":
        return baru;
      case "1":
        return dikembalikan;
      case "2":
        return update;
      case "3":
        return menunggu;
      case "4":
        return diproses;
      case "5":
        return selesai;
      default:
        break;
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      namafile: "",
      keterangan: "",
      catatan: "",
    },
    validationSchema: Yup.object({
      // namafile: Yup.string().required("Harus diisi!"),
      // keterangan: Yup.string().required("Harus diisi!"),
      // catatan: Yup.string().required("Harus diisi!"),
    }),
    onSubmit: (values, actions) => {
      delete values.namafile;
      const resultData = {
        notes: values.catatan,
      };
      const fd = new FormData();
      fd.append("explanation", resultData.notes);
      // fd.append("parent_id", resultData.notes); // TODO LIST
      allData.map((item, index) => {
        fd.append("file_names[" + index + "]", item.namafile);
        fd.append("document_desc[" + index + "]", item.keterangan);
        fd.append("file_paths[" + index + "]", filePaths[index]);
      });
      setLoading(true);
      req
        .post({
          endpoint: `acceleration/${id}/reAdd`,
          data: fd,
        })
        .then((res) => {
          if (res) {
            if (res.status === "success") {
              sweetalert
                .success(
                  `Permohonan Percepatan Ekspor anda
              akan ditinjau kembali oleh pihak PPHNAK,
              Terimakasih`
                )
                .then((res) => {
                  // history.push("/permohonan");
                  history.go(0);
                });
            } else {
              sweetalert.error(`${res.message}`);
            }
            setLoading(false);
          }
        });
    },
  });

  React.useEffect(() => {
    getDetail();
  }, [reRender]);
  return (
    <>
      <ModalImage data={{ show, closeModal, urlImage }} />
      <Container>
        <Row>
          <Col xs="12">
            <Navbar />
          </Col>
          <Col xs="12" style={{ marginTop: "70px" }}>
            {/* nomor pengajuan */}
            <button
              className="button-collapse"
              onClick={() => setcollapse1(!collapse1)}
            >
              <span className="title-collapse">
                Nomor Pengajuan - {dataAcc.submission_number}
              </span>
              <span>{collapse1 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>

            <Collapse in={collapse1}>
              <div id="collapse1" className="paragraph">
                <p>HS Code</p>
                <p className="font-weight-bold mb-2"> {commodity.hs_code}</p>

                <p>Volume (kg)</p>
                <p className="font-weight-bold mb-2">
                  {dataAcc.volume_capacity}
                </p>

                <p>Nilai (US$)</p>
                <p className="font-weight-bold mb-2">{dataAcc.cost_value}</p>

                <p>Tanggal Pengajuan</p>
                <p className="font-weight-bold mb-2">
                  {moment(dataAcc.created_at).format("dddd, DD MMMM YYYY")}
                </p>

                <p>Update Terakhir</p>
                <p className="font-weight-bold mb-2">
                  {moment(dataAcc.updated_at).format("dddd, DD MMMM YYYY")}
                </p>
                {/* <p className="font-weight-bold mb-2">Senin, 21 Desember 2020</p> */}

                <p>Status</p>
                {forSwitch(dataAcc.latest_status)}
              </div>
            </Collapse>

            {/* profil pelaku usaha ekspor */}
            <button
              className="button-collapse"
              onClick={() => setcollapse2(!collapse2)}
            >
              <span className="title-collapse">Profil Pelaku Usaha Ekspor</span>
              <span>{collapse2 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>

            <Collapse in={collapse2}>
              <div id="collapse2" className="paragraph">
                <p>Nama Perusahaan</p>
                <p className="font-weight-bold mb-2">
                  {eksporter.company_name}
                </p>

                <p>Telp/Fax/Wa</p>
                <p className="font-weight-bold mb-2">
                  {eksporter.phone_number}
                </p>

                <p>Nama Contact Person</p>
                <p className="font-weight-bold mb-2">
                  {eksporter.person_in_charge}
                </p>

                <p>Jabatan</p>
                <p className="font-weight-bold mb-2">{eksporter.position}</p>

                <p>Alamat Perusahaan</p>
                {/* <p className="font-weight-bold mb-2">{eksporter.address}</p> */}
                <p className="font-weight-bold mb-2">{`${eksporter?.city?.name}, ${eksporter.address}`}</p>

                <p>Email Perusahaan</p>
                <p className="font-weight-bold mb-2">{eksporter.email}</p>

                <p>No. HP/WA</p>
                <p className="font-weight-bold mb-2">
                  {/* {eksporter.phone_number} */}
                  {eksporter.pic_phone_number}
                </p>
              </div>
            </Collapse>

            {/* dokumen administrasi */}
            <button
              className="button-collapse"
              onClick={() => setcollapse3(!collapse3)}
            >
              <span className="title-collapse"> Dokumen Administrasi </span>
              <span>{collapse3 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>

            <Collapse in={collapse3}>
              <div id="collapse3" className="paragraph">
                <p>Foto KTP Pimpinan Perusahaan</p>
                <p
                  className="color-underline mb-2"
                  onClick={() =>
                    showModal(`${eksporter.ktp_photo.path}`, "ktp")
                  }
                >
                  view file
                </p>

                <p>Foto NPWP</p>
                <p
                  className="mb-2 color-underline"
                  onClick={() =>
                    showModal(`${eksporter.npwp_photo.path}`, "npwp")
                  }
                >
                  view file
                </p>

                <p>SIUP</p>
                <p
                  className="mb-2 color-underline"
                  onClick={() =>
                    showModal(`${eksporter.siup_photo.path}`, "siup")
                  }
                >
                  view file
                </p>

                <p>TDP</p>
                <p
                  className="mb-2 color-underline"
                  onClick={() =>
                    showModal(`${eksporter.tdp_photo.path}`, "tdp")
                  }
                >
                  view file
                </p>

                <p>NIB</p>
                <p
                  className="mb-2 color-underline"
                  onClick={() =>
                    showModal(`${eksporter.nib_photo.path}`, "nib")
                  }
                >
                  view file
                </p>
              </div>
            </Collapse>

            {/* dokumen pendukung */}
            <button
              className="button-collapse"
              onClick={() => setcollapse4(!collapse4)}
            >
              <span className="title-collapse"> Dokumen Pendukung </span>
              <span>{collapse4 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>

            <Collapse in={collapse4}>
              <div id="collapse4" className="paragraph">
                {document.map((item, index) => (
                  <div key={index}>
                    <p>Dokumen Pendukung {`${index + 1}`}</p>
                    <p
                      className="color-underline mb-2"
                      onClick={() => detailDocument(id, item.path)}
                    >
                      view file
                    </p>
                  </div>
                ))}
              </div>
            </Collapse>

            {/* history disposisi  */}
            <button
              className="button-collapse"
              onClick={() => setcollapse5(!collapse5)}
            >
              <span className="title-collapse"> History Disposisi </span>
              <span>{collapse5 ? <CaretUpFill /> : <CaretDownFill />}</span>
            </button>

            <Collapse in={collapse5}>
              <div id="collapse5">
                <Timeline align="left" className="root-title">
                  {proses.length > 0 ? (
                    proses.map((item, index) => (
                      <div
                        className="fsize12"
                        key={index}
                        style={{ borderBottom: "1px solid grey" }}
                      >
                        <div>
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineDot />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                              <Row noGutters className="title-timeline">
                                <Col xs="12">
                                  <p>
                                    {moment(item.updated_at).format("dddd")},
                                  </p>
                                  <p>
                                    {moment(item.updated_at).format(
                                      "DD MMMM YYYY"
                                    )}
                                  </p>
                                </Col>
                                <Col
                                  xs="12"
                                  className="d-flex align-items-center"
                                >
                                  <div className="circle mr-2">
                                    {/* eksporter */}
                                    <img
                                      src={
                                        item.from_role_id === 2
                                          ? eksporter?.profile_picture !== null
                                            ? `${urlForImage}/company_profile/${eksporter.id}/${eksporter.profile_picture}`
                                            : siAlek
                                          : pertanianImg
                                      }
                                      alt=""
                                      className="circle-fit"
                                    ></img>
                                  </div>
                                  <span>
                                    {role.map((v) => {
                                      if (v.id === item.from_role_id) {
                                        return v.name;
                                      }
                                    })}
                                  </span>
                                </Col>
                              </Row>
                            </TimelineContent>
                          </TimelineItem>
                          {item.participants.map((itm, idx) => (
                            <div key={`k${idx}`} className="fsize12">
                              <TimelineItem>
                                <TimelineSeparator className="child-title">
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent className="child-size">
                                  <Row className="d-flex justify-content-around align-items-center">
                                    <Col xs="8">
                                      <p className="font-weight-bold">
                                        {role.map((v) => {
                                          if (v.id === itm.role_id) {
                                            return `${v.name}`;
                                          }
                                        })}
                                      </p>
                                    </Col>
                                    <Col xs="4" className="text-right">
                                      {participantsButton(itm.status)}
                                    </Col>
                                  </Row>
                                </TimelineContent>
                              </TimelineItem>
                            </div>
                          ))}
                        </div>
                        <Row>
                          <Col xs="10">
                            <p>
                              Di proses oleh:
                              {role.map((v) => {
                                if (v.id === item.from_role_id) {
                                  return `  ${v.name}`;
                                }
                              })}
                            </p>
                            <p>{item.explanation}</p>
                          </Col>
                        </Row>
                        {/* JIKA ROLE ID 3 MAKA TAMPILKAN FILE */}
                        {item?.documents?.map((doc, docIndex) => (
                          <p
                            className="txt-blue txt-underline-blue"
                            key={docIndex}
                            onClick={() => getDocument(doc, item.id)}
                          >
                            {doc.document}
                          </p>
                        ))}
                      </div>
                    ))
                  ) : (
                    <Row>
                      <Col className={`text-center`}>
                        <p>- Tidak ada data -</p>
                      </Col>
                    </Row>
                  )}
                </Timeline>
              </div>
            </Collapse>

            {dataAcc.latest_status === "1" && (
              <Row className="mt-3">
                <Col xs="12">
                  <div className="d-flex mb-2">
                    <div className="pr-2">
                      <h6>Lengkapi Data</h6>
                    </div>
                    <hr className="my-auto flex-grow-1 linebold" />
                  </div>
                </Col>
                <Col xs="12">
                  <p>
                    Pengirim:
                    <span className="font-weight-bold">
                      {role.map((v) => {
                        if (v.id == lastProcess?.from_role_id) {
                          return v.name;
                        }
                      })}
                    </span>
                  </p>

                  <p>Catatan:</p>
                  <p>{lastProcess?.explanation}</p>

                  <p>Upload file yang dibutuhkan :</p>
                  <Form onSubmit={formik.handleSubmit}>
                    <Dropzone
                      multiple={false}
                      onDrop={(acceptedFiles) => onDrag(acceptedFiles)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section
                          className="border-upload"
                          style={{ marginBottom: "1rem" }}
                        >
                          <div
                            {...getRootProps()}
                            className="border-upload-fill"
                          >
                            <input {...getInputProps()} />
                            <div className="border-fill">
                              <img
                                src={FileUpload}
                                alt="file"
                                className="pt-2 pb-2"
                              ></img>
                              <div className="border-image">Upload</div>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <Form.Group controlId="keterangan">
                      <Form.Label>Ket. Dokumen</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="keterangan"
                        value={formik.values.keterangan}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>

                    <button
                      type="button"
                      className="button-add fsize16 txt-blue"
                      onClick={(e) => handleAdd(e)}
                    >
                      Tambah
                    </button>
                    <Row
                      style={{ marginLeft: 0, marginRight: 0 }}
                      className={allData.length > 0 ? "notes" : null}
                    >
                      <Col xs="12">
                        {allData.map((item, index) => (
                          <Row
                            key={index}
                            style={{
                              position: "relative",
                              marginBottom: "10px",
                            }}
                          >
                            <Col xs="6">Nama File:</Col>
                            <Col xs="6">
                              {item.namafile.length > 10
                                ? item.namafile.substring(0, 10) + "..."
                                : item.namafile}
                            </Col>
                            <Col xs="6">Ket. Dokumen</Col>
                            {/* <Col xs="6">{item.keterangan}</Col> */}
                            <Col xs="6">
                              {item.keterangan.length > 100
                                ? item.keterangan.substring(0, 100) + "..."
                                : item.keterangan}
                            </Col>
                            <Trash
                              style={{
                                position: "absolute",
                                right: 0,
                                bottom: "5px",
                                color: "red",
                              }}
                              onClick={() => deleteNotes(index)}
                            />
                          </Row>
                        ))}
                      </Col>
                    </Row>

                    <Form.Group controlId="catatan">
                      <Form.Label>Catatan</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="catatan"
                        placeholder="Tulis Pesan"
                        value={formik.values.catatan}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    {loading ? (
                      <Row className="text-center">
                        <Col>
                          <Spinner animation="grow" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </Col>
                      </Row>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-block btn-bg-primary text-white mb-3"
                      >
                        Kirim
                      </button>
                    )}
                  </Form>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Detail;
