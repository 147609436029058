import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import Logo from "../../../assets/image/imageSidebar.png";
import ProfileDeptan from "../../../assets/icon/deptan.svg";
import { useHistory } from "react-router-dom";

const Sidebar = (props) => {
  const history = useHistory();
  const forNewPassword = localStorage.getItem("np") || 0;
  const forConfirmation = localStorage.getItem("rg") || 0;
  // const Sidebar = ({toggle, handleToggle, listMenu}) => {
  const { toggle, handleToggle, listMenu } = props.data;
  const roleid = localStorage.getItem("roleid");
  React.useEffect(() => {}, [listMenu]);
  const getImage = () => {
    if (roleid == null || roleid == 2) {
      return Logo;
    } else {
      return ProfileDeptan;
    }
  };
  return (
    <div>
      <Drawer anchor="left" open={toggle} onClose={handleToggle}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button>
            {/* <ListItemAvatar>
              <Avatar src={getImage() }></Avatar>
            </ListItemAvatar> */}
            <img src={getImage()} alt="" style={{ maxWidth: "60%" }} />
          </ListItem>
          {listMenu &&
            listMenu.length &&
            listMenu.map((v, k) => {
              return (
                <ListItem key={"__list" + k} button>
                  <ListItemText
                    id={"_menu" + k}
                    key={"_menu" + k}
                    primary={v.title}
                    onClick={() => handleToggle(v.id)}
                  />
                </ListItem>
              );
            })}
          {(forNewPassword == 1 || forNewPassword == "1") && (
            <ListItem key={"__list" + 123} button>
              <ListItemText
                id={"_menu" + 111}
                key={"_menu" + 123}
                primary={"Password baru"}
                onClick={() => history.push("/new-password")}
              />
            </ListItem>
          )}

          {(forConfirmation == 1 || forConfirmation == "1") && (
            <ListItem key={"__list" + 108} button>
              <ListItemText
                id={"_menu" + 156}
                key={"_menu" + 189}
                primary={"Konfirmasi Email"}
                onClick={() => history.push("/konfirmasi")}
              />
            </ListItem>
          )}
        </List>
        <Divider />
        <List component="nav" aria-label="secondary mailbox folders"></List>
      </Drawer>
    </div>
  );
};

export default Sidebar;
