import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../../styles/index.css";
import "../index.css";
import moment from "moment";

const CardItem = (props) => {
  const data = props.data;
  return (
    <>
      <Card className="shadow mb-3">
        <Card.Body className="card-box">
          <Row className="mb-2 p-txt-black pl-3 pr-3">
            <Col xs="6">
              <p className="font-weight-bold">Nama File</p>
              <p className="mb-2">{data.path}</p>

              <p className="font-weight-bold">Nama Dokumen</p>
              <p className="mb-2">{data.name}</p>
            </Col>
            <Col xs="6">
              <p className="font-weight-bold">Nomor Dokumen</p>
              <p className="mb-2">{data.number}</p>

              <p className="font-weight-bold">Tanggal Penerbitan</p>
              <p className="mb-2">
                {moment(data.issue_date).format("DD MMMM YYYY")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-right mt-1">
              <p
                className="txt-blue"
                onClick={() =>
                  props.detailDocument(`${data.submission_id}`, `${data.path}`)
                }
              >
                Tampilkan Dokumen
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CardItem;
