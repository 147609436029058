import React from "react";
import Routes from "./routes";
import configureStore from "./shared/configure-store";
// import "react-select/dist/react-select.css";
// import "react-virtualized/styles.css";
// import "react-virtualized-select/styles.css";
import "./index.css";
import "moment/min/locales";
import moment from "moment";

import { setDefaultOptions } from "esri-loader";

setDefaultOptions({ css: true });

const store = configureStore({});

function App() {
  moment.locale("id");
  return <Routes store={store} />;
}

export default App;
