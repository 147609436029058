import React from "react";
import { Map } from "@esri/react-arcgis";
import {
  Col,
  Container,
  Row,
  Form,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Navbar from "../../container/navbar";
import req from "../../../helpers/axios";

import s from "./index.module.css";
import "./styleZoomButton.css";
import { CloudArrowDown, Search } from "react-bootstrap-icons";
import sweetalert from "../../../helpers/sweetalert";
import moment from "moment";
import BermudaTriangle from "./BermudaTriangle"; // The Graphic component we just made
import axios from "axios";
import SelectR from "react-select";
import { Select } from "react-select-virtualized";
import { url, urlForImage } from "../../../helpers/env";

const Index = () => {
  const [countryInput, setCountryInput] = React.useState("");
  const [tahun, setTahun] = React.useState(
    parseInt(moment().format("YYYY")) - 1
  );
  const [reRender, setReRender] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [jenisKomoditas, setJenisKomoditas] = React.useState("");

  const [forKomoditas, setForKomoditas] = React.useState([]);
  const [itemKomoditas, setItemKomoditas] = React.useState([]);
  const [urlDownload, setUrlDownload] = React.useState("");

  const [country, setCountry] = React.useState([]);
  const [year, setYear] = React.useState([]);

  //data
  const [datas, setDatas] = React.useState([]);
  const [count, setCount] = React.useState(1);

  let tmpTahun = 0;
  let tmpProvinsi = 0;
  let tmpJenisKomoditas = 0;
  let tmpData = [];

  const forSetRender = (param) => {
    setReRender(param);
  };
  const onSumber = () => {
    window.open(`https://comtrade.un.org/`, "_blank");
  };
  const getData = async () => {
    setLoading(true);
    await req
      .getWithNoHeaders({
        endpoint: "world-potential-map",
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setCount(count + 1);
            // setCountry(res.data.data);
            const respon = res.data.comtrade_commodities2;
            const countries = res.data.comtrade_contries;
            const forLongLat = res.data.countries;
            // code:"",code_3:"",created_at:"",created_by:"",deleted_at:"",deleted_by:"",id:"",latitude:}
            let aData = [{ value: "", label: "Semua Negara", fill: {} }];
            Object.keys(countries).map((k) => {
              aData.push({
                value: k,
                label: countries[k],
                fill: forLongLat.find((value, index) => {
                  if (value.name === countries[k]) {
                    return value;
                  }
                }),
              });
            });
            setCountry(aData);

            let nData = [
              {
                label: "Semua Komoditas",
                options: [{ label: "-Tampilkan Semua", value: "all" }],
              },
            ];
            Object.keys(respon).map((k) => {
              nData.push({
                label:
                  k === "hewanHidup"
                    ? "Hewan Hidup"
                    : k === "panganSegarOlahan"
                    ? "Pangan Segar & Olahan"
                    : k === "benihTernak"
                    ? "Benih Ternak"
                    : k === "obatHewan"
                    ? "Obat Hewan"
                    : k === "nonPanagan"
                    ? "Non Pangan"
                    : "Lainnya",
                options: Object.keys(respon[k]).map((item) => ({
                  label: `${respon[k][item]}`,
                  value: item,
                })),
              });
            });
            // console.log(nData);
            setForKomoditas(nData);

            setLoading(false);
          } else {
            sweetalert.error(`${`Internal Server Error`}`);
          }
        }
      });
  };
  const getKomoditas = async () => {
    if (
      countryInput !== "" &&
      tahun !== "" &&
      jenisKomoditas !== "" &&
      ((tmpTahun === 0 && tmpProvinsi === 0) ||
        (tmpProvinsi !== countryInput && tmpTahun !== tahun)(
          tmpJenisKomoditas !== jenisKomoditas &&
            tmpJenisKomoditas !== jenisKomoditas
        ))
    ) {
      // alert(`${countryInput}-${tahun}-${jenisKomoditas}`);
      tmpTahun = tahun;
      tmpProvinsi = countryInput;
      setLoading(true);
      await axios({
        url: `https://comtrade.un.org/api/get?max=1000&type=C&freq=A&px=HS&ps=${tahun}&r=${countryInput}&p=all&rg=1&cc=${jenisKomoditas}`,
        method: "GET",
      })
        .then((res) => {
          const dataset = res.data.dataset ?? [];
          if (res.data.validation.status.name === "Ok") {
            let dData = [];

            dataset.map((item) => {
              dData.push({
                data: item,
                datasets: country.find((value, index) => {
                  if (value.value == item.ptCode) {
                    return value;
                  }
                }),
              });
            });
            // console.log(dData);
            setDatas(dData);
            setReRender(true);
          }
        })
        .catch((err) => {
          sweetalert.error("internal server error");
        });

      const params = {
        year: tahun,
        commodity: jenisKomoditas,
        country: countryInput,
        partner: "all",
      };
      await axios({
        url: `${url}/export`,
        method: "POST",
        data: params,
      })
        .then((res) => {
          if (res) {
            setUrlDownload(res?.data?.nama_file);
          }
        })
        .catch((err) => {
          sweetalert.error(`internal server error`);
        });
      setLoading(false);
      return false;
    } else if (tahun !== "" && jenisKomoditas !== "") {
      tmpTahun = tahun;
      tmpJenisKomoditas = jenisKomoditas;
      setLoading(true);
      await axios({
        url: `https://comtrade.un.org/api/get?max=1000&type=C&freq=A&px=HS&ps=${tahun}&r=all&p=0&rg=1&cc=${jenisKomoditas}`,
        method: "GET",
      })
        .then((res) => {
          const dataset = res.data.dataset ?? [];
          if (res.data.validation.status.name === "Ok") {
            let dData = [];

            dataset.map((item) => {
              dData.push({
                data: item,
                datasets: country.find((value, index) => {
                  if (value.label == item.rtTitle) {
                    return value;
                  }
                }),
              });
            });

            setDatas(dData);
            setReRender(true);

            // console.log(dData);
          }
        })
        .catch((err) => {
          sweetalert.error("internal server error");
        });

      const params = {
        year: tahun,
        commodity: jenisKomoditas,
        country: countryInput,
        partner: 0,
      };
      await axios({
        url: `${url}/export`,
        method: "POST",
        data: params,
      })
        .then((res) => {
          if (res) {
            setUrlDownload(res?.data?.nama_file);
          }
        })
        .catch((err) => {
          sweetalert.error(`internal server error`);
        });
      setLoading(false);
      return false;
    } else if (tahun !== "" && countryInput !== "") {
      tmpTahun = tahun;
      tmpJenisKomoditas = jenisKomoditas;
      setLoading(true);
      await axios({
        url: `https://comtrade.un.org/api/get?max=1000&type=C&freq=A&px=HS&ps=${tahun}&r=${countryInput}&p=all&rg=1&cc=TOTAL`,
        method: "GET",
      })
        .then((res) => {
          const dataset = res.data.dataset ?? [];
          if (res.data.validation.status.name === "Ok") {
            let dData = [];

            dataset.map((item) => {
              dData.push({
                data: item,
                datasets: country.find((value, index) => {
                  if (value.label == item.ptTitle) {
                    return value;
                  }
                }),
              });
            });

            setDatas(dData);
            setReRender(true);
            // console.log(dataset);
            // console.log(country);
            // console.log(dData);
          }
        })
        .catch((err) => {
          sweetalert.error("internal server error");
        });

      const params = {
        year: tahun,
        country: countryInput,
        commodity: "TOTAL",
        partner: "all",
      };
      await axios({
        url: `${url}/export`,
        method: "POST",
        data: params,
      })
        .then((res) => {
          if (res) {
            setUrlDownload(res?.data?.nama_file);
          }
        })
        .catch((err) => {
          sweetalert.error(`internal server error`);
        });
      setLoading(false);
      return false;
    } else if (tahun !== "") {
      tmpTahun = tahun;
      tmpJenisKomoditas = jenisKomoditas;
      setLoading(true);
      await axios({
        url: `https://comtrade.un.org/api/get?max=1000&type=C&freq=A&px=HS&ps=${tahun}&r=all&p=0&rg=1&cc=TOTAL`,
        method: "GET",
      })
        .then((res) => {
          const dataset = res.data.dataset ?? [];
          if (res.data.validation.status.name === "Ok") {
            let dData = [];

            dataset.map((item) => {
              dData.push({
                data: item,
                datasets: country.find((value, index) => {
                  if (value.label == item.rtTitle) {
                    return value;
                  }
                }),
              });
            });

            setDatas(dData);
            setReRender(true);
            // console.log(dataset);
            // console.log(country);
            // console.log(dData);
          }
        })
        .catch((err) => {
          sweetalert.error("internal server error");
        });

      const params = {
        year: tahun,
        commodity: "TOTAL",
        country: "all",
        partner: 0,
      };
      await axios({
        url: `${url}/export`,
        method: "POST",
        data: params,
      })
        .then((res) => {
          if (res) {
            setUrlDownload(res?.data?.nama_file);
          }
        })
        .catch((err) => {
          sweetalert.error(`internal server error`);
        });
      setLoading(false);
      return false;
    }
  };

  const onDownload = async () => {
    if (urlDownload !== "") {
      window.open(`${urlForImage}/${urlDownload}`, "_blank");
    } else {
      sweetalert.info("Data tidak ada");
    }
  };
  const makeYear = () => {
    const year = moment().format("YYYY");
    const toIntYear = parseInt(year);
    const lastYear = toIntYear - 30;

    let tmpArrYear = [];
    for (let i = toIntYear; i >= lastYear; i--) {
      tmpArrYear.push(i);
    }

    let selectYear = tmpArrYear.map((item) => ({ value: item, label: item }));
    setYear(selectYear);
  };
  const searchSubmission = () => {
    // setReRender(!reRender);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      marginTop: 10,
    }),
  };

  React.useEffect(() => {
    // if (
    //   countryInput !== "" &&
    //   tahun !== "" &&
    //   jenisKomoditas !== "" &&
    //   ((tmpTahun === 0 && tmpProvinsi === 0) ||
    //     (tmpProvinsi !== countryInput && tmpTahun !== tahun)(
    //       tmpJenisKomoditas !== jenisKomoditas &&
    //         tmpJenisKomoditas !== jenisKomoditas
    //     ))
    // ) {
    // }
    if (count === 1) {
      getData();
      // getCountry();
      makeYear();
    }
    if (country.length > 0) {
      getKomoditas();
    }
  }, [country, countryInput, tahun, jenisKomoditas]); //, datas]);

  return (
    <>
      <Container fluid className="grid-root">
        <Row>
          <Col xs="12" style={{ height: "100vh" }}>
            <Row>
              <Col xs="12">
                <Navbar />
              </Col>
            </Row>

            <Row style={{ marginTop: "80px" }}>
              <Col xs="12" className={`${s.sizeMap}`}>
                <Map
                  class="full-screen-map"
                  // mapProperties={{ basemap: "" }}
                  viewProperties={{
                    center: [106.83156413815725, -6.166243263125052],
                    effectiveMinZoom: 7,
                    zoom: 6,
                  }}
                >
                  <BermudaTriangle
                    datas={datas}
                    reRender={reRender}
                    setReRender={forSetRender}
                  />
                </Map>
              </Col>
            </Row>

            <Row className={`${s.inputForm}`}>
              <Col xs="12">
                <Row>
                  <Col xs="5">
                    <SelectR
                      maxMenuHeight={200}
                      defaultValue={{ values: "", label: "Pilih Negara" }}
                      options={country}
                      onChange={(option) => setCountryInput(option.value)}
                    />
                  </Col>
                  <Col xs="5">
                    <SelectR
                      maxMenuHeight={200}
                      defaultValue={{
                        values: parseInt(moment().format("YYYY")) - 1,
                        label: parseInt(moment().format("YYYY")) - 1,
                      }}
                      options={year}
                      onChange={(option) => setTahun(option.value)}
                    />
                  </Col>
                  {((countryInput !== "" &&
                    tahun !== "" &&
                    jenisKomoditas !== "") ||
                    (tahun !== "" && jenisKomoditas !== "") ||
                    (tahun !== "" && countryInput !== "") ||
                    tahun !== "") &&
                    loading === false && (
                      <Col xs="2">
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Download data
                            </Tooltip>
                          }
                        >
                          <CloudArrowDown size={30} onClick={onDownload} />
                        </OverlayTrigger>
                      </Col>
                    )}
                </Row>

                <Row>
                  <Col>
                    <Select
                      styles={customStyles}
                      grouped
                      options={forKomoditas}
                      minimumInputSearch={0}
                      defaultValue={{ label: "Pilih Komoditas", value: "" }}
                      onChange={(option) =>
                        option !== null ? setJenisKomoditas(option.value) : ""
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {loading && (
              <Row className={`${s.forLoading}`}>
                <Col className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            )}

            <Row className={`${s.onSumber}`}>
              <Col>
                <p style={{ fontSize: "13px", color: "black" }}>
                  Sumber Data :{" "}
                  <span onClick={onSumber} style={{ color: "black" }}>
                    UN Comtrade
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
