import React from "react";
import "./index.css";
// import { Container } from "@material-ui/core";
import { Row, Col, Form, Button, Container, Spinner } from "react-bootstrap";
import Navbar from "../../container/navbar";
import { Eye, Trash } from "react-bootstrap-icons";
import { useHistory, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import FileUpload from "../../../assets/icon/file.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/index.css";
import Swal from "sweetalert2";
import req from "../../../helpers/axios";
import sweetalert from "../../../helpers/sweetalert";

const Index = () => {
  const location = useLocation();
  const history = useHistory();
  const tmpState = location.state;
  const [data, setData] = React.useState({});
  const [allData, setAllData] = React.useState([]);
  const [reRender, setReRender] = React.useState(false);
  const [filePaths, setFilePaths] = React.useState([]);
  const [direktorat, setDirektorat] = React.useState([]);
  const [subdit, setSubdit] = React.useState([]);
  const [idExportir, setidExportir] = React.useState();
  const [parent, setParent] = React.useState();
  const [roleCheck, setRoleCheck] = React.useState({});
  const [roleCheckTmp, setRoleCheckTmp] = React.useState({});
  const [documentEdit, setDocumentEdit] = React.useState({});
  const [participantsCheck, setParticipantsCheck] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [count, setCount] = React.useState(1);
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      namafile: "",
      keterangan: "",
      catatan: tmpState.proses.explanation,
    },
    validationSchema: Yup.object({
      // namafile: Yup.string().required("Harus diisi!"),
      // keterangan: Yup.string().required("Harus diisi!"),
      // catatan: Yup.string().required("Harus diisi!"),
    }),
    onSubmit: (values, actions) => {
      delete values.namafile;
      const resultData = {
        notes: values.catatan,
      };

      let tmpCheck = [];

      let toPush = roleCheck;

      Object.keys(toPush).map((key) => {
        if (toPush[key] === true) {
          tmpCheck.push(key);
        }
      });

      if (
        tmpCheck.length > 0 &&
        (allData.length > 0 || documentEdit.documents.length > 0)
      ) {
        const fd = new FormData();
        fd.append("explanation", resultData.notes);
        fd.append("role_id", tmpCheck.join(","));
        fd.append("parent", parent);
        allData.map((item, index) => {
          fd.append("file_names[" + index + "]", item.namafile);
          fd.append("document_desc[" + index + "]", item.keterangan);
          fd.append("file_paths[" + index + "]", filePaths[index]);
        });

        documentEdit.documents.map((item, index) => {
          fd.append("update_id[" + index + "]", item.id);
          fd.append("document_desc_update[" + index + "]", item.description);
        });
        setLoading(true);
        req
          .post({
            endpoint: `applicant-submission/${tmpState.id_submission}/update/${tmpState?.proses.id}`,

            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                sweetalert.success(`Edit disposisi berhasil.`).then((res) => {
                  history.push(
                    `/pphnak/permohonan/detail/${tmpState.id_submission}`
                  );
                });
              } else {
                sweetalert.error(`${res.message} `);
              }
              setLoading(false);
            }
          });
      } else {
        sweetalert.info(
          "Dokumen harus diisi dan tujuan disposisi harus dipilih"
        );
      }
    },
  });

  const titleSendDisposisi = () => {
    let tmpTitle;
    if (direktorat.length > 0 && subdit.length > 0) {
      tmpTitle = `Direktorat atau Sub-Direktorat`;
    } else if (direktorat.length > 0) {
      tmpTitle = `Direktorat`;
    } else if (subdit.length > 0) {
      tmpTitle = "Sub-Direktorat";
    }
    return (
      <p>
        Pilih {`${tmpTitle}`} untuk tujuan disposisi. Dapat memilih lebih dari 1
        pilihan
      </p>
    );
  };

  const onDrag = (e) => {
    if (e) {
      if (e[0].size < 1000000) {
        setData({ file: e });

        e.map((i) => {
          // return setImageName(i.name);
          formik.setFieldValue("namafile", i.name);
        });

        const fd = new FormData();
        fd.append("file", e[0]);
        req
          .post({
            endpoint: `applicant-submission/${tmpState.id_submission}/add-document`,
            data: fd,
          })
          .then((res) => {
            if (res) {
              if (res.status === "success") {
                const path = res.data.path || null;
                const setpath = [...filePaths];
                setpath.push(path);
                setFilePaths(setpath);
              }
            }
          });
      } else {
        sweetalert.error(`Ukuran file maksimal 1mb`);
      }
    }
  };

  const handleAdd = () => {
    if (formik.values.namafile === "" || formik.values.keterangan === "") {
      return Swal.fire({
        text: "Jika ingin menambah dokumen, Gambar dan keterangan harus diisi",
        icon: "info",
        confirmButtonText: "OK",
        confirmButtonColor: "#63ACEF",
      });
    }
    formik.setFieldValue("namafile", "");
    formik.setFieldValue("keterangan", "");
    const fd = new FormData();
    fd.append("file", data);
    let tmp = allData;
    const result = {
      ...data,
      namafile: formik.values.namafile,
      keterangan: formik.values.keterangan,
    };

    tmp.push(result);

    setAllData(tmp);
  };

  const deleteNotes = (docID) => {
    req
      .post({
        endpoint: `applicant-submission/${docID}/delete-document`,
      })
      .then((res) => {
        if (res) {
          if (res.status === "success") {
            setReRender(!reRender);
          } else {
            sweetalert.error("internal server error");
          }
        }
      });
  };

  const deleteNotesTmp = (index) => {
    allData.splice(index, 1);
    setReRender(!reRender);
  };

  const handleRoleCheck = (param1, param2) => {
    let tmpCheck = roleCheck;
    tmpCheck[param1.id] = !param2;
    setRoleCheck(tmpCheck);
    setCount(count + 1);
    setReRender(!reRender);
  };

  React.useEffect(() => {
    if (count === 1) {
      req
        .get({
          endpoint: `applicant-submission/${tmpState.id_submission}/edit/${tmpState?.proses.id}`,
        })
        .then((res) => {
          if (res) {
            if (res.status == "success") {
              setDocumentEdit(res.process);
              setParent(res.parent);
              setDirektorat(res.direktorats); //array
              setSubdit(res.subdits); //array
              setParticipantsCheck(res.process.all_participants);
              setidExportir(res.data.exporter_id);

              setLoad(true);
              let editCheck = roleCheck;
              res.process.all_participants.map((item) => {
                editCheck[item.role_id] = true;
              });
              setRoleCheck(editCheck);
              setLoad(false);
            }
          }
        });
    }
  }, [location, reRender]);
  return (
    <>
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  {/* <Row>
                    <Col xs="12">
                      <p className="fsize12 font-weight-bold mt-3">
                        {" "}
                        {tmpState?.proses?.role?.name}
                      </p>

                      <p className="fsize12">{tmpState?.proses?.explanation}</p>
                      {tmpState?.proses?.documents.length > 0 &&
                        tmpState?.proses?.documents.map((doc, docIndex) => (
                          <p
                            className="txt-blue mb-2 fsize12"
                            style={{ textDecoration: "underline" }}
                            key={docIndex}
                          >
                            {doc.document}
                            <Eye className="ml-2" color="#63acef" />
                          </p>
                        ))}
                    </Col>
                  </Row> */}
                  <Row style={{ marginTop: "100px" }}>
                    <Col xs="12">
                      <Row>
                        <Col xs="12">
                          <Dropzone
                            onDrop={(acceptedFiles) => onDrag(acceptedFiles)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <section
                                className="border-upload"
                                style={{ marginBottom: "1rem" }}
                              >
                                <div
                                  {...getRootProps()}
                                  className="border-upload-fill"
                                >
                                  <input {...getInputProps()} />
                                  <div className="border-fill">
                                    <img
                                      src={FileUpload}
                                      alt="file"
                                      className="pt-2 pb-2"
                                    ></img>
                                    <div className="border-image">Upload</div>
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>

                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Nama File</Form.Label>
                            <Form.Control
                              className="forForm"
                              type="text"
                              disabled
                              value={formik.values.namafile}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>

                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Ket. Dokumen</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="keterangan"
                              value={formik.values.keterangan}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>
                          <button
                            type="button"
                            className="button-add fsize16 txt-blue"
                            onClick={(e) => handleAdd(e)}
                          >
                            Tambah
                          </button>

                          <Row
                            style={{ marginLeft: 0, marginRight: 0 }}
                            className={
                              documentEdit?.documents?.length > 0 ||
                              allData.length > 0
                                ? "notes"
                                : null
                            }
                          >
                            <Col xs="12">
                              {documentEdit?.documents?.length > 0 &&
                                documentEdit?.documents.map((doc, docIndex) => (
                                  <Row
                                    key={docIndex}
                                    style={{
                                      position: "relative",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <Col xs="6">Nama File:</Col>
                                    <Col xs="6">
                                      {doc.document.length > 10
                                        ? doc.document.substring(0, 10) + "..."
                                        : doc.document}
                                    </Col>
                                    <Col xs="6">Ket. Dokumen</Col>
                                    <Col xs="6">
                                      {doc.description.length > 100
                                        ? doc.description.substring(0, 100) +
                                          "..."
                                        : doc.description}
                                    </Col>
                                    <Trash
                                      style={{
                                        position: "absolute",
                                        right: 0,
                                        bottom: "5px",
                                        color: "red",
                                      }}
                                      onClick={() => deleteNotes(doc.id)}
                                    />
                                  </Row>
                                ))}
                            </Col>
                            <Col xs="12">
                              {allData.map((item, index) => (
                                <Row
                                  key={index}
                                  style={{
                                    position: "relative",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <Col xs="6">Nama File:</Col>
                                  <Col xs="6">
                                    {item.namafile.length > 10
                                      ? item.namafile.substring(0, 10) + "..."
                                      : item.namafile}
                                  </Col>
                                  <Col xs="6">Ket. Dokumen</Col>
                                  {/* <Col xs="6">{item.keterangan}</Col> */}
                                  <Col xs="6">
                                    {item.keterangan.length > 100
                                      ? item.keterangan.substring(0, 100) +
                                        "..."
                                      : item.keterangan}
                                  </Col>
                                  <Trash
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      bottom: "5px",
                                      color: "red",
                                    }}
                                    onClick={() => deleteNotesTmp(index)}
                                  />
                                </Row>
                              ))}
                            </Col>
                          </Row>

                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Catatan</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="catatan"
                              value={formik.values.catatan}
                              onChange={formik.handleChange}
                            />
                          </Form.Group>

                          {titleSendDisposisi()}
                          {direktorat.length > 0 && load === false && (
                            <div className="d-flex mb-2">
                              <div className="pr-2">
                                <h6>Direktorat</h6>
                              </div>
                              <hr className="my-auto flex-grow-1 linebold" />
                            </div>
                          )}
                          {direktorat.length > 0 &&
                            load === false &&
                            direktorat.map((item, index) => (
                              <Form.Group
                                controlId={`check${index}`}
                                key={index}
                              >
                                <Form.Check
                                  value="lokal"
                                  checked={roleCheck[item.id]}
                                  onChange={() =>
                                    handleRoleCheck(item, roleCheck[item.id])
                                  }
                                  type="checkbox"
                                  label={item.name}
                                />
                              </Form.Group>
                            ))}

                          {subdit.length > 0 && load === false && (
                            <div className="d-flex mb-2">
                              <div className="pr-2">
                                <h6>Sub Direktorat</h6>
                              </div>
                              <hr className="my-auto flex-grow-1 linebold" />
                            </div>
                          )}
                          {subdit.length > 0 &&
                            load === false &&
                            subdit.map((item, index) => (
                              <Form.Group
                                controlId={`subdit${index}`}
                                key={index}
                              >
                                <Form.Check
                                  value="lokal"
                                  checked={roleCheck[item.id]}
                                  onChange={() =>
                                    handleRoleCheck(item, roleCheck[item.id])
                                  }
                                  type="checkbox"
                                  label={item.name}
                                />
                              </Form.Group>
                            ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12 pb-3">
                  {loading ? (
                    <Row className="text-center">
                      <Col>
                        <Spinner animation="grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <Button block="true" type="submit">
                      Lanjutkan
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
