import React from "react";
import "./index.css";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Container,
  Spinner,
} from "react-bootstrap";
import Navbar from "../navbar";
import { Envelope } from "react-bootstrap-icons";
import { useLocation, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../styles/index.css";
import { url } from "../../../helpers/env";
import axios from "axios";
import Swal from "sweetalert2";
import req from "../../../helpers/axios";

const Index = () => {
  const [notice, showNotice] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Harus diisi!"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      if (values) {
        // setLoading(true);
        req
          .postWithNoHeaders({
            endpoint: "forget_password",
            data: values,
          })
          .then((res) => {
            if (res?.status === "error") {
              setLoading(false);
              Swal.fire({
                text: `${res?.message}`,
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              });
            } else if (res.status === "success") {
              localStorage.setItem("np", 1);
              setLoading(false);
              Swal.fire({
                text: `${res?.message}`,
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#63ACEF",
              }).then((response) => {
                history.push("/new-password");
              });
            }
          });
      }
    },
  });

  React.useEffect(() => {}, [location]);
  return (
    <>
      <Container fluid className="grid-root">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col
              xs="12"
              style={{ height: "100vh" }}
              className="d-flex flex-column justify-content-between"
            >
              <Row>
                <Col xs="12">
                  <Navbar />
                  <Row style={{ marginTop: "70px" }}>
                    <Col xs="12">
                      <h4 className="heading">Lupa password</h4>
                      <p className="mt-4">
                        Masukkan email yang telah anda daftarkan, kami akan
                        mengirimkan link ke email anda untuk melakukan reset
                        password
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col xs="12">
                      <Row>
                        <Col xs="12">
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="email">
                                <Envelope />
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              name="email"
                              type="email"
                              placeholder="Email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs="12 pb-3">
                  {loading ? (
                    <Row className="text-center">
                      <Col>
                        <Spinner animation="grow" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <Button block="true" type="submit">
                      Kirim
                    </Button>
                  )}
                  {/* <Button block="true" type="submit">
                    {loading ? (
                      <Spinner animation="grow" width={"auto"} />
                    ) : (
                      "Kirim"
                    )}
                  </Button> */}
                  {formik.errors.email && formik.touched.email ? (
                    <Button
                      disabled={true}
                      block="true"
                      className="btn btn-err"
                    >
                      Email tidak boleh kosong
                    </Button>
                  ) : null}
                  {notice ? (
                    <Button
                      disabled={true}
                      block="true"
                      className="btn btn-suc"
                    >
                      Link reset password telah dikirim
                    </Button>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default Index;
