import React from "react";
import { Modal, Button } from "react-bootstrap";
const Changepassword = (props) => {
  return (
    <div>
      <Modal show={props.data.show} onHide={props.data.closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <img src={props.data.urlImage} alt="" />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Changepassword;
