const { default: Swal } = require("sweetalert2");

const success = (text, confirmButtonText, buttonColor) => {
  const btnColor = buttonColor || "#63ACEF";
  const btnText = confirmButtonText !== undefined ? confirmButtonText : "OK";
  return Swal.fire({
    text: `${text}`,
    icon: `success`,
    confirmButtonText: `${btnText}`,
    confirmButtonColor: `${btnColor}`,
  });
};

const error = (text, confirmButtonText, buttonColor) => {
  const btnColor = buttonColor || "#63ACEF";
  const btnText = confirmButtonText !== undefined ? confirmButtonText : "OK";
  return Swal.fire({
    text: `${text}`,
    icon: `error`,
    confirmButtonText: `${btnText}`,
    confirmButtonColor: `${btnColor}`,
  });
};

const info = (text, confirmButtonText, buttonColor) => {
  const btnColor = buttonColor || "#63ACEF";
  const btnText = confirmButtonText !== undefined ? confirmButtonText : "OK";
  return Swal.fire({
    text: `${text}`,
    icon: `info`,
    confirmButtonText: `${btnText}`,
    confirmButtonColor: `${btnColor}`,
  });
};

const sweetalert = { success, error, info };

export default sweetalert;
export { success, error, info };
